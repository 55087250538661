@import '../globals';

.demo-link {
  display: flex;
  align-items: center;
  justify-content: center;
  &__chain {
    display: flex;
    align-items: center;
  }
  &__chain-block {
    display: flex;
    height: 30px;
    align-items: center;
    background-color: #464852;
    border-radius: 4px;
    margin-right: 15px;
    &_transparent {
      background-color: transparent;
      border: 1px solid rgba(142, 142, 146, 0.45);
    }
  }
  &__campaign-class {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #a2a2a7;
    width: 40px;
    border-right: 1px solid rgba(142, 142, 146, 0.45);
  }
  &__campaign-name {
    width: 220px;
    color: $white;
    padding: 0 8px;
    @extend %h-truncate;
  }
  &__dropdown {
    width: 220px !important;
    .dropdown__toggle {
      border: none;
    }
    .dropdown__caret {
      color: $font-color-base;
    }
    .dropdown__menu {
      border-color: rgba(142, 142, 146, 0.45);
    }
  }
  &__icon {
    @extend %h-fa;
    font-size: 14px;
    margin-right: 15px;
  }
  &__buttons {
    display: flex;
    align-items: center;
  }
}
