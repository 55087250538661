@import '../globals';

.textarea {
  @extend %h-textfield-skin;
  position: relative;
  width: 70%;
  min-width: 300px;
  max-width: 700px;
  height: auto;
  min-height: 100px;
  padding: 10px;
  white-space: pre;
  overflow: auto;

  &_dictionaries {
    max-height: calc(100vh - 211px);
    @extend %h-scrollbar-skin;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 10px;
      background: $white;
    }
  }

  &_view-mode {
    border-color: #b3b9c7;
  }
}
