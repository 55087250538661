@import '../globals';

.main-menu,
.main-menu__secondary,
.main-menu__secondary__resolution-sm {
  @extend %h-list-reset;
}

.main-menu__secondary {
  background-color: #2e313c;
  overflow: hidden;
  display: block;
  @include tablet {
    display: none;
  }
}

.main-menu__secondary__resolution-sm {
  background-color: #2e313c;
  overflow: auto;
  position: absolute;
  display: none;
  visibility: hidden;
  left: 40px;
  width: 200px;
  max-height: 200px;
  @include tablet {
    display: block;
  }
}

.main-menu__item-l1 {
  & > .main-menu__item-control {
    display: block;
    position: relative;
    height: 40px;
    line-height: 40px;
    padding-left: 50px;
    color: $white;
    font-size: $font-size-base;
    font-weight: normal;
    cursor: pointer;
    transition: all 0.3s ease;
    background: #464852;
    @include tablet {
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: inline-block;
      width: 40px;
      height: 40px;
      transition: all 0.3s ease;
      @include tablet {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.main-menu__item-l1 > .main-menu__item-control > .main-menu__item-label {
  @include tablet {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    background: #646675;
    width: 200px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    z-index: 100;
    visibility: hidden;
    &:before {
      // content: "";
      display: block;
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translatey(-50%);
      width: 0px;
      height: 0px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid #646675;
    }
  }
}

.main-menu__item-l1:hover > .main-menu__item-control > .main-menu__item-label {
  @include tablet {
    visibility: visible;
  }
}
.main-menu__item-l1:hover > .main-menu__secondary__resolution-sm {
  @include tablet {
    visibility: visible;
    z-index: 1;
  }
}

.main-menu__item-l1:hover > .main-menu__item-control {
  background-color: #545662;
  &:before {
    background-color: #3596c9;
  }
}

.main-menu__item-l1.active > .main-menu__item-control {
  background-color: #545662;
  &:before {
    background-color: #3596c9;
  }
}

.main-menu__item-l2 {
  & > .main-menu__item-control {
    display: flex;
    align-items: center;
    height: 32px;
    padding-right: 5px;
    padding-left: 12px;
    color: #cacbe0;
    font-size: 11px;
    font-weight: normal;
    cursor: pointer;
    transition: border-color 0.3s ease, background-color 0.3s ease,
      color 0.3s ease;

    @include tablet {
      display: block;
      line-height: 32px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background-color: #464a59;
      border-right: 5px solid #3596c9;
      color: $white;
    }
  }
}

.main-menu__item-l2.active > .main-menu__item-control {
  background-color: #464a59;
  border-right: 5px solid #3596c9;
  color: $white;
  font-weight: 600;
}

//icons
.main-menu__item-l1_campaigns > .main-menu__item-control:before {
  background: url('../../images/icon_menu_campaigns.png') no-repeat center;
}
.main-menu__item-l1_widgets > .main-menu__item-control:before {
  background: url('../../images/icon_menu_widgets.png') no-repeat center;
}
.main-menu__item-l1_sites > .main-menu__item-control:before {
  background: url('../../images/icon_menu_sites.png') no-repeat center;
}
.main-menu__item-l1_entities > .main-menu__item-control:before {
  background: url('../../images/icon_menu_entities.png') no-repeat center;
}
.main-menu__item-l1_analytics > .main-menu__item-control:before {
  background: url('../../images/icon_menu_analytics.png') no-repeat center;
}
.main-menu__item-l1_admin > .main-menu__item-control:before {
  background: url('../../images/icon_menu_admin.png') no-repeat center;
}
.main-menu__item-l1_monitoring > .main-menu__item-control:before {
  background: url('../../images/icon_menu_monitoring.png') no-repeat center;
}
.main-menu__item-l1_reporting > .main-menu__item-control:before {
  background: url('../../images/icon_menu_reporting.png') no-repeat center;
}
