/*
* Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
* 600 North Bridge Road, Parkview Square #15-10, Singapore
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* License: see file “LICENSE.txt”
*/
.eyedropper_color-monitor {
  position: fixed;
  visibility: hidden;
  width: 25px;
  height: 25px;
  border: 1px solid #ffffff;
  transform: translate(100%, -110%);
  border-radius: 2px;
  -webkit-box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.75);
  &.eyedropper-active {
    visibility: visible;
  }
}

.eyedropper_cnt.eyedropper-active {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: url('../../images/eyedropper.png') 0 21, auto !important;
  }
}
