@import '../globals';

.link {
  border-bottom: 1px solid;
  cursor: pointer;

  &_highlighted {
    color: $blue-thin-lines;
  }
}
