@import '../globals';

.list-tile {
  display: block;
  min-height: 86px;
  border: 1px solid #e6ebf0;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 36px;
    background: #f2f1f6;
    padding: 0 5px;
  }

  &__title {
    @extend %h-truncate;
    font-size: $font-size-base;
    color: $font-color-base;
    font-weight: normal;
    padding-left: 5px;
    margin-right: 15px;
    flex-shrink: 0;
    flex-grow: 1;
  }

  &__filters-holder {
    margin-right: 15px;
  }

  &__filter {
    width: 100%;
  }

  &__buttons-holder {
    display: flex;
    align-items: center;
  }

  &__body {
    position: relative;
    padding: 15px 10px;
  }
}

.list-tile_slidedown {
  .list-tile__body {
    position: relative;
    height: 56px;
    overflow: hidden;
    transition: 0.5s ease;
    &:after {
      content: '';
      display: block;
      height: 15px;
      width: 100%;
      background: $white;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.list-tile_slidedown-open {
  .list-tile__body {
    height: auto;
  }
}
