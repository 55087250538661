@import '../globals';

.data-row {
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &_indent-10 {
    padding-left: 10px;
  }

  &_indent-15 {
    padding-left: 15px;
  }

  &_indent-25 {
    padding-left: 25px;
  }
}
