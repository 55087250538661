/*
* Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
* 600 North Bridge Road, Parkview Square #15-10, Singapore
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* License: see file “LICENSE.txt”
*/

@import '../settings';
@import '../global';
@import '../../globals';

@mixin clearfix() {
  &:before,
  &:after {
    content: ' '; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin make-grid-columns(
  $i: 1,
  $list: '.col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}'
) {
  @for $i from (1 + 1) through $grid-columns {
    $list: '#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}';
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin float-grid-columns($class, $i: 1, $list: '.col-#{$class}-#{$i}') {
  @for $i from (1 + 1) through $grid-columns {
    $list: '#{$list}, .col-#{$class}-#{$i}';
  }
  #{$list} {
    float: left;
  }
}

@mixin calc-grid-column($index, $class, $type) {
  @if ($type == width) and ($index > 0) {
    .col-#{$class}-#{$index} {
      width: percentage(($index / $grid-columns));
    }
  }
  @if ($type == push) and ($index > 0) {
    .col-#{$class}-push-#{$index} {
      left: percentage(($index / $grid-columns));
    }
  }
  @if ($type == push) and ($index == 0) {
    .col-#{$class}-push-0 {
      left: auto;
    }
  }
  @if ($type == pull) and ($index > 0) {
    .col-#{$class}-pull-#{$index} {
      right: percentage(($index / $grid-columns));
    }
  }
  @if ($type == pull) and ($index == 0) {
    .col-#{$class}-pull-0 {
      right: auto;
    }
  }
  @if ($type == offset) {
    .col-#{$class}-offset-#{$index} {
      margin-left: percentage(($index / $grid-columns));
    }
  }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-grid-columns($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include calc-grid-column($i, $class, $type);
  }
}

// Create grid for specific class
@mixin make-grid($class) {
  @include float-grid-columns($class);
  @include loop-grid-columns($grid-columns, $class, width);
  @include loop-grid-columns($grid-columns, $class, pull);
  @include loop-grid-columns($grid-columns, $class, push);
  @include loop-grid-columns($grid-columns, $class, offset);
}

// Grid system
//
// Generate semantic grid columns with these mixins

// Centered container element
@mixin container-fixed($gutter: $grid-gutter-width) {
  margin-right: auto;
  margin-left: auto;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  @include clearfix;
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
  margin-left: ($gutter / -2);
  margin-right: ($gutter / -2);
  @include clearfix;
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}
@mixin make-xs-column-offset($columns) {
  margin-left: percentage(($columns / $grid-columns));
}
@mixin make-xs-column-push($columns) {
  left: percentage(($columns / $grid-columns));
}
@mixin make-xs-column-pull($columns) {
  right: percentage(($columns / $grid-columns));
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-sm-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-offset($columns) {
  @media (min-width: $screen-sm-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-push($columns) {
  @media (min-width: $screen-sm-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-pull($columns) {
  @media (min-width: $screen-sm-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-md-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-offset($columns) {
  @media (min-width: $screen-md-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-push($columns) {
  @media (min-width: $screen-md-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-pull($columns) {
  @media (min-width: $screen-md-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-lg-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-offset($columns) {
  @media (min-width: $screen-lg-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-push($columns) {
  @media (min-width: $screen-lg-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-pull($columns) {
  @media (min-width: $screen-lg-min) {
    right: percentage(($columns / $grid-columns));
  }
}

.col-wlg-1,
.col-wlg-10,
.col-wlg-11,
.col-wlg-12,
.col-wlg-2,
.col-wlg-3,
.col-wlg-4,
.col-wlg-5,
.col-wlg-6,
.col-wlg-7,
.col-wlg-8,
.col-wlg-9,
.col-wmd-1,
.col-wmd-10,
.col-wmd-11,
.col-wmd-12,
.col-wmd-2,
.col-wmd-3,
.col-wmd-4,
.col-wmd-5,
.col-wmd-6,
.col-wmd-7,
.col-wmd-8,
.col-wmd-9,
.col-wsm-1,
.col-wsm-10,
.col-wsm-11,
.col-wsm-12,
.col-wsm-2,
.col-wsm-3,
.col-wsm-4,
.col-wsm-5,
.col-wsm-6,
.col-wsm-7,
.col-wsm-8,
.col-wsm-9,
.col-wxs-1,
.col-wxs-10,
.col-wxs-11,
.col-wxs-12,
.col-wxs-2,
.col-wxs-3,
.col-wxs-4,
.col-wxs-5,
.col-wxs-6,
.col-wxs-7,
.col-wxs-8,
.col-wxs-9 {
  position: relative;
  min-height: 1px;
  padding: 0;
}

.col-wxs-1,
.col-wxs-10,
.col-wxs-11,
.col-wxs-12,
.col-wxs-2,
.col-wxs-3,
.col-wxs-4,
.col-wxs-5,
.col-wxs-6,
.col-wxs-7,
.col-wxs-8,
.col-wxs-9 {
  float: left;
}
.col-wxs-2 {
  width: 16.66%;
  padding-right: 10px;

  &:last-child {
    padding: 0;
  }
}
.col-wxs-3 {
  width: 25%;
  padding-right: 10px;

  &:last-child {
    padding: 0;
  }
}
.col-wxs-4 {
  width: 33.33%;
  padding-right: 10px;

  & + & {
    padding: 0;
  }

  & + & + & {
    padding-right: 0;
    padding-left: 10px;
  }
}
.col-wxs-6 {
  width: 50%;
  padding-right: 10px;

  & + & {
    padding-right: 0;
  }
}
.col-wxs-8 {
  width: 66.6%;
}
.col-wxs-9 {
  width: 75%;
  padding-right: 10px;

  &:last-child {
    padding: 0;
  }
}
.col-wxs-10 {
  width: 83.33%;
  padding-right: 10px;
}
.col-wxs-12 {
  width: 100%;
  margin-top: 10px;
}

.col-wlg-1,
.col-wlg-10,
.col-wlg-11,
.col-wlg-12,
.col-wlg-2,
.col-wlg-3,
.col-wlg-4,
.col-wlg-5,
.col-wlg-6,
.col-wlg-7,
.col-wlg-8,
.col-wlg-9 {
  float: left;
}

.col-no-margin {
  margin: 0;
}

@media (min-width: 1580px) {
  .col-wlg-2 {
    width: 16.66%;
    margin: 0;
  }
  .col-wlg-3 {
    width: 25%;
    margin: 0;
  }
  .col-wlg-4 {
    width: 33.33%;
    padding-right: 10px;
    margin: 0;
    & + & {
      padding: 0;
    }
    & + & + & {
      padding-right: 0;
      padding-left: 10px;
    }
  }
  .col-wlg-6 {
    width: 50%;
    margin: 0;
    padding-right: 10px;
    & + & {
      padding-right: 0;
    }
  }
  .col-wlg-8 {
    width: 66.6%;
    margin: 0;
  }
  .col-wlg-9 {
    width: 75%;
    margin: 0;
  }
  .col-wlg-10 {
    width: 83.33%;
    margin: 0;
  }
  .col-wlg-12 {
    width: 100%;
  }
}
/// ADMIN 3.0 << --------------------

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
  @include container-fixed;
}

// Row
//
// Rows contain and clear the floats of your columns.

.row {
  @include make-row;
}

// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns;

// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

@include make-grid(xs);

// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
}

// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: $screen-md-min) {
  @include make-grid(md);
}

// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}

//end grid

.page.__analytics {
  .admin-section_header_tabs-block_tab {
    width: 33% !important;
  }
}

.analytics-unique-id {
  display: none !important;
}

.analytics_page-cnt {
  position: relative;
  padding: 15px;
  //height: calc(100vh - 180px);
  //background-color: #d7dae3;
  //overflow-x: hidden !important;

  //.ps-scrollbar-x-rail {
  //  display: none !important;
  //}
}

.analytics-block_container {
  position: relative;
  display: block;

  &.__with-weather {
    padding-top: 40px;
  }

  .link-widget_status-row,
  .widget-preview-i_file-name {
    display: none;
  }

  .modal_body_widgets-list {
    padding: 0;
    margin: 0;
  }

  .widget-preview-i_bg {
    border: none;
  }

  .modal_body_widgets-list li {
    display: inline-block;
    vertical-align: top;
    width: 250px;
    margin: 10px;
  }
}

.analytics-block_title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #858396;
  margin-left: 15px;
}

.analytics-block_name {
  margin-left: 10px;
  border: 1px solid #a2a0b7;
  border-radius: 4px;
  padding: 4px 10px;
}

.analytics-block_tooltip-text {
  position: absolute;
  z-index: 1070;
  opacity: 1 !important;
  @include tablet {
    max-width: 240px;
  }

  .tooltip_inner {
    position: relative;
    z-index: 900;
    padding: 10px;
    background: #646675;
    color: $white;
    max-width: 400px;
    line-height: 1.5;
    margin-top: 15px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 0px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #646675;
    }
  }

  .tooltip_arrow {
    border-bottom-color: #eae9f0 !important;
  }
}
.analytics-block_tooltip-icon {
  display: inline-block;
  background-color: #a298b8;
  width: 14px;
  height: 14px;
  height: 14px;
  color: #fff;
  border-radius: 50%;
  font-size: 11px !important;
  margin: 0 15px 0 0;
  cursor: pointer;
  text-align: center;
  font-family: Arial;
  min-width: 15px;

  &:hover {
    background-color: #7d7295;
  }

  &:before {
    content: '?';
    margin-top: -2px;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }

  &.__tooltipSmall + .tooltip.analytics-block_tooltip-text {
    width: 120px;
  }

  &.__value {
    margin-left: 5px;
    margin-right: 0;
  }
}

.analytics-block_columns {
  display: inline-block;
  text-align: center;
  border-right: 1px solid #eaebed;
  vertical-align: top;

  &:last-child {
    border-right: 0;
  }
  .columns-value {
    font-size: 28px;
    font-weight: 300;
    color: #7e7a85;
    margin-bottom: 7px;
  }
  .columns-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    &__label {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    &.__affected-by-filters {
      font-size: 11px;
      padding: 1px 4px;
      border-radius: 4px;
      background-color: #f2f1f6;
      display: inline-block;
      padding: 7px;
      margin-top: 10px;
    }
  }
}

.analytics-block_h {
  background-color: #f2f1f6;
  padding: 0;
  height: 36px;
}

.analytics-block_close-btn {
  float: right;
  height: 36px;
  line-height: 36px;
  width: 36px;
  background-color: #e2e2ee;
  color: #84868c;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}

.analytics-block_columns-bottom-border {
  border-bottom: 1px solid #eaebed;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.analytics-block_left-header-cnt {
  margin-left: 12px;
}

.analytics-block_right-header-cnt {
  margin-right: 20px;

  .__search {
    position: absolute;
    top: 5px;
    right: 50px;
    background: #fff;
    border-radius: 0;
  }
}

.analytics-block_body-w {
  padding: 20px 15px;
  position: relative;
  background-color: #fff;
  min-height: 85px;

  .pagination_b {
    background-color: #e1e1eb;
    padding: 20px 20px 15px;
    margin: 0 -20px -20px;
  }

  .busy-overlay {
    background-color: rgba(229, 229, 237, 1);
    z-index: 18;

    &.__custom-nodata {
      height: 100px;
      margin: -20px;
    }
  }
  .busy-indicator {
    height: 50px;
    width: 50px;
  }
}

.analytics-block_question-label {
  color: #2e3772;
  font-size: 1.3rem;
  font-weight: bold;
}

.analytics-block_filter-container {
  margin: 0;
  padding: 0;
  position: relative;
  &.__radio,
  &.__checkbox {
    border: 1px solid #e4e5e7;
    border-radius: 4px;
    height: 26px;
    display: flex;
    align-items: center;
    .analytics-block_filter {
      border: none;
      margin-right: 0;
    }
  }
}

.analytics-block_filter {
  border: 1px solid #e4e5e7;
  border-radius: 4px;
  padding: 0 8px;
  display: inline-block;
  white-space: nowrap;
  margin-right: 10px;
  color: #7e7a85;
  height: 26px;
  line-height: 26px;
  &:last-child {
    margin-right: 0;
  }

  &.__text {
    color: $font-color-base;
  }

  &.__disabled {
    label.contain-checkbox {
      opacity: 0.5;
    }
  }

  &.__dropdown-filter {
    padding: 1px 10px 2px;
    font-size: 11px;
  }

  label {
    font-weight: normal;
    margin-bottom: 0;

    span {
      padding-left: 18px;
    }
  }

  .filter-color {
    width: 20px;
    height: 2px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 3px;
    background-color: #f39ab6;
    &.__circle {
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
    &.__bar {
      width: 12px;
      height: 12px;
      margin-top: -1px;
    }
    &.__green {
      background-color: #57d261;
    }
    &.__yellow {
      background-color: #ded84e;
    }
    &.__red {
      background-color: #f35648;
    }
  }

  // Redefined dropdown styles
  // if they present in analytic filters
  //
  .dropdown.__filter {
    height: 15px;
    border: none !important;
    border-radius: 4px;
    background-color: #fff;
    font-size: 0;

    .dropdown_caret {
      top: -4px;
    }

    .dropdown_menu {
      background: #e5e5ed;
      border: 0;
      z-index: 21;
      font-size: 11px;
    }
  }

  .dropdown_menu-i.active > span {
    background-color: rgb(201, 201, 207);
  }

  .dropdown_menu-i > span:hover {
    background-color: rgba(201, 201, 207, 0.5);
  }

  .dropdown_value {
    font-size: 11px !important;
    margin-top: -3px;
    display: block;
  }

  .dropdown_menu {
    min-height: auto !important;
    width: auto !important;
    max-width: 200px;
  }

  .dropdown.analytics-tf_timeframe-it:before {
    top: 19px;
    right: 5px;
  }
  // ---------------------------------
}

.manage-block_title-line {
  width: 20px;
  height: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}

.chart {
  max-height: 450px;
  position: relative;

  &.__part {
    text-align: center;
    width: 100%;
  }
}

.line-bar-chart {
  width: 100%;
  margin: 60px 0 15px;
}

.pie-columns {
  border-right: 1px solid #eaebed;

  &:last-child {
    border: 0;
  }

  &.__multi-lines {
    padding: 30px 20px;
    page-break-inside: avoid;

    &.col-sm-6 {
      border-top: 1px solid #eaebed;
    }

    &:nth-child(3),
    &:nth-child(5) {
      border-right: 0;
    }

    &:nth-child(4),
    &:nth-child(6) {
      clear: left;
    }
  }
}

.pie-show-more-handler {
  text-align: center;
  margin-top: 10px;

  .btn.__info {
    background-color: #aba5bf;
    border: 0;
  }
}

// Portraits collumns
//
.portrait-columns {
  border-right: 1px solid #eaebed;
  padding: 10px;
  float: left;

  &:last-child {
    border: 0;
  }

  &.__amount-3 {
    width: 33.33%;
  }

  &.__amount-6 {
    width: 16.66%;
  }

  &.__amount-9 {
    width: 11.11%;
  }

  &:nth-child(2) {
    .portrait-cnt td.portrait-image {
      height: 190px;
      @media screen and (max-width: 1600px) {
        height: 160px;
      }
    }
  }
  &:nth-child(3) {
    .portrait-cnt td.portrait-image {
      height: 170px;
      @media screen and (max-width: 1600px) {
        height: 140px;
      }
    }
  }
  &:nth-child(4) {
    .portrait-cnt td.portrait-image {
      height: 150px;
      @media screen and (max-width: 1600px) {
        height: 120px;
      }
    }
  }
  &:nth-child(5) {
    .portrait-cnt td.portrait-image {
      height: 130px;
      @media screen and (max-width: 1600px) {
        height: 100px;
      }
    }
  }
  &:nth-child(6) {
    .portrait-cnt td.portrait-image {
      height: 110px;
      @media screen and (max-width: 1600px) {
        height: 90px;
      }
    }
  }
  &:nth-child(7) {
    .portrait-cnt td.portrait-image {
      height: 90px;
      @media screen and (max-width: 1600px) {
        height: 70px;
      }
    }
  }
  &:nth-child(8) {
    .portrait-cnt td.portrait-image {
      height: 70px;
      @media screen and (max-width: 1600px) {
        height: 50px;
      }
    }
  }
  &:nth-child(9) {
    .portrait-cnt td.portrait-image {
      height: 50px;
      @media screen and (max-width: 1600px) {
        height: 30px;
      }
    }
  }

  img {
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .text-view {
    max-width: 100%;
  }

  .portrait-cnt {
    height: 300px;
    width: 100%;
    table-layout: fixed;

    td {
      vertical-align: bottom;
    }

    @media screen and (max-width: 1600px) {
      height: 250px;
    }

    td.portrait-image {
      height: 210px;

      @media screen and (max-width: 1600px) {
        height: 170px;
      }
    }
  }

  .portrait-value {
    color: #9b92b0;
    font-size: 40px;
    padding-bottom: 20px;
    display: inline-block;

    @media screen and (max-width: 1750px) {
      font-size: 33px;
    }

    @media screen and (max-width: 1510px) {
      font-size: 27px;
    }
  }

  hr {
    margin: 15px 0 10px;
  }
  p:not(:last-child) {
    margin-bottom: 5px;
  }
}

.portrait-icons-cnt {
  padding: 10px 10px 0 10px;
  height: 42px;

  [class*='col-sm-'] {
    &:first-child {
      padding-right: 5px;
      padding-left: 0px;
    }
    &:last-child {
      padding-right: 0px;
      padding-left: 5px;
    }
  }
}

.portrait-text {
  font-size: 12px;
}

.portrait-items {
  position: relative;
  padding-left: 30px;
  line-height: 1.6em;
}

.portrait-align {
  display: block;
  margin-top: 8px;
}

.portrait-items-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  color: #a5aad0;
}

// PIE LEGEND
//
.pie-legend {
  list-style-type: none;
  font-size: 11px;
  padding: 0 5px;

  &.__right {
    .legend-graph_title {
      display: block;
      width: auto;
    }
    .legend-graph_values {
      &.__left {
        display: none;
      }
      &.__right {
        display: inline-block;
      }
    }
    .legend-graph_container {
      width: 70%;
    }
    .legend-graph_title {
      text-align: left;
    }
  }
}

.legend-graph_title {
  display: inline-block;
  width: 180px;
  text-align: right;
  padding-right: 15px;
}

.legend-graph_container {
  position: relative;
  display: inline-block;
  width: calc(95% - 200px);
  margin-bottom: 7px;
}

hr.legend-graph_bar {
  display: inline-block;
  width: 0%;
  min-width: 2px;
  height: 10px;
  background-color: #ccc;
  margin: 0;
  padding: 0;
  border: 0;
  transition: all 0.2s;

  &.__minimum {
    min-width: 2px;
  }
}

.pie-chart-legend {
  hr.legend-graph_bar {
    height: 18px;
  }

  &.__small-legend {
    .legend-graph_title {
      width: 60px;
    }
    .legend-graph_container {
      width: calc(95% - 80px);
    }
  }

  &.col-sm-9 {
    width: 75%;
    margin-top: -20px;
    @media (max-width: 1500px) {
      width: 70%;
    }
  }

  &.col-sm-9 + .col-sm-3 {
    width: 25%;
    margin-top: 20px;
    @media (max-width: 1500px) {
      width: 30%;
    }
  }
}

.pie-chart-title {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 11px;
  color: #fff;
  background: #bbbecc;
  padding: 5px 7px;
  border-radius: 4px;
  position: absolute;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.legend-graph_values {
  position: relative;

  &.__right {
    display: none;
    width: 29%;
    text-align: right;
    margin-top: -12px;
  }
  &.__left {
    position: absolute;
    display: inline-block;
    text-align: left;
    top: 2px;
    width: 200px;
  }
}

//.legend-graph_values_styled {
//  padding: 2px 4px;
//  background-color: #ECEBF1;
//  border-radius: 3px;
//}

.legend-graph_values_text-small {
  position: relative;
  width: 50%;
  float: left;
  text-align: left;
  padding-left: 12px;

  &.__right {
    text-align: right;
    padding-right: 8px;
    padding-left: 0px;
    border: 0;
  }
}

.legend-graph_values_text {
  position: relative;
  text-align: left;
}

//PIE LEGEND TABLE
//
.pie-legend-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.pie-legend-table_tr {
  width: 100%;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;

  &:first-child {
    border-top: none;
  }
}

.pie-legend-table_td,
.pie-legend-table_th {
  height: 20px;
  padding: 10px 20px 10px 0;
  border-bottom: 1px solid #f2f2f2;

  &.__title {
    width: 22%;
    text-align: left;
    span {
      display: inline-block;
      width: 100%;
    }
  }

  &.__chart {
    width: 60%;
    overflow: hidden;
  }

  &.__query {
    padding-right: 100px;
  }

  &.__data {
    width: 12%;
    min-width: 50px;
    text-align: center;
  }

  &.__percents {
    width: 6%;
    min-width: 50px;
    text-align: center;
  }
}

.pie-legend-table_th {
  color: #767a91;
  font-size: 1.4rem;
  font-weight: 600;
}

.pie-legend-table_td.__title {
  padding: 0 10px;
  & > span {
    @extend %h-truncate;
  }
}

.pie-chart-legend.__small-legend {
  .pie-legend-table_td,
  .pie-legend-table_th {
    &.__title {
      width: 10%;
    }
    &.__chart {
      width: 72%;
    }
  }
}

.current-widget_cnt {
  height: 460px;
  padding-right: 10px;
  position: relative;
  width: calc(100% + 10px);

  .ps-scrollbar-y-rail {
    right: 5px;
    display: none !important;
  }
  .ps-scrollbar-x-rail {
    display: none !important;
  }
}

// restyled c3
//
.c3-grid line {
  stroke: #ddd;
  stroke-dasharray: 0;
}

.c3-region {
  fill: #555;
}

.c3-tooltip td.value {
  text-align: left;
}

.c3-axis.c3-axis-y .tick:last-of-type text {
  text-anchor: end !important;
}

.chart-labels-block {
  width: 100%;
  position: absolute;
}

.analytics-block_no-data {
  margin: 15px;
  color: #8d8b8f;

  &.__grey {
    background-color: #e5e5ed;
    color: #515151;
  }
}

// axes
.chart-axis_labels-container {
  position: absolute;
}
.axis-label {
  font-size: 11px;
  color: #fff;
  background: #bbbecc;
  padding: 5px 7px;
  border-radius: 4px;
  position: absolute;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.5px;

  &.__x {
    right: 0;
    bottom: -20px;
  }
  &.__y {
    left: 0;
    top: -40px;
  }
  &.__y2 {
    right: 0;
    top: -40px;
    background: #ee9dad;
  }
}

.analytics-infinity {
  color: #a2a0b7;
  margin: 0 15px;
  display: inline-block;
  margin-top: 1px;
  transform: scale(1.7);
  vertical-align: middle;
}

.analytic-icon {
  margin: 0 15px;
  .fa {
    display: inline-block;
    color: #a2a0b7;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  .fa-filter:before {
    content: '\f0b0';
  }

  .fa-asterisk:before {
    content: '\f069';
  }
}

// Global items list styles

.items-list {
  list-style-type: none;
  padding: 0;
}

.items-list_item {
  position: relative;
  vertical-align: middle;
  padding-right: 15px;
  display: inline-block;
  width: 20%;
  margin-bottom: 10px;

  span {
    padding: 3px 8px;
    background-color: #eeedf3;
    color: #8f8288;
    font-size: 11px;
    border-radius: 5px;
    max-width: 100%;
    display: inline-block;

    i {
      margin-right: 5px;
    }
  }
}

.analytics-block_no-data-available {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

// Weather
.analytics-block_weather-container {
  width: 100%;
  position: absolute;
  top: 20px;
}
.analytics-block_weather {
  padding: 0px 20px 10px;
  display: inline-block;
}
.weather-day-container {
  text-align: center;
  border-radius: 5px;
  background-color: #f6f5f9;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  padding: 5px 0;
  min-height: 58px;
  font-size: 11px;

  &.__nodata {
    line-height: 48px;
  }
}
.weather-day-icon {
  height: 28px;
  display: block;
  position: relative;

  img {
    height: 100%;
  }
}
.weather-day-temperature {
  font-size: 11px;
  color: #8d8b8f;
}
.page.__campaigns.__analytics .modal-block_content .pagination_b .drop-down_w {
  display: block;
}
.page.__campaigns.__analytics
  .modal-block_content
  .pagination_b
  .pagination_i.__group-backward {
  display: inline;
}

//*** MANAGE BLOCK STYLES ***//

.analytics-manage-block-container .container {
  width: 100%;
}
.analytics-manage-block {
  margin: 0 10px 25px;
  width: calc(50% - 20px);
  min-height: 83px;
  background: #fff;
  box-sizing: border-box;
  .manage-block-items {
    position: relative;
    max-height: 450px;
    min-height: 72px;
    overflow: auto;
  }
  .analytics-block_h {
    margin: 0;
    padding: 2px 4px 2px 15px;
    line-height: 40px;
    font-size: 15px;
    .settings-form_btn.__manage {
      margin: 5px;
    }
  }
  &.__filter {
    .ps-scrollbar-x-rail,
    .ps-scrollbar-y-rail {
      display: none;
    }
    .picked-item-wrapper {
      width: 19%;
      display: inline-block;
      margin-right: 1%;
    }
  }
  &.__page-break {
    page-break-before: always;
  }

  .picked-item {
    display: inline-block;
    position: relative;
    max-width: 100%;
    padding: 2px 8px 2px 18px;
    margin: 4px 0 4px 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8em;
    border-radius: 3px;
    background-color: #e5e5ef;
    line-height: 20px;
    white-space: nowrap;
    a {
      color: #515151;
    }
    &:before {
      content: '';
      left: 5px;
      top: 5px;
      width: 8px;
      height: 12px;
      margin-right: 2px;
      position: absolute;
      background-position: bottom center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.__overflow-count {
      background: none;
      &:before {
        display: none;
      }
    }
    &.__sites {
      &:before {
        background-image: url('/static/images/analytics-icons/an-filter-site.png');
      }
    }
    &.__cities {
      &:before {
        width: 10px;
        background-image: url('/static/images/analytics-icons/an-filter-city.png');
      }
    }
    &.__campaigns {
      &:before {
        top: 4px;
        width: 10px;
        background-image: url('/static/images/analytics-icons/an-filter-camp.png');
      }
    }
    &.__tagsClasses,
    &.__tagsTypes,
    &.__campaignsTags,
    &.__campaignsLabels,
    &.__tags,
    &.__labels {
      &:before {
        width: 10px;
        background-image: url('/static/images/analytics-icons/an-filter-tag.png');
      }
    }
    &.__entities {
      &:before {
        top: 4px;
        left: 4px;
        width: 12px;
        background-image: url('/static/images/analytics-icons/an-filter-entity.png');
      }
    }
  }
  .manage-btn-txt {
    display: inline;
  }
  .manage-no-items {
    margin: 27px 0 1px;
    color: #8d8b8f;
    text-align: center;
    font-size: 12px;
  }
  .plus-btn-txt {
    display: none;
  }
  .tags-form_remove-btn {
    display: none;
  }
}

.analytics-block {
  .analytics-manage-block {
    .analytics-block_h {
      background: #eae5f3;
      .manage-legend-line {
        position: relative;
        display: inline-block;
        top: -3px;
        left: -10px;
        height: 2px;
        width: 20px;
      }
    }
  }

  &.__no-data .analytics-block_body-w {
    background-color: #e5e5ed;
  }
}

.analytics-block-nopadding .analytics-block_body-w {
  padding: 0;
}

.manage-modal {
  .manage-block-items {
    max-height: 150px;
  }
  .manage-modal_search-w {
    position: relative;
    display: inline-block;
    &:after {
      content: '\e003';
      position: absolute;
      color: #5fb7fa;
      right: 6px;
      top: 7px;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
    }
  }

  .modal_select-all-w {
    label {
      margin-bottom: 0;
    }
    & > span,
    label {
      vertical-align: middle;
    }
  }
  .manage-modal .checkboxes_block-items {
    max-height: 330px;
  }
  label.contain-checkbox {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 11px;
  }
  .manage-types {
    margin-top: 5px;
    font-size: 12px;
    span {
      margin-right: 5px;
    }
  }
  .manage-block-filters {
    background-color: #fff;
    border: 1px solid #63c4f0;
  }
  .analytics-manage-block {
    margin: 0;
    width: 50%;
    font-size: 0.9em;
    background-color: #fff;
    &:first-child {
      margin-right: -1px;
      border-right: 1px solid #63c4f0;
    }
    &:last-child {
      width: calc(50% + 1px);
      border-left: 1px solid #63c4f0;
    }

    .analytics-block_h {
      background: #fff;
      border-bottom: 1px solid #63c4f0;
    }
    .settings-form_btn.__manage {
      padding: 3px 9px;
      .manage-btn-txt {
        display: none;
      }
      .plus-btn-txt {
        display: inline;
      }
    }
    .picked-item {
      background-color: #e0f1fe;
      font-size: 0.7em;
      .tags-form_remove-btn {
        right: -3px;
        display: inline-block;
        font-size: 15px;
        font-weight: bold;
        user-select: none;
      }
    }
  }
}

.analytics-block_search-w {
  position: relative;
  &:after {
    content: '\e003';
    position: absolute;
    opacity: 0.4;
    right: 6px;
    top: 4px;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }
}

.no-was {
  position: relative;
  height: calc(100% - 75px);

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}

.analytics-block_search-it {
  font-size: 12px;
  padding: 2px 30px 2px 5px;
  width: 200px;
  margin-top: -2px;
}

.analytics-print_item-name-cnt {
  margin: 0 auto;
  padding: 30px;
  text-align: center;
  background-color: white;
}

.analytics-print_item-name-label {
  padding-right: 20px;
  color: #7e7a85;
}

.analytics-print_item-name {
  border: 1px solid #d8d7d7;
  border-radius: 2px;
  display: inline-block;
  padding: 1px 3px;
}

@media (max-height: 1000px) {
  .manage-modal.modal-select {
    .manage-block-items {
      max-height: 150px;
    }
    .checkboxes_block-items {
      max-height: 200px;
    }
  }
}
