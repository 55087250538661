//
// Glyphicons for Bootstrap
//
// Since icons are fonts, they can be placed anywhere text is placed and are
// thus automatically sized to match the surrounding child. To use, create an
// inline element with the appropriate classes, like so:
//
// <a href="#"><span class="glyphicon __star"></span> Star</a>

// Import the fonts
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../../fonts/glyphicons-halflings-regular.eot');
  src: url('../../fonts/glyphicons-halflings-regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/glyphicons-halflings-regular.woff2') format('woff2'),
    url('../../fonts/glyphicons-halflings-regular.woff') format('woff'),
    url('../../fonts/glyphicons-halflings-regular.ttf') format('truetype');
}

// Catchall baseclass
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Individual icons
  &.__asterisk {
    &:before {
      content: '\2a';
    }
  }
  &.__plus {
    &:before {
      content: '\2b';
    }
  }
  &.__euro,
  &.__eur {
    &:before {
      content: '\20ac';
    }
  }
  &.__minus {
    &:before {
      content: '\2212';
    }
  }
  &.__cloud {
    &:before {
      content: '\2601';
    }
  }
  &.__envelope {
    &:before {
      content: '\2709';
    }
  }
  &.__pencil {
    &:before {
      content: '\270f';
    }
  }
  &.__glass {
    &:before {
      content: '\e001';
    }
  }
  &.__music {
    &:before {
      content: '\e002';
    }
  }
  &.__search {
    &:before {
      content: '\e003';
    }
  }
  &.__heart {
    &:before {
      content: '\e005';
    }
  }
  &.__star {
    &:before {
      content: '\e006';
    }
  }
  &.__star-empty {
    &:before {
      content: '\e007';
    }
  }
  &.__user {
    &:before {
      content: '\e008';
    }
  }
  &.__film {
    &:before {
      content: '\e009';
    }
  }
  &.__th-large {
    &:before {
      content: '\e010';
    }
  }
  &.__th {
    &:before {
      content: '\e011';
    }
  }
  &.__th-list {
    &:before {
      content: '\e012';
    }
  }
  &.__ok {
    &:before {
      content: '\e013';
    }
  }
  &.__remove {
    &:before {
      content: '\e014';
    }
  }
  &.__zoom-in {
    &:before {
      content: '\e015';
    }
  }
  &.__zoom-out {
    &:before {
      content: '\e016';
    }
  }
  &.__off {
    &:before {
      content: '\e017';
    }
  }
  &.__signal {
    &:before {
      content: '\e018';
    }
  }
  &.__cog {
    &:before {
      content: '\e019';
    }
  }
  &.__trash {
    &:before {
      content: '\e020';
    }
  }
  &.__home {
    &:before {
      content: '\e021';
    }
  }
  &.__file {
    &:before {
      content: '\e022';
    }
  }
  &.__time {
    &:before {
      content: '\e023';
    }
  }
  &.__road {
    &:before {
      content: '\e024';
    }
  }
  &.__download-alt {
    &:before {
      content: '\e025';
    }
  }
  &.__download {
    &:before {
      content: '\e026';
    }
  }
  &.__upload {
    &:before {
      content: '\e027';
    }
  }
  &.__inbox {
    &:before {
      content: '\e028';
    }
  }
  &.__play-circle {
    &:before {
      content: '\e029';
    }
  }
  &.__repeat {
    &:before {
      content: '\e030';
    }
  }
  &.__refresh {
    &:before {
      content: '\e031';
    }
  }
  &.__list-alt {
    &:before {
      content: '\e032';
    }
  }
  &.__lock {
    &:before {
      content: '\e033';
    }
  }
  &.__flag {
    &:before {
      content: '\e034';
    }
  }
  &.__headphones {
    &:before {
      content: '\e035';
    }
  }
  &.__volume-off {
    &:before {
      content: '\e036';
    }
  }
  &.__volume-down {
    &:before {
      content: '\e037';
    }
  }
  &.__volume-up {
    &:before {
      content: '\e038';
    }
  }
  &.__qrcode {
    &:before {
      content: '\e039';
    }
  }
  &.__barcode {
    &:before {
      content: '\e040';
    }
  }
  &.__tag {
    &:before {
      content: '\e041';
    }
  }
  &.__tags {
    &:before {
      content: '\e042';
    }
  }
  &.__book {
    &:before {
      content: '\e043';
    }
  }
  &.__bookmark {
    &:before {
      content: '\e044';
    }
  }
  &.__print {
    &:before {
      content: '\e045';
    }
  }
  &.__camera {
    &:before {
      content: '\e046';
    }
  }
  &.__font {
    &:before {
      content: '\e047';
    }
  }
  &.__bold {
    &:before {
      content: '\e048';
    }
  }
  &.__italic {
    &:before {
      content: '\e049';
    }
  }
  &.__text-height {
    &:before {
      content: '\e050';
    }
  }
  &.__text-width {
    &:before {
      content: '\e051';
    }
  }
  &.__align-left {
    &:before {
      content: '\e052';
    }
  }
  &.__align-center {
    &:before {
      content: '\e053';
    }
  }
  &.__align-right {
    &:before {
      content: '\e054';
    }
  }
  &.__align-justify {
    &:before {
      content: '\e055';
    }
  }
  &.__list {
    &:before {
      content: '\e056';
    }
  }
  &.__indent-left {
    &:before {
      content: '\e057';
    }
  }
  &.__indent-right {
    &:before {
      content: '\e058';
    }
  }
  &.__facetime-video {
    &:before {
      content: '\e059';
    }
  }
  &.__picture {
    &:before {
      content: '\e060';
    }
  }
  &.__map-marker {
    &:before {
      content: '\e062';
    }
  }
  &.__adjust {
    &:before {
      content: '\e063';
    }
  }
  &.__tint {
    &:before {
      content: '\e064';
    }
  }
  &.__edit {
    &:before {
      content: '\e065';
    }
  }
  &.__share {
    &:before {
      content: '\e066';
    }
  }
  &.__check {
    &:before {
      content: '\e067';
    }
  }
  &.__move {
    &:before {
      content: '\e068';
    }
  }
  &.__step-backward {
    &:before {
      content: '\e069';
    }
  }
  &.__fast-backward {
    &:before {
      content: '\e070';
    }
  }
  &.__backward {
    &:before {
      content: '\e071';
    }
  }
  &.__play {
    &:before {
      content: '\e072';
    }
  }
  &.__pause {
    &:before {
      content: '\e073';
    }
  }
  &.__stop {
    &:before {
      content: '\e074';
    }
  }
  &.__forward {
    &:before {
      content: '\e075';
    }
  }
  &.__fast-forward {
    &:before {
      content: '\e076';
    }
  }
  &.__step-forward {
    &:before {
      content: '\e077';
    }
  }
  &.__eject {
    &:before {
      content: '\e078';
    }
  }
  &.__chevron-left {
    &:before {
      content: '\e079';
    }
  }
  &.__chevron-right {
    &:before {
      content: '\e080';
    }
  }
  &.__plus-sign {
    &:before {
      content: '\e081';
    }
  }
  &.__minus-sign {
    &:before {
      content: '\e082';
    }
  }
  &.__remove-sign {
    &:before {
      content: '\e083';
    }
  }
  &.__ok-sign {
    &:before {
      content: '\e084';
    }
  }
  &.__question-sign {
    &:before {
      content: '\e085';
    }
  }
  &.__info-sign {
    &:before {
      content: '\e086';
    }
  }
  &.__screenshot {
    &:before {
      content: '\e087';
    }
  }
  &.__remove-circle {
    &:before {
      content: '\e088';
    }
  }
  &.__ok-circle {
    &:before {
      content: '\e089';
    }
  }
  &.__ban-circle {
    &:before {
      content: '\e090';
    }
  }
  &.__arrow-left {
    &:before {
      content: '\e091';
    }
  }
  &.__arrow-right {
    &:before {
      content: '\e092';
    }
  }
  &.__arrow-up {
    &:before {
      content: '\e093';
    }
  }
  &.__arrow-down {
    &:before {
      content: '\e094';
    }
  }
  &.__share-alt {
    &:before {
      content: '\e095';
    }
  }
  &.__resize-full {
    &:before {
      content: '\e096';
    }
  }
  &.__resize-small {
    &:before {
      content: '\e097';
    }
  }
  &.__exclamation-sign {
    &:before {
      content: '\e101';
    }
  }
  &.__gift {
    &:before {
      content: '\e102';
    }
  }
  &.__leaf {
    &:before {
      content: '\e103';
    }
  }
  &.__fire {
    &:before {
      content: '\e104';
    }
  }
  &.__eye-open {
    &:before {
      content: '\e105';
    }
  }
  &.__eye-close {
    &:before {
      content: '\e106';
    }
  }
  &.__warning-sign {
    &:before {
      content: '\e107';
    }
  }
  &.__plane {
    &:before {
      content: '\e108';
    }
  }
  &.__calendar {
    &:before {
      content: '\e109';
    }
  }
  &.__random {
    &:before {
      content: '\e110';
    }
  }
  &.__comment {
    &:before {
      content: '\e111';
    }
  }
  &.__magnet {
    &:before {
      content: '\e112';
    }
  }
  &.__chevron-up {
    &:before {
      content: '\e113';
    }
  }
  &.__chevron-down {
    &:before {
      content: '\e114';
    }
  }
  &.__retweet {
    &:before {
      content: '\e115';
    }
  }
  &.__shopping-cart {
    &:before {
      content: '\e116';
    }
  }
  &.__folder-close {
    &:before {
      content: '\e117';
    }
  }
  &.__folder-open {
    &:before {
      content: '\e118';
    }
  }
  &.__resize-vertical {
    &:before {
      content: '\e119';
    }
  }
  &.__resize-horizontal {
    &:before {
      content: '\e120';
    }
  }
  &.__hdd {
    &:before {
      content: '\e121';
    }
  }
  &.__bullhorn {
    &:before {
      content: '\e122';
    }
  }
  &.__bell {
    &:before {
      content: '\e123';
    }
  }
  &.__certificate {
    &:before {
      content: '\e124';
    }
  }
  &.__thumbs-up {
    &:before {
      content: '\e125';
    }
  }
  &.__thumbs-down {
    &:before {
      content: '\e126';
    }
  }
  &.__hand-right {
    &:before {
      content: '\e127';
    }
  }
  &.__hand-left {
    &:before {
      content: '\e128';
    }
  }
  &.__hand-up {
    &:before {
      content: '\e129';
    }
  }
  &.__hand-down {
    &:before {
      content: '\e130';
    }
  }
  &.__circle-arrow-right {
    &:before {
      content: '\e131';
    }
  }
  &.__circle-arrow-left {
    &:before {
      content: '\e132';
    }
  }
  &.__circle-arrow-up {
    &:before {
      content: '\e133';
    }
  }
  &.__circle-arrow-down {
    &:before {
      content: '\e134';
    }
  }
  &.__globe {
    &:before {
      content: '\e135';
    }
  }
  &.__wrench {
    &:before {
      content: '\e136';
    }
  }
  &.__tasks {
    &:before {
      content: '\e137';
    }
  }
  &.__filter {
    &:before {
      content: '\e138';
    }
  }
  &.__briefcase {
    &:before {
      content: '\e139';
    }
  }
  &.__fullscreen {
    &:before {
      content: '\e140';
    }
  }
  &.__dashboard {
    &:before {
      content: '\e141';
    }
  }
  &.__paperclip {
    &:before {
      content: '\e142';
    }
  }
  &.__heart-empty {
    &:before {
      content: '\e143';
    }
  }
  &.__link {
    &:before {
      content: '\e144';
    }
  }
  &.__phone {
    &:before {
      content: '\e145';
    }
  }
  &.__pushpin {
    &:before {
      content: '\e146';
    }
  }
  &.__usd {
    &:before {
      content: '\e148';
    }
  }
  &.__gbp {
    &:before {
      content: '\e149';
    }
  }
  &.__sort {
    &:before {
      content: '\e150';
    }
  }
  &.__sort-by-alphabet {
    &:before {
      content: '\e151';
    }
  }
  &.__sort-by-alphabet-alt {
    &:before {
      content: '\e152';
    }
  }
  &.__sort-by-order {
    &:before {
      content: '\e153';
    }
  }
  &.__sort-by-order-alt {
    &:before {
      content: '\e154';
    }
  }
  &.__sort-by-attributes {
    &:before {
      content: '\e155';
    }
  }
  &.__sort-by-attributes-alt {
    &:before {
      content: '\e156';
    }
  }
  &.__unchecked {
    &:before {
      content: '\e157';
    }
  }
  &.__expand {
    &:before {
      content: '\e158';
    }
  }
  &.__collapse-down {
    &:before {
      content: '\e159';
    }
  }
  &.__collapse-up {
    &:before {
      content: '\e160';
    }
  }
  &.__log-in {
    &:before {
      content: '\e161';
    }
  }
  &.__flash {
    &:before {
      content: '\e162';
    }
  }
  &.__log-out {
    &:before {
      content: '\e163';
    }
  }
  &.__new-window {
    &:before {
      content: '\e164';
    }
  }
  &.__record {
    &:before {
      content: '\e165';
    }
  }
  &.__save {
    &:before {
      content: '\e166';
    }
  }
  &.__open {
    &:before {
      content: '\e167';
    }
  }
  &.__saved {
    &:before {
      content: '\e168';
    }
  }
  &.__import {
    &:before {
      content: '\e169';
    }
  }
  &.__export {
    &:before {
      content: '\e170';
    }
  }
  &.__send {
    &:before {
      content: '\e171';
    }
  }
  &.__floppy-disk {
    &:before {
      content: '\e172';
    }
  }
  &.__floppy-saved {
    &:before {
      content: '\e173';
    }
  }
  &.__floppy-remove {
    &:before {
      content: '\e174';
    }
  }
  &.__floppy-save {
    &:before {
      content: '\e175';
    }
  }
  &.__floppy-open {
    &:before {
      content: '\e176';
    }
  }
  &.__credit-card {
    &:before {
      content: '\e177';
    }
  }
  &.__transfer {
    &:before {
      content: '\e178';
    }
  }
  &.__cutlery {
    &:before {
      content: '\e179';
    }
  }
  &.__header {
    &:before {
      content: '\e180';
    }
  }
  &.__compressed {
    &:before {
      content: '\e181';
    }
  }
  &.__earphone {
    &:before {
      content: '\e182';
    }
  }
  &.__phone-alt {
    &:before {
      content: '\e183';
    }
  }
  &.__tower {
    &:before {
      content: '\e184';
    }
  }
  &.__stats {
    &:before {
      content: '\e185';
    }
  }
  &.__sd-video {
    &:before {
      content: '\e186';
    }
  }
  &.__hd-video {
    &:before {
      content: '\e187';
    }
  }
  &.__subtitles {
    &:before {
      content: '\e188';
    }
  }
  &.__sound-stereo {
    &:before {
      content: '\e189';
    }
  }
  &.__sound-dolby {
    &:before {
      content: '\e190';
    }
  }
  &.__sound-5-1 {
    &:before {
      content: '\e191';
    }
  }
  &.__sound-6-1 {
    &:before {
      content: '\e192';
    }
  }
  &.__sound-7-1 {
    &:before {
      content: '\e193';
    }
  }
  &.__copyright-mark {
    &:before {
      content: '\e194';
    }
  }
  &.__registration-mark {
    &:before {
      content: '\e195';
    }
  }
  &.__cloud-download {
    &:before {
      content: '\e197';
    }
  }
  &.__cloud-upload {
    &:before {
      content: '\e198';
    }
  }
  &.__tree-conifer {
    &:before {
      content: '\e199';
    }
  }
  &.__tree-deciduous {
    &:before {
      content: '\e200';
    }
  }
  &.__cd {
    &:before {
      content: '\e201';
    }
  }
  &.__save-file {
    &:before {
      content: '\e202';
    }
  }
  &.__open-file {
    &:before {
      content: '\e203';
    }
  }
  &.__level-up {
    &:before {
      content: '\e204';
    }
  }
  &.__copy {
    &:before {
      content: '\e205';
    }
  }
  &.__paste {
    &:before {
      content: '\e206';
    }
  }
  &.__alert {
    &:before {
      content: '\e209';
    }
  }
  &.__equalizer {
    &:before {
      content: '\e210';
    }
  }
  &.__king {
    &:before {
      content: '\e211';
    }
  }
  &.__queen {
    &:before {
      content: '\e212';
    }
  }
  &.__pawn {
    &:before {
      content: '\e213';
    }
  }
  &.__bishop {
    &:before {
      content: '\e214';
    }
  }
  &.__knight {
    &:before {
      content: '\e215';
    }
  }
  &.__baby-formula {
    &:before {
      content: '\e216';
    }
  }
  &.__tent {
    &:before {
      content: '\26fa';
    }
  }
  &.__blackboard {
    &:before {
      content: '\e218';
    }
  }
  &.__bed {
    &:before {
      content: '\e219';
    }
  }
  &.__apple {
    &:before {
      content: '\f8ff';
    }
  }
  &.__erase {
    &:before {
      content: '\e221';
    }
  }
  &.__hourglass {
    &:before {
      content: '\231b';
    }
  }
  &.__lamp {
    &:before {
      content: '\e223';
    }
  }
  &.__duplicate {
    &:before {
      content: '\e224';
    }
  }
  &.__piggy-bank {
    &:before {
      content: '\e225';
    }
  }
  &.__scissors {
    &:before {
      content: '\e226';
    }
  }
  &.__bitcoin {
    &:before {
      content: '\e227';
    }
  }
  &.__yen {
    &:before {
      content: '\00a5';
    }
  }
  &.__ruble {
    &:before {
      content: '\20bd';
    }
  }
  &.__scale {
    &:before {
      content: '\e230';
    }
  }
  &.__ice-lolly {
    &:before {
      content: '\e231';
    }
  }
  &.__ice-lolly-tasted {
    &:before {
      content: '\e232';
    }
  }
  &.__education {
    &:before {
      content: '\e233';
    }
  }
  &.__option-horizontal {
    &:before {
      content: '\e234';
    }
  }
  &.__option-vertical {
    &:before {
      content: '\e235';
    }
  }
  &.__menu-hamburger {
    &:before {
      content: '\e236';
    }
  }
  &.__modal-window {
    &:before {
      content: '\e237';
    }
  }
  &.__oil {
    &:before {
      content: '\e238';
    }
  }
  &.__grain {
    &:before {
      content: '\e239';
    }
  }
  &.__sunglasses {
    &:before {
      content: '\e240';
    }
  }
  &.__text-size {
    &:before {
      content: '\e241';
    }
  }
  &.__text-color {
    &:before {
      content: '\e242';
    }
  }
  &.__text-background {
    &:before {
      content: '\e243';
    }
  }
  &.__object-align-top {
    &:before {
      content: '\e244';
    }
  }
  &.__object-align-bottom {
    &:before {
      content: '\e245';
    }
  }
  &.__object-align-horizontal {
    &:before {
      content: '\e246';
    }
  }
  &.__object-align-left {
    &:before {
      content: '\e247';
    }
  }
  &.__object-align-vertical {
    &:before {
      content: '\e248';
    }
  }
  &.__object-align-right {
    &:before {
      content: '\e249';
    }
  }
  &.__triangle-right {
    &:before {
      content: '\e250';
    }
  }
  &.__triangle-left {
    &:before {
      content: '\e251';
    }
  }
  &.__triangle-bottom {
    &:before {
      content: '\e252';
    }
  }
  &.__triangle-top {
    &:before {
      content: '\e253';
    }
  }
  &.__console {
    &:before {
      content: '\e254';
    }
  }
  &.__superscript {
    &:before {
      content: '\e255';
    }
  }
  &.__subscript {
    &:before {
      content: '\e256';
    }
  }
  &.__menu-left {
    &:before {
      content: '\e257';
    }
  }
  &.__menu-right {
    &:before {
      content: '\e258';
    }
  }
  &.__menu-down {
    &:before {
      content: '\e259';
    }
  }
  &.__menu-up {
    &:before {
      content: '\e260';
    }
  }
  &.__remove-light {
    &:before {
      content: '\2715';
    }
  }
}
