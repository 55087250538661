@import '../globals';

.approved-widget_cnt {
  position: fixed;
  border: 1px solid #cdcdcd;
  background-color: #e5e5ed;
  padding: 5px;
  height: 245px;
}

.approved-widget_na-cnt {
  line-height: 160px;
  color: #6d6d6d;
  font-size: 15px;
}

.approved-widget_bg {
  background-position: center;
  background-repeat: no-repeat;
}

.approved-widget_img {
  width: 150px;
  height: auto;
}

.approved-widget_h {
  margin: 0;
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  color: #787878;
}
