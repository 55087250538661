@import '../globals';

.fields-divider-5 {
  margin-right: 5px;
}

.fields-divider-10 {
  margin-right: 10px;
}

.fields-divider-15 {
  margin-right: 15px;
}

.fields-divider-20 {
  margin-right: 20px;
}

.fields-divider-35 {
  margin-right: 35px;
}
