.sidebar {
  width: 200px;
}
.logo_container {
  padding: 10px 10px 30px;
  text-align: center;
  background-color: #3a3c45;
}
.build_name {
  margin-top: -10px;
  height: 50px;
  line-height: 52px;
  color: #afb5bd;
}
.label {
  margin: 0;
  color: #fff;
  line-height: 1.3;
  font-weight: 700;
  text-transform: uppercase;
}
.logo {
  display: block;
  margin: 0 auto 12px;
  max-width: 86px;
}

@media screen and (max-width: 1365px) {
  .sidebar {
    width: 40px;
  }
  .logo_container {
    display: flex;
    padding: 10px;
    height: 105px;
    align-items: center;
  }
  .logo {
    max-width: 100%;
  }
  .label,
  .build_name {
    display: none;
  }
}
