%h-header-reset {
  font-weight: normal;
  font-size: inherit;
  margin: 0;
}

%h-button-reset {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  &:focus {
    outline: none;
  }
}

%h-list-reset {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
