/*
* Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
* 600 North Bridge Road, Parkview Square #15-10, Singapore
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* License: see file “LICENSE.txt”
*/
.mod-table-container {
  padding: 5px 20px;
}

.mod-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  background: #fff;
  border: 1px solid rgba(26, 26, 29, 0.15);

  &__header {
    background-color: #a2a5b3;
  }
  th,
  td {
    vertical-align: top;
    padding: 5px 10px;
    line-height: 14px;
    border: 1px solid rgba(26, 26, 29, 0.15);
    text-overflow: ellipsis;
    overflow: hidden;

    div div {
      padding-left: 1em;
    }
    hr {
      margin: 5px 0;
    }
  }
  th {
    background-color: #a2a5b3;
    color: #fff;
    text-align: center;
    font-size: 11px;
    font-weight: 300;
    height: 35px;
    line-height: 35px;
    text-transform: uppercase;
    padding: 0;
    border-top: none;
  }
  th:first-child,
  td:first-child {
    border-left: none;
  }
  th:last-child,
  td:last-child {
    border-right: none;
  }

  &__inset-container {
    padding: 0 !important;
  }

  &__inset {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    td:first-child {
      border-left: none;
    }
    td:last-child {
      border-right: none;
    }
    tr:first-child td {
      border-top: none;
    }
    tr:last-child td {
      border-bottom: none;
    }

    tr {
      border-bottom: none;
    }
  }

  &__icon {
    font-family: FontAwesome;
    margin-right: 8px;

    &_add {
      color: #00cc22;
    }
    &_edit {
      color: #4cacf5;
    }
    &_del {
      color: #ff0004;
    }
  }
}

//small table

.mod-table_small tr {
  border-bottom: 1px;
}

//remove borders on history table
.content-tile_contains-history .mod-table {
  border: none;
}
