@import '../globals';

.text-view {
  @extend %h-textview-skin;
  @extend %h-truncate;
  &:not(:last-child) {
    margin-right: 10px;
  }

  &_responsive {
    width: 100%;
  }

  &_multiline {
    white-space: normal;
    height: auto;
    min-height: 26px;
  }

  &_card-content {
    max-width: 100%;
  }

  &_centered {
    text-align: center;
  }

  &_header-1 {
    padding: 0;
    border: none;
    font-size: 2.3rem;
    height: 3rem;
    line-height: 3rem;
  }

  &_header-2 {
    padding: 0;
    border: none;
    font-size: 1.7rem;
  }

  &_infinit {
    padding: 0;
    border: none;
    font-size: 3rem;
    font-weight: 300;
    color: #a1a1a1;
    line-height: 2.8rem;
  }

  &_infinit-bordered {
    font-size: 3rem;
    font-weight: 300;
    color: #a1a1a1;
  }

  &_border-blue {
    border-color: $blue;
  }

  &_border-green {
    border-color: $green;
  }

  &_border-violet {
    border-color: $violet;
  }

  &_border-red {
    border-color: $red;
  }

  &_number {
    padding-top: 0;
    padding-bottom: 0;
    height: 26px;
    line-height: 26px;
  }

  &_disabled {
    background-color: #e9e9f0;
    color: lighten($font-color-prime, 40%);
  }

  &_placeholder {
    color: $grey-placeholder;
    font-weight: 300;
  }

  &_hint {
    color: $grey-placeholder;
    font-weight: 300;
  }

  &_filled {
    @extend %h-textview-filled;
  }

  &_sharp {
    border-radius: 0;
  }

  &_link {
    @extend %h-textview-special;
    &:before {
      font-family: FontAwesome;
      content: '\f14c';
    }
  }

  &_user {
    @extend %h-textview-special;
    &:before {
      font-family: FontAwesome;
      content: '\f007';
    }
  }

  &_email {
    @extend %h-textview-special;
    &:before {
      font-family: FontAwesome;
      content: '\f003';
    }
  }

  &_phone {
    @extend %h-textview-special;
    &:before {
      font-family: FontAwesome;
      content: '\f095';
    }
  }

  &_date {
    @extend %h-textview-special;
    @extend %h-textview-filled;
    &:before {
      font-family: FontAwesome;
      content: '\f274';
    }
  }
  &_time {
    @extend %h-textview-special;
    @extend %h-textview-filled;
    &:before {
      font-family: FontAwesome;
      content: '\f017';
    }
  }
  &_tag {
    @extend %h-textview-special;
    @extend %h-textview-filled;
    &:before {
      font-family: FontAwesome;
      content: '\f02b';
    }
  }
  &_occupation {
    @extend %h-textview-special;
    @extend %h-textview-filled;
    &:before {
      font-family: FontAwesome;
      content: '\f0b1';
    }
  }

  &_interest {
    @extend %h-textview-special;
    @extend %h-textview-filled;
    &:before {
      font-family: FontAwesome;
      content: '\f004';
    }
  }
  &_site,
  &_sites {
    @extend %h-textview-special;
    @extend %h-textview-filled;
    &:before {
      font-family: FontAwesome;
      content: '\f041';
    }
  }
  &_campaign,
  &_campaigns {
    @extend %h-textview-special;
    @extend %h-textview-filled;
    &:before {
      font-family: FontAwesome;
      content: '\f0a1';
    }
  }

  &_labels,
  &_campaignsLabels {
    @extend %h-textview-special;
    @extend %h-textview-filled;
    &:before {
      @extend %h-icon-skin;
      content: '\f292';
    }
  }

  &_tags,
  &_tagsClasses,
  &_tagsTypes,
  &_campaignsTags {
    @extend %h-textview-special;
    @extend %h-textview-filled;
    &:before {
      @extend %h-icon-skin;
      content: '\f02b';
    }
  }

  &_entity,
  &_entities {
    @extend %h-textview-special;
    @extend %h-textview-filled;
    &:before {
      @extend %h-icon-skin;
      content: '\f19c';
    }
  }

  &_cities {
    @extend %h-textview-special;
    @extend %h-textview-filled;
    &:before {
      @extend %h-icon-skin;
      content: '\f0ac';
    }
  }
}
