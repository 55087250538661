@import '../globals';

.tabs-panel {
  height: 55px;
  min-height: 55px;
  background: #2e313c;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &__tabs-container {
    display: flex;
    flex-grow: 1;
  }
  &__buttons-container {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-left: 50px;
  }

  &__tab {
    @extend %h-truncate;
    height: 40px;
    line-height: 40px;
    max-width: 400px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    background: linear-gradient(315deg, #e1e3eb, #cbced6);
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: inset 1px -5px 4px -2px rgba(0, 0, 0, 0.06);
    text-align: center;
    cursor: pointer;
    transition: opacity 0.2s ease;
    text-transform: uppercase;
    font-weight: 700;
    color: #737479;

    &:hover {
      opacity: 0.95;
    }

    &_active,
    &_active:hover {
      background: $white !important;
      color: $font-color-prime;
      border: none;
      cursor: auto;
      opacity: 1;
      color: $font-color-prime;
    }
  }

  &__tab-label {
    position: relative;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #6e6f72;
  }

  &__indicator {
    position: absolute;
    top: 0;
    left: 100%;
    margin-left: 5px;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #b0b0b8;

    &_blue {
      background: $blue;
    }
    &_error {
      background: $red;
    }
  }
}

//style secondary menu
.tabs-panel_secondary {
  height: 36px;
  min-height: 36px;
  padding: 0;
}

.tabs-panel_secondary .tabs-panel__tab {
  height: 36px;
  line-height: 36px;
  background: #f2f1f6;
  box-shadow: inset 5px -5px 9px -2px rgba(0, 0, 0, 0.06);
  font-weight: 600;
  color: #858396;
  text-transform: none;
  max-width: none;

  &:hover {
    opacity: 0.95;
  }

  &_active,
  &_active:hover {
    background: $white;
    color: #858396;
    border: none;
    box-shadow: none;
    cursor: auto;
    opacity: 1;
  }
}
