@import '../globals';

html {
  font-size: 62.5%;

  @include desktop-sm {
    font-size: 57.29%;
  }
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  color: $font-color-base;
  line-height: $line-height-base;
}

p,
.paragraph {
  display: block;
  font-family: $font-family-base;
  font-size: $font-size-base;
  color: inherit;
  line-height: $line-height-base;
  margin: 0 0 10px 0;
  &:last-child {
    margin-bottom: 0;
  }
  &.fix-margin {
    margin-bottom: 10px;
  }
}
