@import '../globals';

%widget-element-placeholder-skin {
  display: block;
  width: 100%;
  background: #ccccd8;
  border: 1px dashed #a0a1b3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-base;
  font-weight: 600;
  color: #91919f;
  min-height: 20px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.widgets-section {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  display: flex;
  align-items: stretch;

  &__viewer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 15px;
    background: #dcdce6;
    border: 1px dashed #a0a1b3;
  }

  &__aside {
    width: 300px;
    background: #454851;
  }

  &__header {
    margin: 0;
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;
    text-align: center;
    background: #000;
    color: $white;
    font-size: $font-size-base;
    font-weight: normal;
    border-bottom: 1px dashed #a0a1b3;
  }

  &__preview-cnt {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__preview-holder-centered {
    width: 370px;
  }

  &__preview-area {
    @extend %widget-element-placeholder-skin;
    height: 520px;
  }

  &__buttons {
    @extend %widget-element-placeholder-skin;
    min-height: 50px;
    border-radius: 4px;
  }

  &__terms {
    @extend %widget-element-placeholder-skin;
    border-radius: 4px;
  }

  &__placeholder-label {
    @extend %h-header-reset;
    color: #8787a1;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  &__add-background-logo {
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: #91919f;
    font-weight: 600;
  }
}
