.flex {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.flex__group {
  display: flex;
  align-items: center;
}

.flex,
.flex__group {
  &_center-v {
    align-items: center;
  }

  &_center-h {
    justify-content: center;
  }

  &_stretch-cols {
    align-items: stretch;
  }

  &_justify-cols {
    justify-content: space-between;
  }

  &_align-end {
    justify-content: flex-end;
  }

  &_column {
    flex-direction: column;
  }

  &_wrap {
    flex-wrap: wrap;
  }

  &_section {
    margin-bottom: 10px;
  }

  &_headline {
    height: 35px;
    align-items: center;
  }
}

.flex__col,
.flex__group {
  &_grow,
  &_grow-1 {
    flex-grow: 1;
  }

  &_grow-2 {
    flex-grow: 2;
  }

  &_grow-3 {
    flex-grow: 3;
  }

  &_stretch {
    align-self: stretch;
  }

  &_has-gap-10 {
    margin-right: 10px;
  }

  &_has-gap-15 {
    margin-right: 15px;
  }

  &_has-border-right {
    &:not(:last-child) {
      border-right: 1px solid #e4e5e7;
    }
  }
}

.flex__col {
  &_10 {
    width: 10%;
  }
  &_20 {
    width: 20%;
  }
  &_30 {
    width: 30%;
  }
  &_40 {
    width: 40%;
  }
  &_50 {
    width: 50%;
  }
  &_60 {
    width: 60%;
  }
  &_70 {
    width: 70%;
  }
  &_80 {
    width: 80%;
  }
  &_90 {
    width: 90%;
  }

  //special cases
  &_admin-permissions-campaigns-cnt {
    width: 80%;
  }
}

//equal cols
.flex_equal-cols > .flex__col {
  flex-basis: 0;
  flex-grow: 1;
}

.flex_fix-basis > .flex__col {
  flex-basis: 0;
}

//col with border (divider)
.flex__col_border-right {
  position: relative;
  &:after {
    content: '';
    background: #e5e5e5;
    display: block;
    width: 1px;
    position: absolute;
    right: 0;
    top: 15px;
    bottom: 15px;
  }
}

//vertical flex grid
.flex_vertical {
  flex-direction: column;

  .flex__col {
    width: 100%;
  }
}

.flex_horizontal {
  flex-direction: row;
}

//reset <ul> within flex grid

ul.flex {
  margin: 0;
  padding: 0;
}

.flex-row {
  display: flex;
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }

  &__col {
    flex-basis: 0;
    flex-grow: 1;
    &:not(:last-child) {
      margin-right: 15px;
    }

    &_grow-1 {
      flex-grow: 1;
    }

    &_grow-2 {
      flex-grow: 2;
    }

    &_grow-3 {
      flex-grow: 3;
    }

    &_with-border {
      border: 1px solid #ededed;
      padding: 0 10px 10px 15px;
      &:not(:last-child) {
        margin-right: 30px;
      }

      .flex-row__col-inner {
        margin-top: -10px;
      }

      .checkbox {
        background: $white;
        padding: 0 5px;
      }
    }
  }
}

.columns {
  &_2 {
    column-count: 2;
  }
  &_3 {
    column-count: 3;
  }
  &_4 {
    column-count: 4;
  }
  &_5 {
    column-count: 5;
  }

  &_bordered {
    border: 1px solid #f2f1f6;
    padding: 5px 10px;
    border-radius: 4px;
  }

  &__col {
    overflow: hidden; /* Fix for firefox and IE 10-11  */
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    page-break-inside: avoid; /* Firefox */
    break-inside: avoid; /* IE 10+ */
    break-inside: avoid-column;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
