@import '../globals';

.accordion {
  @extend %h-list-reset;

  &__tab {
    position: relative;
    display: block;
    min-height: 35px;
    padding: 5px 8px;
    border-bottom: 1px solid #50545e;
  }

  &__tab_collapsed {
    cursor: pointer;
    transition: 0.2s ease;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
      .accordion__expand-arrow {
        top: 7px;
      }
    }
  }

  &__tab-title {
    @extend %h-header-reset;
    color: #9da1ab;
    font-size: 1.3rem;
  }

  &__expand-arrow {
    @extend %h-icon-skin;
    position: absolute;
    top: 5px;
    right: 10px;
    color: #9ea2b3;
    font-size: 18px;
    transition: 0.2s ease;
  }
}
