//
// noselect
// --------------------------------------------------

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.separator {
  display: inline-block;
  margin: 0 10px;
}
