/*
* Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
* 600 North Bridge Road, Parkview Square #15-10, Singapore
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* License: see file “LICENSE.txt”
*/

@import '../globals';
.ui-widget-content {
  background: #ffffff 50% 50% repeat-x;
  color: #222222;
}
.ui-widget-content {
  background: #5f626b 50% 50% repeat-x;
  color: #222222;
}
.ui-slider-horizontal {
  height: 5px;
}
.ui-slider {
  position: relative;
  text-align: left;
  border: none !important;
}
.sliderExample {
  width: 640px;
  padding: 2em;
  background-color: #f5f5f5;
  border: 1px solid #f0f0f0;
  margin: 2em;
}

.vertical-container {
  width: 15%;
  display: inline-block;
}
#swatch {
  width: 100px;
  height: 100px;
  border: 1px solid black;
}
#red .ui-slider-range {
  background: #ef2929;
}
#red .ui-slider-handle {
  border-color: #ef2929;
}
#green .ui-slider-range {
  background: #8ae234;
}
#green .ui-slider-handle {
  border-color: #8ae234;
}
#blue .ui-slider-range {
  background: #729fcf;
}
#blue .ui-slider-handle {
  border-color: #729fcf;
}

// track
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #e6e6e6 50% 50% repeat-x;
  font-weight: normal;
  color: #555555;
}
.ui-slider-horizontal .ui-slider-handle {
  top: 6px;
  margin-left: -5px;

  &:focus {
    outline: none;
  }
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  cursor: default;
  top: auto;
  bottom: -1px;
  background: none;
  border: none;
}
.ui-slider .ui-slider-handle::after {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom: 10px solid #cac9cf;
}

.ui-widget-content a {
  color: #222222;
}
