@charset "UTF-8";
/*
* Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
* 600 North Bridge Road, Parkview Square #15-10, Singapore
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* License: see file “LICENSE.txt”
*/
/* line 9, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf {
  background-color: #eae9f0;
  padding: 10px 10px 10px 20px;
  margin-bottom: 10px;
}
/* line 16, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf [date-picker] {
  padding: 0 10px 10px 10px;
  background-color: #dddce1;
  border-radius: 0;
  border: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
/* line 24, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf [date-picker] table {
  background-color: #dddce1;
  border-radius: 0;
  border: none;
  margin-top: 4px;
}
/* line 31, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf [date-picker] table:before {
  background: #dddce1;
  border: none;
}
/* line 36, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf [date-picker] th:hover,
.analytics-tf [date-picker] [ng-switch-when='date'] td span:hover {
  background: #cfced6;
}
/* line 41, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf [date-picker] .active,
.analytics-tf [date-picker] .now {
  color: #fff;
  background-color: #9f9fa7;
}
/* line 47, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf [date-picker] .active.active {
  color: #000;
  background-color: #cfced6;
}
/* line 52, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf [date-picker] .active:hover,
.analytics-tf [date-picker] .active:active,
.analytics-tf [date-picker] .active.disabled,
.analytics-tf [date-picker] .active[disabled] {
  color: #000;
  background-color: #cfced6;
}
/* line 60, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf [date-picker] .now:hover,
.analytics-tf [date-picker] .now:active,
.analytics-tf [date-picker] .now.active,
.analytics-tf [date-picker] .now.disabled,
.analytics-tf [date-picker] .now[disabled] {
  color: #000;
}

/* line 70, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_block {
  display: inline-block;
  vertical-align: top;
  background-color: #f3f2f7;
  padding: 4px 10px;
  margin-right: 10px;
}
/* line 77, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_block .settings-form_dropdown_btn {
  background-color: transparent !important;
  text-align: left !important;
}
/* line 82, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_block .form-control span {
  font-size: 12px !important;
}
/* line 86, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_block .validation_msg {
  min-width: 228px;
  white-space: nowrap;
}
/* line 89, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_block .validation_msg.__right {
  right: -10px;
  transform: translate(100%, -50%);
}
/* line 95, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_block input.ng-invalid,
.analytics-tf_block .form-control.ng-invalid {
  border: 1px solid #fe143e !important;
}

/* line 101, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_label {
  margin-right: 5px;
  font-size: 11px;
}

/* line 106, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_period-it {
  border: none;
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
  width: 75px;
}

/* line 114, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_period-it-w {
  position: relative;
}

/* line 118, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_allTime-it {
  margin-left: 10px;
  margin-bottom: 0;
}
/* line 121, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_allTime-it span {
  font-weight: normal;
  padding-left: 15px !important;
}

/* line 127, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_timeframe-it,
.analytics-tf_days-it {
  border: none !important;
  border-radius: 4px;
  background-color: #fff;
}
/* line 132, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_timeframe-it[disabled],
.analytics-tf_days-it[disabled] {
  pointer-events: none;
}
/* line 136, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_timeframe-it.dropdown,
.analytics-tf_days-it.dropdown {
  font-size: 12px !important;
}
/* line 138, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_timeframe-it.dropdown .dropdown_menu,
.analytics-tf_days-it.dropdown .dropdown_menu {
  background: #e5e5ed;
  border: 0;
  z-index: 21;
  min-height: 200px;
}
/* line 144, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_timeframe-it.dropdown .dropdown_menu-i,
.analytics-tf_timeframe-it.dropdown .dropdown_menu-i > span,
.analytics-tf_timeframe-it.dropdown .dropdown_menu-i > a,
.analytics-tf_days-it.dropdown .dropdown_menu-i,
.analytics-tf_days-it.dropdown .dropdown_menu-i > span,
.analytics-tf_days-it.dropdown .dropdown_menu-i > a {
  background-color: #e5e5ed;
}
/* line 149, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_timeframe-it.dropdown .dropdown_menu-i:hover,
.analytics-tf_timeframe-it.dropdown .dropdown_menu-i:focus,
.analytics-tf_timeframe-it.dropdown .dropdown_menu-i > span:hover,
.analytics-tf_timeframe-it.dropdown .dropdown_menu-i > span:focus,
.analytics-tf_timeframe-it.dropdown .dropdown_menu-i > a:hover,
.analytics-tf_timeframe-it.dropdown .dropdown_menu-i > a:focus,
.analytics-tf_days-it.dropdown .dropdown_menu-i:hover,
.analytics-tf_days-it.dropdown .dropdown_menu-i:focus,
.analytics-tf_days-it.dropdown .dropdown_menu-i > span:hover,
.analytics-tf_days-it.dropdown .dropdown_menu-i > span:focus,
.analytics-tf_days-it.dropdown .dropdown_menu-i > a:hover,
.analytics-tf_days-it.dropdown .dropdown_menu-i > a:focus {
  background-color: #cfced6;
}
/* line 153, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_timeframe-it.dropdown:before,
.analytics-tf_days-it.dropdown:before {
  background-color: #e5e5ed;
  border: 0;
  top: 29px;
}
/* line 159, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_timeframe-it.dropdown .ps-scrollbar-y,
.analytics-tf_days-it.dropdown .ps-scrollbar-y {
  background-color: #9f9fa7;
}
/* line 163, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_timeframe-it.dropdown .ps-scrollbar-y-rail,
.analytics-tf_days-it.dropdown .ps-scrollbar-y-rail {
  background-color: #dddce1;
}

/* line 170, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_days-it {
  height: 21px;
}
/* line 172, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_days-it .dropdown_menu {
  width: auto !important;
  min-width: 65px !important;
  min-height: initial !important;
}
/* line 177, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_days-it .btn {
  min-height: initial !important;
  padding: 0 10px !important;
  width: auto !important;
}
/* line 182, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_days-it .dropdown_menu-i {
  padding: 0 7px;
  margin-bottom: 0;
}
/* line 185, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_days-it .dropdown_menu-i label.contain-checkbox {
  padding: 2px 0;
  margin: 0;
  white-space: nowrap;
}
/* line 191, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_days-it:before {
  left: 50%;
  transform: translateX(-50%);
  top: 25px !important;
}
/* line 196, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_days-it label {
  font-weight: normal;
}
/* line 198, static/sass/project/analyticsTimeFilters.scss */
.analytics-tf_days-it label span {
  padding-left: 17px;
}

/* line 204, static/sass/project/analyticsTimeFilters.scss */
.__monitoring .analytics-tf_block.__timeframe,
.__monitoring .analytics-tf_block.__days {
  display: none;
}
