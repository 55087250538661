@import '../globals';

.progress-report {
  width: 180px;
  height: 12px;
  line-height: 12px;
  border-radius: 6px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.progress-report_completed-on {
  font-size: 11px;
  margin-top: 5px;
}

.progress-report_rail {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #c2e0f7;
  display: block;
}

.progress-report_track {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #73c4ff;
}

.progress-report_text {
  position: relative;
  display: block;
  font-size: 10px;
  color: $font-color-prime;
}

//big progress bar

.reporting-no-yet {
  .progress-report {
    width: 900px;
    z-index: 100;
  }

  .progress-report_completed-on {
    font-size: 13px;
    margin-top: 10px;
    text-align: center;
  }
}
