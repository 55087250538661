@import '../globals';

.colorpicker.dropdown {
  position: absolute;
  top: -29px !important;
  left: 100% !important;
  background: #fff;
  padding: 12px;
  min-width: 0 !important;
  width: 153px;
  height: 145px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}

button.close {
  position: absolute;
  top: 0px;
  right: 2px;
  opacity: 1;
  color: $blue-thin-lines;
}

.colorpicker .dropdown-menu::after {
  top: 35px;
  left: -6px;
}

.colorpicker-position-bottom .dropdown-menu::after {
  border: 6px solid transparent;
  border-right: 6px solid #fff;
  border-left: 0;
}
.colorpicker-position-bottom .dropdown-menu::before {
  border: 6px solid transparent;
  border-right: 7px solid #aaa;
  border-left: 0;
}
.colorpicker .dropdown-menu::before {
  top: 35px;
  left: -8px;
}
colorpicker-hue,
colorpicker-alpha {
  width: 17px;
}
