.busy-overlay {
  background-color: rgba(229, 229, 237, 0.95);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  &.ng-enter {
    transition: all 0.1s ease;
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }
}
.busy-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  svg path {
    fill: #3e3d3a;
  }
}
