@import '../globals';

$authorizing-input-background: linear-gradient(
  to right,
  #4e5163 36px,
  #3a3e4f 36px
);

.authorizing {
  display: block;
  position: relative;
  padding: 30px 50px;

  &__system-version {
    position: fixed;
    top: 10px;
    left: 10px;
    color: $white;
  }

  &__logo {
    max-width: 100px;
  }

  &__title {
    margin: 0;
    color: $white;
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-top: 2.5rem;
    @extend %h-truncate;
  }

  &__inputs-group {
    position: relative;
    margin-top: 3rem;
    color: $white;

    &_error {
      color: #ba0404;
    }
  }

  &__input {
    @extend %h-truncate;
    @extend %h-placeholder-skin;
    display: block;
    width: 100%;
    height: 30px;
    border-radius: 4px;
    border: none;
    background-image: $authorizing-input-background; //find at the top of the this stylesheet
    background-repeat: no-repeat;
    background-color: #3a3e4f;
    color: inherit;
    font-size: $font-size-base;
    font-weight: 300;
    padding-left: 46px;
    margin-bottom: 10px;
    margin-right: 0;
    &:focus {
      outline: none;
    }
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
    &_user {
      background-image: url('../../images/icon_user_login.png'),
        $authorizing-input-background;
      background-position: 10px center, center center;
    }
    &_email {
      background-image: url('../../images/icon_user_email.png'),
        $authorizing-input-background;
      background-position: 10px center, center center;
    }
    &_password {
      background-image: url('../../images/icon_user_pass.png'),
        $authorizing-input-background;
      background-position: 10px center, center center;
    }
    &_password-2 {
      background-image: url('../../images/icon_user_pass2.png'),
        $authorizing-input-background;
      background-position: 10px center, center center;
    }

    &_error {
      color: #ba0404;
    }
  }

  &__msg-cnt {
    position: absolute;
    display: table;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 18px);
  }

  &__error-msg,
  &__success-msg {
    position: relative;
    display: block;
    background: #ba0404 url('../../images/icon-attention.png') no-repeat 8px
      center;
    color: $white;
    font-weight: 300;
    padding: 14px 14px 14px 38px;
    text-align: left;
    max-width: 300px;
    margin: 10px 0;
    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      height: 0;
      width: 0;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 9px solid #ba0404;
    }
  }

  &__success-msg {
    background-color: $green;
    &:before {
      border-right: 9px solid $green;
    }
  }

  &__controls-group {
    margin-top: 3rem;
  }

  &__notes {
    text-align: center;
    margin-top: 2rem;
    font-size: $font-size-base;
    line-height: 1.7;
    color: #e0dada;
    max-width: 300px;
    padding: 0 40px;
  }

  &__link {
    color: #e0dada;
    border-bottom: 1px solid #e0dada;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      color: #fff;
      border-bottom: 1px solid #fff;
    }
  }

  &__lang-switcher {
    position: absolute !important; //lang-switcher has position: relative by default to style drop-down list properly
    top: 10px;
    right: 10px;
  }
}

//add specific styles for buttons (have to increase specificity) :

.authorizing .authorizing__button {
  width: 200px;
  padding: 0.85rem 1.4rem;
  &:not(:last-child) {
    margin-bottom: 12px;
    margin-right: 0;
  }
}
