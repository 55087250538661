@import '../globals';

// !!! BE CAREFUL, this block makes use of !important rules.
// Despite the bad reputation of this practice, in some cases this rule helps to reduce unnecessary styling and keep things clear and simple

%l-cloud-item-icon {
  position: relative;
  padding-left: 25px;
  &:before {
    @extend %h-icon-skin;
    color: #9197b3;
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.cloud {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -10px;
  &__item {
    @extend %h-textview-skin;
    @extend %h-truncate;
    @extend %h-textview-filled;
    border: none;
    margin-bottom: 10px;
    &:not(:last-child) {
      margin-right: 10px;
    }

    &_sharp {
      background-color: transparent !important;
      border-radius: 0;
      border: 1px solid #b3b9c7;
    }

    &_type {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f0f7';
      }
    }

    &_entity {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f19c';
      }
    }

    &_collection {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f005';
      }
    }

    &_place {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f0ac';
      }
    }

    &_class {
      @extend %l-cloud-item-icon;
      padding-left: 21px;
      &:before {
        content: '\f155';
      }
    }

    &_date {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f274';
      }
    }

    &_occupation {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f0b1';
      }
    }

    &_interest {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f004';
      }
    }

    &_keyword {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f002';
      }
    }

    &_url {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f14c';
      }
    }

    &_campaign {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f0a1';
      }
    }

    &_site {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f041';
      }
    }

    &_tag {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f02b';
      }
    }

    &_label {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f292';
      }
    }

    &_location {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f124';
      }
    }

    &_email {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f003';
      }
    }

    &_phone {
      @extend %l-cloud-item-icon;
      &:before {
        content: '\f095';
      }
    }
  }

  &__del-btn {
    @extend %h-button-reset;
    @extend %h-icon-skin;
    color: $blue-thin-lines;
    font-size: 13px;
    cursor: pointer;
    margin-left: 5px;
  }

  .button {
    margin-bottom: 10px;
  }
}

//edit mode

.cloud__item_edit {
  background-color: #dbefff;
  padding-right: 7px;

  &:before {
    color: $blue-thin-lines;
  }
}

.cloud__item_sharp.cloud__item_edit {
  border-color: $blue-thin-lines;
}
