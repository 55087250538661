/*
* Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
* 600 North Bridge Road, Parkview Square #15-10, Singapore
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* License: see file “LICENSE.txt”
*/
.impact_chart {
  overflow: hidden;
  width: 100%;
  position: relative;
  background-size: 1px;
  background-position: 0, 25%, 50%, 75%;
  background-repeat: repeat-y;
  background-image: linear-gradient(90deg, #4d4e53 1px, transparent 1px),
    linear-gradient(90deg, #4d4e53 1px, transparent 1px),
    linear-gradient(90deg, #4d4e53 1px, transparent 1px),
    linear-gradient(90deg, #4d4e53 1px, transparent 1px);
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: #4d4e53;
    right: 0;
    top: 0;
    height: 100%;
    width: 1px;
  }
}

.impact_li {
  position: relative;
  height: 30px;
  padding: 5px;
  border-bottom: 5px solid rgba(100, 104, 131, 0.7);
  &.__is-current {
    .impact_bar {
      border-bottom: 5px solid #59b8fc;
      cursor: default;
    }
    .impact_specificity-cnt {
      background-color: #59b8fc;
    }
  }
}

.impact_caption {
  cursor: pointer;
}
.impact_title {
  display: inline-block;
  max-width: calc(100% - 45px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.impact_bar {
  position: absolute;
  bottom: -5px;
  white-space: nowrap;
  border-bottom: 5px solid #c4b9d9;
  color: #aeb7c6;
}

.impact_specificity-cnt {
  background-color: #aea3c5;
  display: inline-block;
  min-width: 10px;
  padding: 2px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #141318;
  text-align: center;
  white-space: nowrap;
  border-radius: 10px;
  margin-left: 3px;
}

.impact-tooltip_cnt {
  position: absolute;
  left: 50%;
  margin-top: -30px;
  transform: translate(-50%, -100%);
  width: 95%;
  background-color: #575c70;
  color: #fff;
  font-size: 1.3rem;
  line-height: 1.5;
  &:after {
    width: 15px;
    height: 15px;
    top: 100%;
    left: 50%;
    border-top: 10px solid #575c70;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    content: ' ';
    pointer-events: none;
    transform: translateX(-50%);
  }
  @media (max-width: 1680px) {
    width: 90%;
  }
}

.impact-tooltip_scroll_cnt {
  overflow: hidden;
  position: relative;
  width: 100%;
  .ps-scrollbar-y-rail {
    background-color: transparent !important;
    right: 5px !important;
  }
  .ps-scrollbar-y {
    background-color: #fff !important;
  }
}

.impact-tooltip_h-cnt {
  padding: 5px 10px;
}

.impact-tooltip_h {
  text-align: left;
  color: #d3cceb;
  margin: 2px 0 0 0;
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  a {
    color: inherit;
  }
}

.impact-tooltip_statistic-cnt {
  border-top: 1px solid #676975;
  border-bottom: 1px solid #676975;
  padding: 5px 10px;
}

.impact-tooltip_sites-cnt-label {
  padding: 5px;
}

.impact-tooltip_sites-cnt {
  padding: 0 0 10px;
  margin: 0 10px;
  position: relative;
  overflow: hidden;
  display: block;

  .impact-tooltip_sites-pagination {
    height: 40px;
    min-height: 40px;
    background-color: rgba(225, 225, 235, 0.49);
  }
}

.impact-tooltip_label {
  color: #b2b4c0;
}

.impact-tooltip_sites-ul {
  list-style: none;
  margin: 0;
  background-color: #6a6f8a;
  padding: 5px 10px;
  line-height: 1.7;
  font-size: 11px;
  color: #ecebf1;
}

.impact-tooltip_sites-pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 7px 6px;
  margin: 0;
  background-color: #8e93ad;

  .pagination__btn_group,
  .nav-bar__items-per-page {
    display: none;
  }
}

.impact-tooltip_bnt-close {
  font-size: 21px;
  margin-top: 0px;
  margin-right: -2px;
  line-height: 1;
  cursor: pointer;
}
