.u-hide-tablet {
  @include tablet {
    display: none;
  }
}

.u-hide-desktop-sm {
  @include desktop-sm {
    display: none;
  }
}
