@import '../globals';

.datepicker-cnt {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-right: 15px;
  }
}

.datepicker {
  position: relative;

  &__input {
    @extend %h-textfield-skin;
    @extend %h-placeholder-skin;
    @extend %h-truncate;
    border: none;
    background: $lightblue;
    width: 135px;
    text-align: center;
    padding-left: 25px;
    cursor: pointer;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAMAAAAolt3jAAAAOVBMVEUAAABHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfz+65eQAAAAEnRSTlMAAwQJDA0OEBFkZ3OV09fZ+fvVzf45AAAAU0lEQVQIW2XOCw6AIAwD0OKPKR+19z+sOJlg7JImL8ACgO1YYV1ylrFGSART0g4AyZGsrWwpREtP96HsrnKYb4mdTllUdtln1ft28b/ND2P3i4gLYi8Fj6v47iAAAAAASUVORK5CYII=');
    background-repeat: no-repeat;
    background-position: 6px 6px;
  }

  &__infinite-placeholder {
    width: 135px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    background-color: #e9e9f0;
    color: lighten($font-color-prime, 40%);
    border-radius: 4px;
  }
}

.submitted .datepicker__input.ng-invalid {
  border: 1px solid $red;
}

[date-picker-wrapper] {
  z-index: 1000 !important;
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  margin-top: 10px;
  background: #fff !important;
  border-radius: 4px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.33);
}

[date-picker] {
  top: 0 !important;
  padding: 0px;
  border: 1px solid #21a7ee;
}
[date-picker] span {
  width: auto !important;
  min-width: 30px !important;
  padding: 1px;
}

[date-picker] [ng-switch-when='hours'] span,
[date-picker] [ng-switch-when='minutes'] span {
  min-width: 42px !important;
}

[date-picker] table {
  width: 100%;
  min-width: 200px !important;
}

[date-picker] table:before {
  top: -2px !important;
}

[date-picker] .active.active {
  background: $blue !important;
}

[date-picker] .now {
  background: $green !important;
}

[date-picker] [ng-switch-when='year'] span,
[date-picker] [ng-switch-when='month'] span,
[date-picker] [ng-switch-when='minutes'] span {
  height: 26px;
  line-height: 26px;
}
