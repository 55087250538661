@import '../globals';

.linked-widgets {
  width: 360px;
  padding: 15px 20px;
  background: #f2f1f6;
  @include desktop-sm {
    width: 300px;
    padding: 10px;
  }

  &__title {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #7d7a91;
    margin: 0 0 10px -5px;
  }

  &__chain-field {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__chain-field-label {
    padding-right: 15px;
  }

  &__chain-field-input {
    flex: 1 1;
  }

  &__viewer-container {
    display: flex;
    height: 475px;
  }

  &__viewer {
    align-self: stretch;
    position: relative;
    width: 100%;
    min-width: 0;
    height: 475px;
    border: 1px solid #d9d8dd;
    display: flex;
  }

  &__viewer-inner {
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
  }

  &__widget {
    position: relative;
    display: block;
    width: 100%;
  }

  &__preview-img,
  &__preview-img-small {
    width: 100%;
    height: 100%;
  }

  &__link-btn {
    @extend %h-button-reset;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgba(66, 175, 252, 0.6) url('../../images/camp_link.png')
      no-repeat center;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: rgba(66, 175, 252, 0.7);
    }

    &.disabled {
      background-color: rgba(128, 128, 128, 0.32);
    }
  }

  &__widget-data {
    height: calc(475px - 345px - 4px);
    background-color: $white;
    padding: 15px;
  }

  &__widget-name {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0 12px 0;
  }

  &__previews-container {
    position: relative;
    align-self: flex-start;
    height: 475px;
    width: 70px;
    min-width: 70px;
    padding-left: 10px;
    text-align: center;
    transition: transform 0.3s ease;

    &_contains-arrows {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &__previews-container-inner {
    transition: transform 0.3s ease;
  }

  &__previews-overflow {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  &__preview {
    position: relative;
    display: block;
    width: 100%;
    height: 85px;
    line-height: 85px;
    text-align: center;
    color: $white;
    font-size: 30px;
    font-weight: 600;
    background-color: #e5e5ed;
    box-shadow: inset 0 0 0 1px #b8b8c0;
    padding: 1px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &_type {
      background-color: #ceced6;
    }

    &_placeholder {
      background: url('../../images/widgets/icon-bg-stripe.png');
      box-shadow: inset 0 0 0 2px $blue;
    }

    &_current {
      padding: 2px;
      box-shadow: inset 0 0 0 2px $violet;
    }
  }

  &__preview-background {
    width: 100%;
    height: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__preview-foreground {
    width: 80%;
    height: auto;

    &_cover {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
  }

  &__remove-btn {
    @extend %h-button-reset;
    @extend %h-icon-skin;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    color: $white;
    background-color: $blue-thin-lines;
    font-size: 11px;
    cursor: pointer;
  }

  &__arrow-up {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    display: block;
    width: 0;
    height: 0;
    margin-left: 5px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $blue-thin-lines;
  }

  &__arrow-down {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    display: block;
    width: 0;
    height: 0;
    margin-left: 5px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $blue-thin-lines;
  }
}

//validation

.linked-widgets__validation-checkbox {
  display: none;
}

.submitted {
  .linked-widgets__validation-checkbox.ng-invalid ~ .linked-widgets__viewer {
    border-color: $red;
  }
}

//states

.linked-widgets__viewer_edit {
  border: 2px solid $blue;
}

.linked-widgets__viewer_edit .linked-widgets__widget-data {
  background: $blue;
}
