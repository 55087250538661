@import '../globals';

.content-tile {
  position: relative;
  background: $white;
  min-height: 96px;

  &:not(:last-child),
  &_fix-margin,
  &_fix-margin:last-child {
    margin-bottom: 10px;
  }

  &_single-line {
    min-height: 64px;
  }

  &_expand {
    min-height: 600px;
  }

  &_full-height {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &_height-100 {
    height: 100%;
  }

  &_noheader {
    .content-tile__header {
      display: none;
    }
  }

  &__header {
    height: 36px;
    display: flex;
    align-items: center;
    background: #f2f1f6;

    &_secondary {
      background: #e1e1eb;
    }

    &_dark {
      background: #545662;

      .content-tile__title {
        color: $white;
      }
    }
  }

  &__subheader {
    height: 40px;
    background-color: #f5f6fa;
    display: flex;
    align-items: center;
  }

  &__title {
    @extend %h-header-reset;
    padding-left: 15px;
    font-size: $font-size-base;
    font-weight: 600;
    color: #858396;
  }

  &__buttons-holder {
    flex-grow: 1;
    align-self: stretch;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    padding-right: 5px;

    &_nopadding {
      padding: 0;
    }
  }

  &__select-holder {
    padding: 0 10px;
  }

  &__expand-btn {
    @extend %h-button-reset;
    position: relative;
    align-self: stretch;
    width: 41px;
    height: 36px;
    margin-right: -5px;
    background-color: #646675;
    transition: background-color 0.2s ease;
    cursor: pointer;
    &:after {
      content: '\f107';
      font-family: FontAwesome;
      color: $white;
      font-size: 18px;
      -webkit-font-smoothing: antialiased;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &:hover {
      background-color: darken(#646675, 10%);
    }

    &_disabled {
      background-color: #c7c7cf;
      cursor: default;
      &:hover {
        background-color: #c7c7cf;
      }
    }

    &_open:after {
      content: '\f106';
    }
  }

  &__body {
    position: relative;
    padding: 15px;
    &_full-height {
      height: calc(100% - 36px);
    }
  }

  &__aside {
    width: 250px;
    background: #f2f1f6;
  }

  &__aside-header {
    background: #e0dfe8;
    color: #898799;
    font-size: $font-size-base;
    font-weight: 600;
    height: 36px;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  &__aside-menu {
    @extend %h-list-reset;
  }

  &__aside-menu-item-cnt {
  }

  &__aside-menu-item {
    display: block;
    height: 36px;
    line-height: 36px;
    padding-left: 25px;
    cursor: pointer;
    border-right: 5px solid #f2f1f6;
    transition: border-color 0.3s ease, background-color 0.3s ease,
      color 0.3s ease;

    &:hover,
    &_active {
      background-color: $white;
      border-right: 5px solid $blue;
    }
  }

  &__extend-block {
    display: block;
    height: 50px;
  }

  &__no-items {
    padding: 30px;
    text-align: center;
  }
}

.content-tile_secondary {
  border: 1px solid #e6ebf0;

  .content-tile__title {
    font-weight: normal;
  }
}

.content-tile_slidedown {
  position: relative;
  max-height: 96px;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 10px;
    background: $white;
  }
}

.content-tile_slidedown-open,
.content-tile_slidedown-responsive {
  max-height: 9999px;
  overflow: visible;
  &:after {
    display: none;
  }

  &_infinity-height {
    max-height: none;
  }
}

.content-tile_contains-history {
  .content-tile__body {
    padding: 0;
  }
}

.xls-upload-sites {
  display: inline-flex;
  align-items: baseline;
  margin-right: 18px;
}
