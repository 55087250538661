@import '../globals';

.nav-bar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  min-height: 50px;
  background-color: #e1e1eb;
  padding-left: 15px;
  padding-right: 15px;

  &_stick-to-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__items-per-page {
    flex-grow: 1;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #5b5b5b;
    font-size: $font-size-base;
    font-weight: normal;
  }
}
