.root {
  position: relative;
}

.button {
  display: block;
  cursor: pointer;
  border-radius: 50%;
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
}

.button:focus {
  outline: none;
}

.icon {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border-style: none;
}

.menu {
  position: absolute;
  z-index: 100;
  background-color: #3a3e4f;
  color: #fff;
  top: calc(100% + 10px);
  left: -6px;
}

.menu:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 100%;
  left: 10px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #3a3e4f;
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.listItem {
  display: flex;
  align-items: center;
  padding: 7px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.listItem:hover {
  background-color: #5b5f73;
}

.label {
  margin-left: 15px;
  white-space: nowrap;
}
