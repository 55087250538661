/*
* Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
* 600 North Bridge Road, Parkview Square #15-10, Singapore
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* License: see file “LICENSE.txt”
*/
@import '../_mixins';
@import '../_settings';
@import '../../globals';

.page.__reporting.__list {
  .site-visitors_page-cnt {
    height: calc(100vh - 210px);
  }
  .admin-section_header_tabs-block_tab {
    width: 33%;
  }
  .admin-section_header_tabs-block {
    padding-right: 7%;
  }
}

.page.__reporting.__create {
  .analytics-tf {
    padding: 0;
  }
  .analytics-tf_block {
    padding-top: 0;
    padding-bottom: 0;

    &.__timeframe {
      .dropdown_value {
        display: inline-block;
        margin-top: 6px;
      }
    }
  }
  .admin-section_header_tabs-block {
    padding-right: 10%;
  }
  .admin-section_header_tabs-block_tab {
    width: 50% !important;
  }

  @media screen and (max-width: 1500px) {
    .reporting {
      &__input-name {
        width: 200px;
      }
      &__select-campaign {
        width: 200px;
      }
      &__select-type {
        width: 100px;
      }
    }
  }
}

.page.__reporting.__view,
.page.__reporting.__create {
  &.__monitoring {
    .analytics-tf_block.__days,
    .analytics-tf_block.__timeframe {
      display: none;
    }
  }
}

.page.__reporting.__view {
  .admin-section {
    font-size: 14px;
  }
  .analytics_page-cnt {
    height: calc(100vh - 150px);
  }
  .admin-section_header_tabs-block {
    padding-right: 7%;
  }
  .admin-section_header_tabs-block_tab {
    width: 50% !important;
  }
  .pagination_dropdown .btn,
  .site-visitors_sort-btn,
  .site-visitors_filter-i {
    pointer-events: none;
  }
  .analytics-tf {
    padding: 0;
  }
  .analytics-tf_block {
    margin-bottom: 10px;

    .settings-form_form-control[disabled='disabled'],
    .form-control[disabled='disabled'] {
      background-color: #f3f2f7;
    }
  }
}

.site-visitors_table {
  &.__reporting-list {
    @include table-list-cells((5%, 30%, 15%, 15%, 15%, 10%, 10%));
    td {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        text-align: center;
      }
      &:nth-child(2) {
        text-align: left;
      }
      &:nth-child(5) {
        padding: 12px 30px;
      }
    }
  }
}

.reporting-copy-btn {
  padding: 2px 5px;
  border-radius: 4px;
  background-color: #7f7fc7;
  color: #fff;
  margin-right: 5px;
  cursor: pointer;

  &:focus,
  &:hover,
  &:active {
    color: #fff;
  }
}

.reporting-link-btn {
  color: #383838;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.reporting_page-cnt {
  position: relative;
  height: calc(100vh - 160px);
  padding: 10px 20px;
  background-color: #d7dae3;
  overflow-x: hidden !important;
}

.reporting-block {
  position: relative;
  margin-bottom: 10px;

  .analytics-manage-block {
    background: #f9f7fa;
    margin: 0;
    width: calc(50% - 10px);
  }

  label {
    font-weight: normal;
    margin-bottom: 0;
    span {
      padding-left: 20px;
    }
  }
}

.reporting-block_h {
  background-color: #f2f1f6;
  padding: 8px 20px;
}

.reporting-block_title {
  color: #a2a0b7;
  margin-right: 5px;
  font-size: 12px;
  margin-top: 2px;
}

.reporting-block_body-w {
  padding: 20px;
  position: relative;
  background-color: #fff;

  &.__list {
    padding-left: 40px;
  }

  &.__period {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.reporting-block_list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  column-fill: auto;
  height: 147px;

  &.__visitors {
    column-count: 2;
  }

  &.__general,
  &.__campaigns {
    column-count: 3;
  }

  &.__sites,
  &.__campaign-spec,
  &.__site-spec {
    column-count: 4;
  }
}

.reporting-block_list_item {
  *::first-letter {
    text-transform: uppercase;
  }
  &:nth-child(5n) {
    label {
      margin-bottom: 0;
    }
  }
  label {
    margin-bottom: 12px !important;
  }
  label.for-checkbox,
  label.contain-checkbox span {
    padding-left: 20px;
  }
}

.reporting-create_footer,
.reporting-flex {
  display: flex;
  flex-wrap: wrap;
}

.reporting-create_footer {
  .btn {
  }
}

.reporting-label {
  font-size: 11px;
  margin-right: 12px;

  &.__item-title {
    font-size: 18px;
    font-weight: 700;
  }
}

.reporting-flex_column {
  margin: auto;

  &.__a-right {
    margin-right: 20px;
  }

  &.__a-left {
    margin-left: 20px;
  }

  &.__a-left-hard {
    margin: 0;
  }

  &.__one-line {
    white-space: nowrap;
  }

  &.__union {
    border: 1px solid #e0e3e9;
    padding: 4px 15px;
  }

  &.__time-filters,
  &.__search-filter {
    margin-top: 10px;
    margin-bottom: 10px;
    @media screen and (max-width: 1620px) {
      margin-left: 0;
    }
  }

  &.__search-filter {
    @media screen and (max-width: 1620px) {
      padding-left: 10px;
    }
  }

  &.analytics-tf.__one-line {
    @media screen and (max-width: 1850px) {
      margin-left: 0;
      .analytics-tf_block {
        margin-right: 0px;
      }
    }
    @media screen and (max-width: 1620px) {
      margin-left: 0;
      .analytics-tf_block {
        margin-right: 0px;
      }
    }
  }
}

.flex-input {
}

.reporting {
  &__input-name {
    width: 250px;
  }
  &__select-campaign {
    width: 250px;
    .dropdown_menu {
      max-height: 200px;
    }
  }
  &__select-site {
    width: 250px;
    .dropdown_menu {
      max-height: 200px;
    }
  }
  &__select-type {
    width: 180px;
  }
}

.reporting-view-header {
  height: 50px;
  background-color: #f3f4f5;
}

.reporting-filters {
  background-color: #eae9f0;
  padding: 10px 10px 0px 20px;
  margin-bottom: 10px;
}

.dropdown_caret.__direction {
  position: absolute;
  right: -15px;
  top: 8px;

  &.__asc {
    transform: rotate(180deg);
  }
}

.is-edit {
  .settings-form_form-control:not([disabled='disabled']) {
    border: 1px solid $admin-edit-color;

    &.analytics-tf_period-it-w:not(.ng-invalid),
    &.analytics-tf_timeframe-it:not(.ng-invalid),
    &.analytics-tf_days-it:not(.ng-invalid) {
      border: 1px solid $admin-edit-color !important;
    }
  }

  .settings-form_dropdown_btn {
    border-color: $admin-edit-color;
  }

  .settings-form_dropdown_caret {
    border-top-color: $admin-edit-color;
  }

  .form-control_group {
    border-color: $admin-edit-color;

    &.__name {
      border-color: inherit;
    }
  }
}

.reporting-no-yet {
  position: relative;
  height: 190px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reporting-no-column {
  display: none;
  visibility: hidden !important;
  width: 0 !important;
  border: 0 !important;
}

.reporting-search-bar {
  input.search-bar_it {
    border-radius: 0;
    background: #fff;
    color: inherit;
  }
}

.report-invalid {
  color: #f95760;
  font-weight: 600;
}

/// HARDLINKS
.page.__reporting.__hardlink {
  .analytics_page-cnt {
    height: calc(100vh - 50px) !important;
  }
}

.reporting_nothing-to-show-cnt {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  font-size: 120%;
  margin-top: 150px;
}

//table sorting
.site-visitors_sort-b {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.site-visitors_sort-btn {
  display: block;
  height: 7px;
  width: 10px;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 11px;
  }

  &.__up {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 7px solid #ced7de;
    &.__is-active {
      border-bottom: 7px solid $blue-thin-lines;
    }
  }

  &.__down {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #ced7de;
    &.__is-active {
      border-top: 7px solid $blue-thin-lines;
    }
  }
}

//device icons

.site-visitors_device-icn {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
}

.site-visitors_device-icn.__smartphone {
  background-image: url('../../../images/analytics-icons/site-visit-smart.png');
  width: 11px;
  height: 18px;
}

.site-visitors_device-icn.__console {
  background-image: url('../../../images/analytics-icons/site-visit-console.png');
  width: 21px;
  height: 12px;
}

.site-visitors_device-icn.__tablet {
  background-image: url('../../../images/analytics-icons/site-visit-tabl.png');
  width: 16px;
  height: 18px;
}

//social-media icons

.site-visitors_soc-btn {
  border: none;
  text-indent: 99999px;
  display: inline-block;
  background-color: #2d80c2;
  background-image: url('../../../images/analytics-icons/social-small.png');
  background-repeat: no-repeat;
  background-size: 18px;
  border-radius: 50%;
  height: 21px;
  width: 21px;

  &[disabled] {
    opacity: 0.5;
  }
}

.site-visitors_soc-btn.__vk {
  background-position: 2px 2px;
}

.site-visitors_soc-btn.__fb {
  margin-left: 5px;
  background-position: 2px -16px;
}

//monitoring collection name
.collection-name {
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  background: #b1b3c4;
  color: #646675;
  font-size: 14px;
  text-transform: uppercase;
}

.pagination_msg-b {
  display: block;
  text-align: center;
  position: relative;
  height: 0;
  bottom: 30px;
}
