@import '../globals';

$sidebar-width: 200px;
$sidebar-width_tablet: 50px;

.widget-card {
  position: relative;
  margin: 0 12px 24px 12px;
  width: calc(25% - 24px);
  //padding-top: calc(((25% - 24px) * 1.4) + 40px + 125px);
  height: calc(
    ((((100vw - #{$sidebar-width}) / 4) - 24px) * 1.4) + 40px + 125px
  );
  max-height: 767px;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  @include tablet {
    height: calc(
      ((((100vw - #{$sidebar-width_tablet}) / 4) - 24px) * 1.4) + 40px + 125px
    );
  }

  &_popup {
    height: calc((((1100px / 4) - 24px) * 1.4) + 125px);
  }

  &_analytics {
    width: 250px;
    height: 440px;
  }

  &__inner {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    height: 40px;
    background: #464852;

    &_archive {
      background: #595346;
    }
  }

  &__head-buttons-holder {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding-right: 10px;
  }

  &__body {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    background: #e5e5ed;
    overflow: hidden;
    box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.05);
    background-size: cover;
    background-position: center;

    &_stripes {
      background: url('../../images/widgets/icon-bg-stripe.png');
    }
  }

  &__placeholder-cnt {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__placeholder-center {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &__placeholder-image-cnt {
    width: 100%;
    height: calc(100% - 40px);
    padding: 15px;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__placeholder-image {
    max-width: 60%;
    max-height: 90%;
  }

  &__placeholder-label {
    display: block;
    min-width: 100px;
    max-width: 60%;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    text-align: center;
    background: #bdbdc1;
    color: #ffffff;
    border-radius: 4px;
    @extend %h-truncate;
  }

  &__background {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__foreground {
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__bottom {
    height: 134px;
    background: $white;
    padding: 15px;
    &_empty {
      background: $blue;
    }
  }

  &__widget-name {
    @extend %h-truncate;
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 600;
    margin: 0 0 10px 0;
  }
}
