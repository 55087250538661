@import '../globals';

.campaigns-list-tooltip,
.prognosis-tooltip,
.link-widget_link-btn-tooltip,
.camp-prev_popover {
  position: fixed;
  z-index: 900;
  padding: 10px;
  background: #646675;
  color: $white;
  max-width: 350px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #646675;
  }

  &__inner {
    max-width: 100%;
  }
}

.campaigns-list-tooltip,
.prognosis-tooltip {
  margin-top: 10px;
}

.link-widget_link-btn-tooltip {
  position: absolute;
  margin-top: 10px;
}

.prognosis-tooltip {
  max-width: 200px;
}

.camp-prev_popover {
  margin-top: 10px;
  width: 225px;

  .camp-prev_popover-bg {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
  }

  .camp-prev_popover-bg-placeholder {
    background-color: #e5e5ed !important;
    background-image: none !important;
  }

  .camp-prev_popover-fg {
    max-width: 100%;
    max-height: 100%;
  }

  .camp-prev_popover-ph {
    max-width: 80%;
  }
}
