%h-textfield-skin {
  display: inline-block;
  border: 1px solid $blue-thin-lines;
  border-radius: 4px;
  color: $font-color-prime;
  font-size: $font-size-base;
  height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  &:focus {
    outline: none;
    box-shadow: inset 0 0 6px 0 $blue-field-focus;
  }
}

%h-placeholder-skin {
  &::placeholder {
    color: $grey-placeholder;
    font-weight: 300;
  }
  &:focus::placeholder {
    color: transparent;
  }
}

%h-scrollbar-skin {
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e7e6eb;
    border-radius: 3px;
  }
}

%h-textview-skin {
  display: inline-block;
  border: 1px solid $grey-thin-lines;
  border-radius: 4px;
  color: $font-color-prime;
  font-size: $font-size-base;
  background-color: $white;
  padding: 0 10px;
  max-width: 300px;
  min-width: 26px;
  height: 26px;
  line-height: 26px;
}

%h-textview-special {
  position: relative;
  padding-left: 0;
  &:before {
    content: '';
    display: inline-block;
    padding: 0 8px;
    color: #9ea3bc;
  }
}

%h-textview-filled {
  background-color: #f2f1f6;
  border-color: #f2f1f6;
}

%h-icon-skin {
  font-family: FontAwesome;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin h-checkbox-skin {
  position: relative;
  font-size: $font-size-base;
  line-height: 1.6;
  font-weight: normal;
  text-transform: none;
  color: $font-color-prime;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:not(:last-child) {
    margin-right: 15px;
  }
  &__control {
    position: absolute;
    left: -9999px;
  }
  &__label {
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 14px;
      height: 14px;
    }
    &:before {
      border: 1px solid $blue-thin-lines;
      border-radius: 3px;
      background-color: $white;
    }
    &.disabled-site {
      opacity: 0.5;
    }
  }
}
