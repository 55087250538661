@import '../globals';

.widgets-list-cnt {
  &__inner {
    min-height: 100%;
    position: relative;
    padding-bottom: 50px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1800px;
    margin: 0 auto -24px;
    padding: 20px 12px;

    &_empty {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: none;
      margin: 0;
      padding: 0 0 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
    }
  }

  &__noitems {
    height: calc(100vh - 160px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
}
