.container {
  display: flex;
  align-items: center;
  width: 100%;
}

.item {
  display: block;
  height: 27px;
  line-height: 27px;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  margin-right: 15px;
}

.item a {
  border-bottom: 1px solid #adadb1;
  padding-bottom: 1px;
  cursor: pointer;
}

.sectionItem {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.itemName {
  display: block;
  padding: 0 10px;
  border-radius: 4px;
  background-color: #747680;
}

.arrow:after {
  display: block;
  content: '\F105';
  color: #9ea2b4;
  margin-right: 15px;
  font: normal normal normal 1.8rem FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
