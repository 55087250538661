@import '../globals';

.user-tooltip {
  position: absolute;
  z-index: 900;
  padding: 10px;
  background: #646675;
  color: $white;
  width: 300px;
  white-space: normal;
  margin-top: 10px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #646675;
  }

  &__inner {
    max-width: 100%;
  }
}
