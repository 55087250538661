@import '../globals';

.textinput {
  @extend %h-textfield-skin;
  @extend %h-placeholder-skin;
  @extend %h-truncate;
  width: 300px;
  &:not(:last-child) {
    margin-right: 15px;
  }

  &_short {
    width: 120px;
  }

  &_number {
    width: 35px;
    text-align: center;
  }

  &_number-lg {
    width: 70px;
    text-align: center;
  }

  &_additional-info {
    width: 200px;
  }

  &_responsive {
    width: 100%;
  }

  &_centered {
    text-align: center;
  }

  &:disabled,
  &.disabled {
    background-color: #e9e9f0;
  }

  &_colorpicker {
    width: 26px;
    cursor: pointer;
    text-indent: -1000px;
    &:focus {
      box-shadow: none;
    }
  }

  &_period {
    width: 80px;
    text-align: center;
    border: none;
    padding: 0 5px;
    margin: 0 !important;
    box-shadow: none !important;

    &:not(:disabled) {
      cursor: pointer;
    }
    &:disabled {
      cursor: default;
    }
  }

  &_phone {
    padding-left: 24px;
    background: url(../../images/icon-phone.png) no-repeat 7px center;
    background-size: 11px auto;
  }

  &_link {
    padding-left: 24px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAMAAACecocUAAAAWlBMVEUAAABHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfywC+DLAAAAHXRSTlMAAQIEBQsODxwkNz9KX4iUoqOmt7m8vsjM0/Hz/ce3WKwAAABMSURBVAhbPcxJAkAwFATRMsRMSIjx3/+aJEJt+q0apW1IK5glZsFPDazyes/ARg/8nhLa6CWll/H0PnK6cPT4qhqJdt+/g9JsIVNwAzcfCNpnsP6YAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 7px center;
  }

  &_user {
    padding-left: 24px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAMAAACecocUAAAAXVBMVEUAAABHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfyXFzDsAAAAHnRSTlMAAQQGBw0jLzk7PUBHSU90eJqeo6jBxcfI3Ojv9fvyIq48AAAASUlEQVQIHQXBhQHCAADAsCDD3RnQ/88kAdMBwOrXYwZMf9UJGKqugGe1Bhbb4+WwmcOy7uex9wS3qmqBT1W1x1hV7bD+VvWa+QNS0Aa0n8oHmQAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-position: 7px center;
  }

  &_position {
    padding-left: 24px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAMAAACecocUAAAAM1BMVEUAAABHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfwpEpoGAAAAEHRSTlMADCAkJSZNUWxtlZeYmqOqfVhKTQAAADxJREFUCB1NwYkNgDAMA0AnAUJ5Wu8/LZaqKtxB9szcMHUz65CXyw0WsCDEw0NA8eEUUM6jUcCCh8uFnw8zvATlJrn2AgAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-position: 7px center;
  }

  &_email {
    padding-left: 24px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAMAAACecocUAAAARVBMVEUAAABHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxHsfxqpT2/AAAAFnRSTlMAARcYGSw1NldYW1xoa4WRo6qtusXMO5w9awAAAEFJREFUCB0FwQECgQAAALEVoZAu6f9PtQGAd1VVLz8AX+cIGE/HdgHX7dCw3nBfh8TzMcwLCdNnQnYAu6WqqhkA/sX4AvstipJdAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: 7px center;
  }

  &_fake-field {
    display: none;
    position: absolute;
    left: 100%;
  }
}

//validation

.submitted {
  .textinput_error,
  .textinput.ng-invalid {
    border: 1px solid $red;
    &:focus {
      box-shadow: inset 0 0 6px 0 lighten($red, 10%);
    }
  }
}

//hide arrows

.textinput[type='number']::-webkit-outer-spin-button,
.textinput[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.textinput[type='number'] {
  -moz-appearance: textfield;
}
