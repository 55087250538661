// Table cells in list

@mixin table-list-cells($settings) {
  @for $i from 1 through length($settings) {
    $value: nth($settings, $i);
    td:nth-child(#{$i}),
    th:nth-child(#{$i}) {
      width: $value;
      @if (unit($value) == '%') and ($value > 20%) {
        padding: 0 3%;
      }
    }
  }
}
