@import '../globals';

.user-sidebar {
  display: flex;
  align-items: center;
  width: 200px;
  background-color: #f2f1f6;
  border-right: 1px solid #eeedf2;

  &_extended {
    width: 350px;
    align-items: flex-start;
  }

  &__inner {
    flex-grow: 1;
    text-align: center;
  }

  &__image-holder {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border: 1px solid #b3b9c7;
    background-image: linear-gradient(
      45deg,
      #fff 25%,
      #c9c9c9 25%,
      #c9c9c9 50%,
      #fff 50%,
      #fff 75%,
      #c9c9c9 75%,
      #c9c9c9
    );
    background-size: 6px 6px;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &_edit {
      border-color: $blue;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__notes {
    color: #888888;
    font-weight: 300;
    line-height: 1.45;
  }
}

.visitor-images {
  padding: 30px 25px;
  .visitor-images-slider {
    margin: 0 auto;
    width: 300px;
    height: 300px;
    .img-count {
      margin: 5px 0;
      text-align: center;
      color: #9594a4;
    }
    .alpha-image-container {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background-image: url('../../images/widgets/icon-bg-stripe.png');
      .alpha-image {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .img-thumbs {
      margin: 10px 0 0;
      padding: 0;
      list-style-type: none;
      &.__1 {
        width: 130px;
        margin: 10px 95px;
      }
      &.__2 {
        width: 230px;
        margin: 10px 50px;
      }

      &:after {
        display: block;
        content: '';
        clear: both;
      }
      li {
        float: left;
        &:nth-child(2) {
          margin-left: 0 !important;
        }
        &.img-thumb {
          width: 80px;
          height: 80px;
          float: left;
          margin-left: 10px;
          overflow: hidden;
          box-sizing: border-box;
          background-image: url('../../images/widgets/icon-bg-stripe.png');

          div {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.__active {
            border: 2px solid $violet;
          }
        }
      }
      .prev-slide,
      .next-slide {
        width: 0;
        height: 0;
        margin-top: 30px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        cursor: pointer;
      }
      .prev-slide {
        margin-right: 10px;
        border-right: 10px solid $violet;
        &.__disabled {
          border-right: 10px solid #c3bae0;
          cursor: default;
        }
      }
      .next-slide {
        margin-left: 10px;
        border-left: 10px solid $violet;
        &.__disabled {
          border-left: 10px solid #c3bae0;
          cursor: default;
        }
      }
    }
  }
}
