@import '../globals';

.map-window {
  align-self: stretch;
  background-color: #f2f1f6;
  min-height: 580px;
  display: flex;
  align-items: stretch;

  &__map-cnt {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__map {
    height: 100%;
    width: 100%;
  }

  &__notifications {
    display: flex;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
  }
}

.map-window_full-size {
  .map-window__map {
    height: calc(100vh - 200px);
  }
}

.map-window-campaigns {
  height: 450px;
}
