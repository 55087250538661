@import '../globals';

//should be applied to table tag

//this block makes use of tags selectors because of strict structure of table content

.list-table {
  position: relative;
  width: 100%;
  table-layout: fixed;
  background: $white;
  border-spacing: 0;
  font-size: $font-size-base;
  color: #5b5b5b;

  tr:nth-child(even) {
    background-color: #f2f3f7;
  }

  tr.list-table__row-highlighted,
  tr.list-table__row-highlighted:nth-child(even) {
    background-color: #f2f1f6;
  }

  tr.list-table__sub-header,
  tr.list-table__sub-header:nth-child(even) {
    background-color: #f5f6fa;

    td {
      padding: 0 25px;
    }
  }

  th,
  td {
    vertical-align: middle;
    border-right: 1px solid rgba(195, 195, 195, 0.15);
  }

  th:first-child,
  td:first-child {
    border-left: 1px solid rgba(195, 195, 195, 0.15);
  }

  th {
    position: relative;
    background-color: #545662;
    color: $white;
    height: 50px;
    text-align: center;
    padding: 0 15px;
    font-size: 1.1rem;
    font-weight: 300;
    text-transform: uppercase;

    &.__sorting {
      padding-left: 5px;
      padding-right: 20px;
    }
  }

  td {
    @extend %h-truncate;
    height: 44px;
    padding: 0 10px;
  }

  &__item-state {
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;

    &_lg {
      height: 12px;
      width: 12px;
    }

    &.__always-running,
    &.__running,
    &.__green {
      background-color: #62bb45;
    }
    &.__scheduled {
      background-color: #57b6fa;
    }
    &.__stopped,
    &.__red {
      background-color: #ff0004;
    }
    &.__finished {
      background-color: #bababc;
    }
    &.__draft,
    &.__yellow {
      background-color: #fbca15;
    }
  }

  .list-table__noitems {
    position: relative;
    height: 88px;
    text-align: center;
    background: $white;
  }
}

//no stripes

.list-table_plain-white tr:nth-child(even) {
  background-color: inherit;
}

.list-table_plain-white td {
  border-bottom: 1px solid #e5e5e5;
}

.list-table_plain-white tr:first-child td {
  border-top: 1px solid #e5e5e5;
}

//special cases

.list-table_admin-vendor-settings {
  max-width: 550px;
  margin: 0 auto;
}

.list-table_admin-localization-settings {
  max-width: 800px;
  margin: 0 auto;
}
