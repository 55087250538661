@import '../globals';

.dropdown {
  position: relative;
  font-size: $font-size-base;
  width: 300px;
  &:not(:last-child) {
    margin-right: 15px;
  }

  &_no-margin {
    margin-right: 0;
  }

  &_short {
    width: 120px;
  }

  &_md {
    width: 190px;
  }

  &_micro {
    width: 60px;
  }

  &__toggle {
    @extend %h-button-reset;
    @extend %h-textfield-skin;
    position: relative;
    width: 100%;
    line-height: 26px;
    text-align: left;
    padding-right: 25px;
    cursor: pointer;
    background-color: $white;
    &_focus {
      box-shadow: inset 0 0 6px 0 $blue-field-focus;
    }

    &:disabled {
      background-color: #e9e9f0;
      pointer-events: none !important;
    }
  }
  &__caret {
    @extend %h-fa;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -9px;
    font-size: 18px;
    color: $blue-thin-lines;
    &:after {
      content: '\f107';
    }
  }

  &__input-cnt {
    height: 100%;
  }

  &__input-field {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    cursor: inherit;
    background-color: transparent;
    @extend %h-placeholder-skin;
    @extend %h-truncate;
    &:focus {
      outline: none;
      cursor: text;
    }
  }
  &__placeholder {
    color: $grey-placeholder;
    font-weight: 300;
  }
  &__menu {
    @extend %h-list-reset;
    @extend %h-scrollbar-skin;

    position: absolute;
    top: calc(100% + 5px);
    width: 100%;
    z-index: 100;
    border: 1px solid $blue-thin-lines;
    border-radius: 4px;
    background: $white;
    margin-top: 5px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.33);
    max-height: 385px;
    overflow: auto;

    &.ps {
      overflow: hidden;
    }

    &_collapsible {
      border: none;
      @extend %h-scrollbar-skin;
      //box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.33), inset 0 0 0 1px $blue-thin-lines;
    }
  }
  &__menu-list {
    @extend %h-list-reset;
  }
  &__menu-item {
    font-size: 12px;
    color: $font-color-prime;
    padding: 5px 10px;
    cursor: pointer;
    @extend %h-truncate;
    &:hover,
    &_active,
    &_active {
      background: #e6f4ff;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }
  &__menu-subheader {
    font-size: 12px;
    color: $font-color-prime;
    font-weight: 600;
    padding: 5px 15px 5px 10px;
    background: #e9ecf5;
    text-align: right;
    text-transform: uppercase;
  }
}

// show/hide/modify elements
.dropdown .dropdown__menu {
  left: -9999px;
  bottom: 0;
}

.dropdown_open .dropdown__menu,
.dropdown .dropdown__menu_open {
  left: 0;
  bottom: auto;
}

//validation
.submitted {
  .dropdown_error,
  .dropdown.ng-invalid {
    .dropdown__toggle {
      border-color: $red;
      &:focus,
      &_focus {
        box-shadow: inset 0 0 6px 0 lighten($red, 10%);
      }
    }
    .dropdown__caret {
      color: $red;
    }
  }
}

//view mode dropdown
.dropdown_view-mode {
  width: auto;

  .dropdown__caret {
    display: none;
  }

  .dropdown__toggle {
    border-color: $grey-thin-lines;
    padding-right: 10px;
    background: $white;
  }
}

//special dropdowns
.dropdown_items-counter {
  width: 45px;
}

.dropdown_items-counter .dropdown__toggle {
  border: none;
  background: #c7c7d3;
  color: $white;
}

.dropdown_items-counter .dropdown__caret {
  color: $white;
}

//filters dropdowns

.dropdown_filter {
  width: auto;
  .dropdown__toggle {
    border: none;
    background-color: $white;
    height: 100%;
    line-height: 30px;
    border-radius: 0;
    max-width: 200px;
    @extend %h-truncate;
  }
  .dropdown__toggle:focus {
    box-shadow: none;
  }
  .dropdown__menu {
    width: auto;
    min-width: 100%;
    max-width: 250px;
    border: none;
    border-radius: 0;
  }
  .dropdown__menu-item {
    padding: 8px 10px;
  }
}

.dropdown_period {
  width: 50px;
  margin: 0 !important;
  .dropdown__toggle {
    border-color: transparent;
    padding: 0 5px;
  }
  .dropdown__caret {
    display: none;
  }
  .dropdown__menu {
    width: 60px;
  }
}

//widget section dropdown

.dropdown_widget {
  width: auto;
  .dropdown__toggle {
    border: none;
    background-color: #2e313c;
    height: 25px;
    color: #b6b8c2;
    @extend %h-truncate;
  }
  .dropdown__toggle:focus {
    box-shadow: none;
  }
  .dropdown__toggle:disabled {
    background-color: #e9e9f0;
  }
  .dropdown__caret {
    color: inherit;
  }
  .dropdown__menu {
    width: auto;
    background-color: #2e313c;
    min-width: 100%;
    max-width: 250px;
    border: none;
    border-radius: 4px;
  }
  .dropdown__menu-item {
    color: #b6b8c2;
    &:hover {
      background-color: #5b5f73;
    }
  }
}

.dropdown_widget-secondary {
  width: 110px;
  .dropdown__toggle {
    border: none;
    background-color: #454851;
    height: 28px;
    color: #b6b8c2;
    @extend %h-truncate;
  }
  .dropdown__toggle:focus {
    box-shadow: none;
  }
  .dropdown__caret {
    color: inherit;
  }
  .dropdown__menu {
    width: auto;
    background-color: #454851;
    min-width: 100%;
    max-width: 250px;
    border: none;
    border-radius: 4px;
  }
  .dropdown__menu-item {
    color: #b6b8c2;
    &:hover {
      background-color: #5b5f73;
    }
  }
}

.dropdown_opacity {
  .dropdown__toggle {
    display: flex;
    align-items: center;
    color: #9698b1;
  }
  .dropdown__input-field {
    width: 25px;
    color: #9698b1;
  }
  .imageicon.__transparent {
    position: absolute;
    top: 50%;
    right: 26px;
    transform: translateY(-50%);
    display: block;
    width: 14px;
    height: 14px;
    border: 1px solid #ffffff;
    span {
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 1px;
      right: 1px;
    }
  }
  .dropdown__menu {
    overflow: visible;
    padding: 7px 11px;
    box-sizing: content-box;
    margin: 4px -11px 0;
    width: 100%;
  }
}

//searchbar dropdown

.dropdown_search {
  width: 100%;
  max-width: 300px;
  .dropdown__toggle {
    border: none;
    background-color: $white;
    height: 30px;
    line-height: 30px;
    border-radius: 0;
    @extend %h-truncate;
  }
  .dropdown__menu {
    width: 100%;
    border: none;
    border-radius: 0;
  }
  .dropdown__menu-item {
    padding: 8px 10px;
  }

  .dropdown__search-submit {
    @extend %h-button-reset;
    display: block;
    width: 16px;
    height: 16px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAllBMVEUAAABbtvZXtflXt/pXtvpXtvpYtfpXtvoA//9WtvpXtvpXtvpatfdXtvpXtvlXtflXt/pXtvpXtfpduf9VuPpVqv9Yt/lXtfpXtvtXtvpRvP9Zt/lXtvtAv/9XtvpYtvtYt/pYtflWt/tduf9XtvlmzP9YtflXtvpZtftVv/9XtvpWuPtXtvpWtPtXtvpXtvpXtvoAAADLPg58AAAAMHRSTlMAHIq+79qmXgFi8Lwf/LaHm9zeCzYGhpWq/hMuRgT7d25aRxbnBYP3SAz4RPZB25AootXOAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAAN1wAADdcBQiibeAAAAAd0SU1FB+EGHQw2ByKd5dcAAACdSURBVBjTVY5HEoNADASXjAET1uQMJjnP/19nsezBnkvXtFQqMcaYomq6YVo2kzk5EHE92YGzH4QRwC97VxzwmJikQLYLFef4WM1RlAQNvrxVATVBRyBF06IjGAilKIGeYCJKDjEAI8ECUtGvE/hMtF0gr5pymIBFTDxOX7bi13UT5pYVe+PLivsm79ddP86sJjOy3zye4H+Cvd6fL3xcEXK4HX11AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA2LTI5VDEyOjU0OjA3KzAyOjAwYXpahQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wNi0yOVQxMjo1NDowNyswMjowMBAn4jkAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    position: absolute;
    top: 7px;
    right: 5px;
    cursor: pointer;
  }
}
.dropdown_search .dropdown__toggle {
  @extend %h-placeholder-skin;
}

.dropdown_search .dropdown__toggle:focus {
  &::placeholder {
    color: transparent;
  }
}

//pagination dropdown

.dropdown_pagination {
  width: 47px;
  .dropdown__toggle {
    border: none;
    border-radius: 0;
    background-color: darken(#c7c7d3, 10%);
    color: $white;
    padding-left: 7px;
    padding-right: 18px;

    &:disabled {
      background-color: #c7c7d3;
    }
  }

  .dropdown__toggle:focus {
    box-shadow: none;
  }

  .dropdown__caret {
    color: $white;
  }

  .dropdown__menu {
    bottom: 100%;
    top: auto;
    margin: 0 0 10px;
    border: none;
    border-radius: 0;
    background-color: darken(#c7c7d3, 10%);
  }

  .dropdown__menu-item {
    color: $white;
    padding-left: 7px;
    &:hover {
      background-color: darken(#c7c7d3, 15%);
    }
    &.__disabled {
      color: darken(white, 20%);
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.dropdown_open.dropdown_pagination {
  .dropdown__caret:after {
    content: '\f106';
  }
}
