@import '../globals';

.cols-grid {
  min-height: 60px;
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;

  &__col {
    width: 20%;
    padding: 5px 20px 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__checkbox {
    position: relative;
    flex-grow: 1;
    padding-right: 40px;
  }
}

.cols-grid_horizontal {
  height: auto;
  min-height: 100px;
  flex-direction: row;
  align-content: flex-start;
}

//fix for analytics Comparison block
.analytics-manage-block.manage-block__alternate .cols-grid__col {
  width: auto;
}
