@import '../globals';

.lang-switcher {
  position: relative;
  &__icon {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
  &__toggle {
    @extend %h-button-reset;
    display: block;
    cursor: pointer;
    border-radius: 50%;
  }
  &__menu {
    position: absolute;
    left: -9999px;
    z-index: 100;
    background-color: #3a3e4f;
    color: $white;
  }
  &__menu:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 10px;
    width: 0px;
    height: 0px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #3a3e4f;
  }
  &__menu-list {
    @extend %h-list-reset;
  }
  &__menu-item {
    display: flex;
    align-items: center;
    padding: 7px;
    cursor: pointer;
    transition: background 0.3s ease;
    &:hover {
      background-color: #5b5f73;
    }
  }
  &__label {
    margin-left: 15px;
    white-space: nowrap;
  }
}

//toggle menu

.lang-switcher.dropdown_open .lang-switcher__menu {
  top: calc(100% + 10px);
  left: -6px;
}
