@import '../globals';

.top-version-note {
  display: block;
  height: $top-panel-height;
  line-height: $top-panel-height;
  background-color: $logo-block-background;
  color: #afb5bd;
  text-align: center;
  @include tablet {
    display: none;
  }
}
