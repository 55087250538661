@import '../globals';

$local-top-panel-height: 50px;
$local-tabs-panel-height: 55px;
$local-filters-panel-height: 55px;

.content-container {
  position: relative;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &_root {
    overflow: visible;
  }

  &_widgets-list {
    min-height: calc(
      100vh - #{$local-top-panel-height} - #{$local-tabs-panel-height} - #{$local-filters-panel-height}
    );
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    &_scroll {
      display: block;
      overflow: auto;
      @extend %h-scrollbar-skin;
    }
  }

  &__scroll {
    position: relative;
    flex-grow: 1;
    flex-basis: 0;
    overflow: auto;
    @extend %h-scrollbar-skin;
  }

  &__body {
    position: relative;
    flex-grow: 1;
    flex-basis: 0;
  }
  &__aside {
    width: 345px;
    margin-left: 15px;
    @include desktop-md {
      width: 300px;
    }
  }
}
