//fix floats
.clearfix:before,
.clearfix:after {
  content: ' '; /* to fix some weird opera bug whatever.. */
  display: table; /* to prevent collapsing of margins */
}

.clearfix:after {
  clear: both;
}

//hide blocks
.hidden {
  display: none;
}

//text alignment
.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.t-left {
  text-align: left;
}

//text overflow ellipsis
.t-ellipsis {
  @extend %h-truncate;
}

//text-color
.t-green {
  color: $green !important;
}

.t-green-alternate {
  color: #069c47;
}

.t-red {
  color: $red !important;
}

.t-blue {
  color: $blue-thin-lines;
}

.t-violet {
  color: $violet;
}

.t-grey {
  color: #9197b3;
}

//text size

.t-small {
  font-size: 75%;
}

.t-large {
  font-size: 133%;
}

.t-xlarge {
  font-size: 150%;
}

//font weight

.t-bold {
  font-weight: 700;
}

.t-semibold {
  font-weight: 600;
}

.t-light {
  font-weight: 300;
}

//text transforms
.t-uppercase {
  text-transform: uppercase;
}

.t-capitalize {
  text-transform: capitalize;
}

//text decoration
.t-underline {
  text-decoration: underline;
}

//backgrounds
.bg-light-grey {
  background: #f2f1f6;
}

.bg-white {
  background: $white;
}

//containers

.container-border {
  border: 1px solid #ededed;
}

.container-p10 {
  padding: 10px;
}

.container-p15 {
  padding: 15px;
  @include desktop-sm {
    padding: 15px 10px;
  }
}

.container-p20 {
  padding: 20px;
  @include desktop-sm {
    padding: 20px 10px;
  }
}

.container-p30 {
  padding: 30px;
  @include desktop-sm {
    padding: 30px 15px;
  }
}

.container-p35 {
  padding: 35px;
  @include desktop-sm {
    padding: 35px 15px;
  }
}

.container-p40 {
  padding: 40px;
  @include desktop-sm {
    padding: 40px 15px;
  }
}

//remove padding
.container-p0-top {
  padding-top: 0;
}

.container-p0-bottom {
  padding-bottom: 0;
}

//sections
.section-m5 {
  margin-bottom: 5px !important;
}

.section-m10 {
  margin-bottom: 10px;
}

.section-m15 {
  margin-bottom: 15px;
}

//align items in row
.align-center-v {
  display: flex;
  align-items: center;
}

.align-center-h {
  display: flex;
  justify-content: center;
}

//Properties for the Flex Children
.align-top {
  align-self: flex-start;
}

.align-bottom {
  align-self: flex-end;
}

.align-middle {
  align-self: center;
}

.grow {
  flex-grow: 1;
}

//hide rails on perfect scrollbar block
.ps-hide-rails {
  .ps__scrollbar-x-rail,
  .ps__scrollbar-y-rail {
    display: none !important;
  }
}

//hide hack block that is needed for export pdf
.show-on-print {
  display: none;
}
@media print {
  .show-on-print {
    display: block;
  }
}

//add padding for containers with fixed filters panel
.contains-fixed-filters {
  margin-top: 70px;
}

//floating blocks
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
