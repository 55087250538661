@import '../globals';

.form-row {
  display: flex;
  align-items: center;
  &:not(:last-child),
  &.fix-margin {
    margin-bottom: 14px;
  }

  &_align-top {
    align-items: flex-start;
  }

  &__label {
    width: 225px;
    min-width: 225px;
    padding-right: 15px;
    text-align: right;
    font-size: 12px;
    color: $font-color-base;
    @include desktop-md {
      width: 160px;
      min-width: 160px;
    }

    &_align-top {
      align-self: flex-start;
    }

    &_align-top-adjust {
      align-self: flex-start;
      padding-top: 5px;
    }

    &_align-top-checkboxes {
      align-self: flex-start;
      padding-top: 3px;
    }

    &_align-left {
      text-align: left;
    }

    &_short {
      width: 165px;
      min-width: 0;
    }
  }
  &__controls {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 26px;
    max-width: 100%;
    &:not(:last-child) {
      padding-right: 15px;
    }
    &_as-block {
      display: block;
    }
    &_nowrap {
      flex-wrap: nowrap;
    }
  }
  &__right-side-group {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__controls-blocks-cnt {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
  &__controls-block {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  &__image {
    min-width: 115px;
    padding-right: 15px;
    display: flex;
  }
  &__image-view {
    border: 1px solid #9fa0a6;
    max-height: 60px;
  }
  &__image-item {
    border: 1px solid #9fa0a6;
    max-height: 130px;
    max-width: 230px;
    background: linear-gradient(
      45deg,
      #fff 25%,
      #c9c9c9 25%,
      #c9c9c9 50%,
      #fff 50%,
      #fff 75%,
      #c9c9c9 75%,
      #c9c9c9
    );
    background-size: 6px 6px;
    width: 100%;

    &_edit {
      border-color: $blue-thin-lines;
    }

    &_error {
      border-color: $red;
    }

    &_favicon {
      width: 32px;
      height: 32px;
    }

    &_system-logo {
      width: 100px;
      height: 100px;
    }

    &_background {
      width: 206px;
      height: 130px;
      width: 100%;
    }

    &_foreground {
      width: 152px;
      height: 130px;
    }

    &_client-img {
      width: 60px;
      height: 60px;
    }
  }
  &__notes {
    color: #9e9e9e;
    font-weight: 300;
    flex-shrink: 0;
    &:not(:last-child) {
      padding-right: 15px;
    }
  }

  &__del-btn {
    @extend %h-button-reset;
    @extend %h-icon-skin;
    color: $blue-thin-lines;
    font-size: 13px;
    cursor: pointer;
  }
}

.form-row-cnt {
  position: relative;
  padding: 20px 0;
  border: 1px solid $blue-thin-lines;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &_del-btn {
    @extend %h-button-reset;
    @extend %h-icon-skin;
    color: $blue-thin-lines;
    font-size: 13px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 8px;
  }
}
