@import '../globals';

.top-panel {
  height: $top-panel-height;
  min-height: $top-panel-height;
  background-color: $menu-bars-background;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $white;

  &__breadcrumbs {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-right: 50px;
  }

  &__bc-item-cnt {
    display: flex;
    align-items: center;
  }

  &__bc-item {
    display: block;
    height: 27px;
    line-height: 27px;
    font-size: 1.2rem;
    font-weight: normal;
    color: $white;
    margin-right: 15px;
    &_main {
      font-size: 1.1rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    &_current {
      display: block;
      padding: 0 10px;
      border-radius: 4px;
      background-color: #747680;
    }

    a {
      border-bottom: 1px solid #adadb1;
      padding-bottom: 1px;
      cursor: pointer;
    }
  }

  &__bc-arrow:after {
    display: block;
    content: '\f105';
    @extend %h-fa;
    font-size: 1.8rem;
    color: #9ea2b4;
    margin-right: 15px;
  }

  &__usermenu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__userpic {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 20px;
    background-position: center center;
  }

  &__username {
    color: $white;
    font-size: 1.1rem;
    letter-spacing: 0.05rem;
    margin-right: 20px;
  }

  &__user-role {
    color: $white;
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 0.05rem;
    padding: 6px 8px;
    border: 1px solid #c2c5d2;
    border-radius: 4px;
    margin-right: 20px;
  }

  &__lang-switcher {
    margin-right: 20px;
    margin-left: 10px;
  }

  &__btns-list {
    display: flex;
  }

  &__btn {
    display: block;
    width: 50px;
    height: 50px;
    transition: all 0.3s ease;
    background-repeat: no-repeat;
    background-position: center center;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: $dark-blue;
    }

    &_settings {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6N0Y0MkVEMzVDNUI5MTFFNEI4OURBRDcyMEVGRTE4MkMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6N0Y0MkVEMzZDNUI5MTFFNEI4OURBRDcyMEVGRTE4MkMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3RjQyRUQzM0M1QjkxMUU0Qjg5REFENzIwRUZFMTgyQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3RjQyRUQzNEM1QjkxMUU0Qjg5REFENzIwRUZFMTgyQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoYHk/oAAAF0SURBVHjapJI/KIVRGMZ932CQUndQEq5BuXdi4RrYZFAoGRTJhk3dxSCTQRbLLWWx4GYw6JY/IUQKd7BcxaCMBjbLpeP31vvV67vfvSWnnt73POd5nnM653jOuar/DM8GFAqPjZRTcJZMJuYMv0Wph+uvGKDiAcoh2McwzDxDH4RNw22WBCCKsfBuQoJUOdEIyBjPGtr5cMAb/QR4AtugR9c7ED+w3ka/CCaVX4eflcZX4gAcgRdjdkGP+JlyZ04xQ+jCrzuA2KWMhS8Js8daH+0F+AYbEgCKrFWHX6GTUgvkyMtgCNSAc5X0YrpCl6MflHCv0j9AmKLc6LQFw6vyq5S0BPhljHUga8xx0AWX1vmtPnXkP4hR7kGrUvJ5PsG1zsfZORvo/Qhz3pjziE+ozUa2g26lJACyXXeOB2bzjLLjqPLyCg79ZfgEU2ZnCerGWDTPuUdZ0ukHSIT/QQMlpx8oheGrzAUfi5n1pshL/Ov4EWAAMcCnmlXHMRMAAAAASUVORK5CYII=);
    }

    &_pass {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Qjc5RkU1RDJDNUI5MTFFNEI4OURBRDcyMEVGRTE4MkMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Qjc5RkU1RDNDNUI5MTFFNEI4OURBRDcyMEVGRTE4MkMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNzlGRTVEMEM1QjkxMUU0Qjg5REFENzIwRUZFMTgyQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNzlGRTVEMUM1QjkxMUU0Qjg5REFENzIwRUZFMTgyQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkdKnxQAAAEbSURBVHjaYvz//z8DJYAFRNy9exfG5wFiZyBWBOLXQHwMiO/j0qysrAwxAAqMgHg5EKshib0C4nwgXoHLECYoLQXEu4D4FxBbAnESEH8CYjGoofaEDAgFYmEgDgHiE0A8H4gXIqkrI2SALpSWR5KTQmKr4g1EIHgOpRcB8SoglgNif7SwwOuCtUAMik9xIM5F0wxzWT4+Ay4AcSae6JYB4k5oWGE1AARmArE1EM8G4v1AvBiInyLJs0O91wrEjOhhAAPHoBgGIoHYAYjTkMSqgNgAiNOB+AkTgZS6HKrQA4hnIYl7AfFObC7ABUCKXwKxNxALAjEXEGuhhwEhcB2aStdjSwfEgJ9A/BiINwOxEBC/AQkyUpqdAQIMAASMM+n+wVURAAAAAElFTkSuQmCC);
    }

    &_logout {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Qjc5RkU1RDZDNUI5MTFFNEI4OURBRDcyMEVGRTE4MkMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Qjc5RkU1RDdDNUI5MTFFNEI4OURBRDcyMEVGRTE4MkMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNzlGRTVENEM1QjkxMUU0Qjg5REFENzIwRUZFMTgyQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNzlGRTVENUM1QjkxMUU0Qjg5REFENzIwRUZFMTgyQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pqu2+MwAAAE/SURBVHjapJNNK0RhFMefe2fyEllosrCh7oaNWLAkWwvfYLaKWJqkhIXEnrL1DbCxkI3llJcVm7uRFQ02IhPX79H/1ul2x0w59euczvOcf+c8L0GSJC6OY5djBbiEThiBj+yGKIpc0TW2LhV6K8FD3qYwJ+eLuuHT5N6gDUabCezDDRQzLb9AAldw2EhgF+YVv2bWOqAOX1CGg6zAGCwrnpRvNwI98uPwDXMwZQXW5LfgQrEfoQLr8KScH2FFsc+7QNf4rjYH4N79bb1Q851wjYXQzOjt0TW3Zzt+aK7JW38LAn3ydStwJL/YgsCC/Jk9gyHiWy3MwkmD4mk4VzzBGVTTDu5gVfExbPunbgoHdepp8Q5UbQfpxooWU6vpBZZMbhM20s8U5PzGYViCGV2r00c6hT24tr/xV+A/9iPAAKOwVhB+2lqpAAAAAElFTkSuQmCC);
    }
  }
}
