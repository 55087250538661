@import '../globals';

.widget-icon {
  display: block;
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: $white;
  border: 2px solid $blue;
  color: $blue;
  &:not(:last-child) {
    margin-right: 10px;
  }

  &:after {
    @extend %h-fa;
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &_static:after {
    content: '\f030';
  }

  &_slider:after {
    content: '\f141';
    font-size: 14px;
    margin-top: 1px;
  }

  &_video:after {
    content: '\f03d';
  }

  &_custom:after {
    content: '\f1b2';
  }

  &_poll:after {
    content: '\f03a';
  }

  &_approved:after {
    content: '\f00c';
  }

  &_pending:after {
    content: '\f252';
  }

  &_pinned:after {
    content: '\f08d';
  }

  &_linked:after {
    content: '\f0c1';
  }

  &_not-linked:after {
    content: '\f127';
  }

  &_green {
    color: $green;
    border-color: $green;
  }

  &_red {
    color: $red;
    border-color: $red;
  }

  &_black {
    color: $black;
    border-color: $black;
  }

  &_violet {
    color: $violet;
    border-color: $violet;
  }

  &_yellow {
    color: $orange;
    border-color: $orange;
  }
}
