$tablet: 1365px;
$desktop-sm: 1605px;
$desktop-md: 1805px;

$min-height: 755px;

@mixin tablet {
  @media screen and (max-width: $tablet) {
    @content;
  }
}

@mixin desktop-sm {
  @media screen and (max-width: $desktop-sm) {
    @content;
  }
}

@mixin desktop-md {
  @media screen and (max-width: $desktop-md) {
    @content;
  }
}

@mixin min-height {
  @media screen and (max-height: $min-height) {
    @content;
  }
}
