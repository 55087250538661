/*Fontawesome*/
@font-face {
  font-family: 'FontAwesome';
  src: url('../../fonts/fontawesome/fonts/fontawesome-webfont.eot?v=4.7.0');
  src: url('../../fonts/fontawesome/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0')
      format('embedded-opentype'),
    url('../../fonts/fontawesome/fonts/fontawesome-webfont.woff2?v=4.7.0')
      format('woff2'),
    url('../../fonts/fontawesome/fonts/fontawesome-webfont.woff?v=4.7.0')
      format('woff'),
    url('../../fonts/fontawesome/fonts/fontawesome-webfont.ttf?v=4.7.0')
      format('truetype'),
    url('../../fonts/fontawesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
/*END Fontawesome*/

/* BEGIN Light */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/Light/OpenSans-Light.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/Light/OpenSans-Light.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/Light/OpenSans-Light.woff2?v=1.1.0')
      format('woff2'),
    url('../../fonts/OpenSans/Light/OpenSans-Light.woff?v=1.1.0') format('woff'),
    url('../../fonts/OpenSans/Light/OpenSans-Light.ttf?v=1.1.0')
      format('truetype'),
    url('../../fonts/OpenSans/Light/OpenSans-Light.svg?v=1.1.0#Light')
      format('svg');
  font-weight: 300;
  font-style: normal;
}
/* END Light */
/* BEGIN Light Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/LightItalic/OpenSans-LightItalic.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/LightItalic/OpenSans-LightItalic.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/LightItalic/OpenSans-LightItalic.woff2?v=1.1.0')
      format('woff2'),
    url('../../fonts/OpenSans/LightItalic/OpenSans-LightItalic.woff?v=1.1.0')
      format('woff'),
    url('../../fonts/OpenSans/LightItalic/OpenSans-LightItalic.ttf?v=1.1.0')
      format('truetype'),
    url('../../fonts/OpenSans/LightItalic/OpenSans-LightItalic.svg?v=1.1.0#LightItalic')
      format('svg');
  font-weight: 300;
  font-style: italic;
}
/* END Light Italic */
/* BEGIN Regular */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/Regular/OpenSans-Regular.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/Regular/OpenSans-Regular.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/Regular/OpenSans-Regular.woff2?v=1.1.0')
      format('woff2'),
    url('../../fonts/OpenSans/Regular/OpenSans-Regular.woff?v=1.1.0')
      format('woff'),
    url('../../fonts/OpenSans/Regular/OpenSans-Regular.ttf?v=1.1.0')
      format('truetype'),
    url('../../fonts/OpenSans/Regular/OpenSans-Regular.svg?v=1.1.0#Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
/* END Regular */
/* BEGIN Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/Italic/OpenSans-Italic.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/Italic/OpenSans-Italic.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/Italic/OpenSans-Italic.woff2?v=1.1.0')
      format('woff2'),
    url('../../fonts/OpenSans/Italic/OpenSans-Italic.woff?v=1.1.0')
      format('woff'),
    url('../../fonts/OpenSans/Italic/OpenSans-Italic.ttf?v=1.1.0')
      format('truetype'),
    url('../../fonts/OpenSans/Italic/OpenSans-Italic.svg?v=1.1.0#Italic')
      format('svg');
  font-weight: normal;
  font-style: italic;
}
/* END Italic */
/* BEGIN Semibold */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/Semibold/OpenSans-Semibold.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/Semibold/OpenSans-Semibold.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/Semibold/OpenSans-Semibold.woff2?v=1.1.0')
      format('woff2'),
    url('../../fonts/OpenSans/Semibold/OpenSans-Semibold.woff?v=1.1.0')
      format('woff'),
    url('../../fonts/OpenSans/Semibold/OpenSans-Semibold.ttf?v=1.1.0')
      format('truetype'),
    url('../../fonts/OpenSans/Semibold/OpenSans-Semibold.svg?v=1.1.0#Semibold')
      format('svg');
  font-weight: 600;
  font-style: normal;
}
/* END Semibold */
/* BEGIN Semibold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/SemiboldItalic/OpenSans-SemiboldItalic.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/SemiboldItalic/OpenSans-SemiboldItalic.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/SemiboldItalic/OpenSans-SemiboldItalic.woff2?v=1.1.0')
      format('woff2'),
    url('../../fonts/OpenSans/SemiboldItalic/OpenSans-SemiboldItalic.woff?v=1.1.0')
      format('woff'),
    url('../../fonts/OpenSans/SemiboldItalic/OpenSans-SemiboldItalic.ttf?v=1.1.0')
      format('truetype'),
    url('../../fonts/OpenSans/SemiboldItalic/OpenSans-SemiboldItalic.svg?v=1.1.0#SemiboldItalic')
      format('svg');
  font-weight: 600;
  font-style: italic;
}
/* END Semibold Italic */
/* BEGIN Bold */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/Bold/OpenSans-Bold.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/Bold/OpenSans-Bold.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/Bold/OpenSans-Bold.woff2?v=1.1.0') format('woff2'),
    url('../../fonts/OpenSans/Bold/OpenSans-Bold.woff?v=1.1.0') format('woff'),
    url('../../fonts/OpenSans/Bold/OpenSans-Bold.ttf?v=1.1.0')
      format('truetype'),
    url('../../fonts/OpenSans/Bold/OpenSans-Bold.svg?v=1.1.0#Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
}
/* END Bold */
/* BEGIN Bold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/BoldItalic/OpenSans-BoldItalic.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/BoldItalic/OpenSans-BoldItalic.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/BoldItalic/OpenSans-BoldItalic.woff2?v=1.1.0')
      format('woff2'),
    url('../../fonts/OpenSans/BoldItalic/OpenSans-BoldItalic.woff?v=1.1.0')
      format('woff'),
    url('../../fonts/OpenSans/BoldItalic/OpenSans-BoldItalic.ttf?v=1.1.0')
      format('truetype'),
    url('../../fonts/OpenSans/BoldItalic/OpenSans-BoldItalic.svg?v=1.1.0#BoldItalic')
      format('svg');
  font-weight: bold;
  font-style: italic;
}
/* END Bold Italic */
/* BEGIN Extrabold */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/ExtraBold/OpenSans-ExtraBold.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/ExtraBold/OpenSans-ExtraBold.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/ExtraBold/OpenSans-ExtraBold.woff2?v=1.1.0')
      format('woff2'),
    url('../../fonts/OpenSans/ExtraBold/OpenSans-ExtraBold.woff?v=1.1.0')
      format('woff'),
    url('../../fonts/OpenSans/ExtraBold/OpenSans-ExtraBold.ttf?v=1.1.0')
      format('truetype'),
    url('../../fonts/OpenSans/ExtraBold/OpenSans-ExtraBold.svg?v=1.1.0#ExtraBold')
      format('svg');
  font-weight: 800;
  font-style: normal;
}
/* END Extrabold */
/* BEGIN Extrabold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot?v=1.1.0');
  src: url('../../fonts/OpenSans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot?#iefix&v=1.1.0')
      format('embedded-opentype'),
    url('../../fonts/OpenSans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff2?v=1.1.0')
      format('woff2'),
    url('../../fonts/OpenSans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff?v=1.1.0')
      format('woff'),
    url('../../fonts/OpenSans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.ttf?v=1.1.0')
      format('truetype'),
    url('../../fonts/OpenSans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.svg?v=1.1.0#ExtraBoldItalic')
      format('svg');
  font-weight: 800;
  font-style: italic;
}
