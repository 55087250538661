@import '../globals';

.layout {
  display: flex;
  height: 100%;
  align-items: stretch;
  overflow: hidden;
  &_full-screen {
    align-items: center;
    justify-content: center;
  }

  &__container {
    display: flex;
    height: 100%;
    flex-grow: 1;
  }

  &__login-container {
    display: flex;
    background-color: #1f222e;
    align-self: stretch;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  &__aside {
    position: relative;
    display: flex;
    width: 200px;
    min-width: 200px;
    background: $menu-bars-background;
    overflow: hidden;
    @include tablet {
      width: 40px;
      min-width: 40px;
      padding-right: 200px;
      margin-right: -160px;
      background: none;
      overflow: visible;
      &:before {
        content: '';
        display: block;
        width: 40px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $menu-bars-background;
        z-index: -1;
      }
    }
  }

  &__aside-inner {
    height: 100%;
    width: 200px;
    @include tablet {
      width: 40px;
    }
  }

  &__body {
    flex-grow: 1;
    background: #ced1db;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
  }

  &__body-centered {
    width: 400px;
    min-height: 200px;
    background: #272a38;
  }

  &__content {
    position: relative;
    flex-grow: 1;
    display: flex;
  }

  &__content-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  }
}

//hardlinks layout

body.__hardlink {
  .layout__aside {
    display: none !important;
  }

  .top-panel {
    display: none !important;
  }

  .tabs-panel_main {
    display: none !important;
  }
}
