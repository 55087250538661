@import '../globals';

.contacts-card {
  position: relative;
  background-color: #eeedf3;
  padding: 5px 20px 5px 7px;
  margin-bottom: 12px;
  border-radius: 4px;
  max-width: 300px;
  &:not(:last-child) {
    margin-right: 10px;
  }

  &__icon {
    @extend %h-icon-skin;
    color: #9197b3;
    margin-right: 7px;
  }

  &__del-btn {
    @extend %h-button-reset;
    @extend %h-icon-skin;
    color: $blue-thin-lines;
    font-size: 13px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

//edit mode

.contacts-card_edit {
  background-color: #dbefff;
  padding-right: 22px;
}

.contacts-card_edit .contacts-card__icon {
  color: $blue-thin-lines;
}
