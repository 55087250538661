/// validation contexts
ng-form,
form {
  display: block;
  &.submitted {
    .brick-block.has-focused,
    .brick-block:hover,
    .validation_msg-cnt.has-focused,
    .validation_msg-cnt:hover {
      .validation_msg {
        display: block;
      }
    }

    .form-control.ng-invalid {
      border-color: #fe143e !important;
    }
    [type='checkbox'].ng-invalid + span:before {
      border: 1px solid #fe143e;
    }
  }
  &.settings-form {
    .validation_msg {
      left: 0;
      transform: none;
      margin-top: 15px;
    }
    &.submitted,
    .submitted & {
      .form-control.ng-invalid {
        background-color: rgba(254, 20, 62, 0.15) !important;
      }
      .validation_msg-cnt.has-error {
        .settings-form_image {
          border-color: #fe143e;
        }
        label {
          background-color: #fe143e;
        }
      }
      .settings-form_dropdown.ng-invalid {
        .settings-form_dropdown_btn {
          border-color: #fe143e;
          background-color: rgba(254, 20, 62, 0.15) !important;
        }
      }
    }
  }
}
