@import '../globals';

.radiobutton {
  @include h-checkbox-skin;

  &_adaptive {
    font-size: inherit;
    color: inherit;
  }

  &_two-lines {
    display: flex;
    max-width: calc(50% - 7.5px);
    white-space: initial;
    overflow: visible;
    text-overflow: initial;
    .radiobutton__label:after {
      top: 8px;
      bottom: auto;
    }
    .radiobutton__label:before {
      top: 3px;
      bottom: auto;
    }
  }
}

.radiobutton__label:before {
  border-radius: 50%;
}

.radiobutton__label:after {
  content: '';
  background-color: $blue-thin-lines;
  height: 4px;
  width: 4px;
  border-radius: 2px;
  bottom: 7px;
  left: 5px;
  opacity: 0;
}

.radiobutton__control:checked ~ .radiobutton__label:after {
  opacity: 1;
}

.radiobutton__control:active ~ .radiobutton__label:before {
  box-shadow: inset 0 0 6px 0 $blue-field-focus;
}

.radiobutton__control_error ~ .radiobutton__label:before,
.submitted .radiobutton_error .radiobutton__label:before {
  border-color: $red;
}

.radiobutton__control_error ~ .radiobutton__label:after,
.submitted .radiobutton_error .radiobutton__label:after {
  background-color: $red;
}

.radiobutton__control_error:active ~ .radiobutton__label:before,
.radiobutton_error .radiobutton__control:active ~ .radiobutton__label:before {
  box-shadow: inset 0 0 6px 0 lighten($red, 10%);
}

.radiobutton_disabled {
  cursor: default;

  .radiobutton__label:before {
    border-color: $grey-thin-lines;
    background-color: #e9e9f0;
  }

  .radiobutton__label:after {
    background-color: #686968;
  }
}

.radiobutton__control:disabled:active:not(.radiobutton__control_style-as-active)
  ~ .radiobutton__label:before {
  box-shadow: none;
}

.radiobutton__control:disabled:not(.radiobutton__control_style-as-active)
  ~ .radiobutton__label {
  cursor: default;
  &:before {
    border-color: $grey-thin-lines;
    background-color: #e9e9f0;
  }
  &:after {
    background-color: $grey-thin-lines;
  }
}

.radiobutton_view {
  cursor: default;

  .radiobutton__label:before {
    border-color: $grey-thin-lines;
  }

  .radiobutton__label:after {
    background-color: #686968;
  }
}

.radiobutton_invert {
  .radiobutton__label {
    padding-left: 0;
    padding-right: 22px;
  }

  .radiobutton__label:before {
    left: auto;
    right: 0;
  }

  .radiobutton__label:after {
    left: auto;
    right: 5px;
  }
}
