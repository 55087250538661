/*
* Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
* 600 North Bridge Road, Parkview Square #15-10, Singapore
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* License: see file “LICENSE.txt”
*/

.analytics-presets_buttons-wrapper {
  display: inline-block;
}

.analytics-presets_dropdown {
  min-width: 250px;

  button,
  span {
    font-size: 12px !important;
  }

  .dropdown_menu {
    max-height: 330px;
    z-index: 19;
  }

  .settings-form_dropdown_btn {
    background-color: transparent !important; // TODO: refactor after delete Old Campaigns Analytics
    width: 100% !important;
    text-align: left !important;
  }
}

.analytics_presets-w {
  background-color: #f2f3f5;
  padding: 10px 20px;
}

.analytics-presets_preset-name-w {
  width: 133px;
  display: inline-block;
}

.analytics-presets_preset-name {
  max-width: 122px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
}

.analytics-presets_date {
  display: inline-block;
  vertical-align: middle;
  width: auto;
}

.analytics-presets_buttons-wrapper {
  margin-left: 10px;
  .btn + .btn {
    margin-left: 5px;
  }
}

.analytics-presets_modal .modal_dialog {
  width: 1210px;
}

.analytics-presets-modal_list-cnt {
  border: 1px solid #21a7ee;
  background-color: #fff;
}

.analytics-presets-modal_li {
  display: inline-block;
  background-color: #f5faff;
  margin: 3px 20px;
  overflow: hidden;
  width: 240px;
  white-space: nowrap;
  font-size: 13px;
  padding: 0 5px;
  border-radius: 2px;
  cursor: default;
  &:hover {
    background-color: #e6f4ff;
    .analytics-presets-modal_li_default-btn {
      visibility: visible;
    }
  }
  &.__is-selected {
    background-color: #e6f4ff;
  }
  &.__is-default {
    .analytics-presets-modal_li_name {
      font-weight: bold;
    }
    .analytics-presets-modal_li_default-btn {
      visibility: visible;
      color: #000;
    }
  }
}

.analytics-presets-modal_li_name,
.analytics-presets-modal_li_date {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
}

.analytics-presets-modal_li_name {
  width: 48%;
  padding-right: 1em;
}

.analytics-presets-modal_li_date {
  width: 30%;
}

.analytics-presets-modal_li_default-btn,
.analytics-presets-modal_li_remove-btn {
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  color: #5fb7fa;
  font-weight: bold;
  border: none;
  outline: none;
  background-color: transparent;
}

.analytics-presets-modal_li_remove-btn[disabled] {
  opacity: 0.2;
}
.analytics-presets-modal_li_default-btn {
  visibility: hidden;
}

.analytics-presets-modal_overflow {
  height: 200px;
  position: relative;
}

.analytics-presets-modal_list {
  padding: 10px;
}

.analytics-presets-modal_search-w {
  position: relative;
  input::-webkit-input-placeholder {
    color: #5fb7fa;
  }
  &:after {
    content: '\e003';
    position: absolute;
    color: #5fb7fa;
    right: 6px;
    top: 7px;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }
}

.analytics-presets_save-modal {
  .settings-form.is-edit {
    border: 1px solid #21a7ee !important;
  }
}
