@import '../globals';

.icon {
  @extend %h-icon-skin;
  margin-right: 5px;

  &_lg {
    font-size: 18px;
  }

  &_mobile-phone:before,
  &_mobile:before {
    content: '\f10b';
  }
  &_desktop:before {
    content: '\f108';
  }
  &_tablet:before {
    content: '\f10a';
  }
  &_laptop:before {
    content: '\f109';
  }
  &_apple:before {
    content: '\f179';
  }
  &_android:before {
    content: '\f17b';
  }
  &_windows:before {
    content: '\f17a';
  }
  &_linux:before {
    content: '\f17c';
  }
  &_dribbble:before {
    content: '\f17d';
  }
}

.icon-sorting {
  display: inline-block;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #646267;

  &_asc {
    border-top: none;
    border-bottom: 5px solid #646267;
  }

  &_adjust {
    margin-left: 5px;
    position: relative;
    bottom: 1px;
  }
}

.icon-gender {
  &:after {
    @extend %h-icon-skin;
    font-size: 20px;
  }

  &_lg:after {
    font-size: 25px;
  }

  &_male:after {
    content: '\f183';
    color: #3b92e3;
  }

  &_female:after {
    content: '\f182';
    color: #eb7a96;
  }
}
