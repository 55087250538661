@import '../globals';

$ps-rail-color: #eee;
$ps-bar-default: #aaa;
$ps-bar-hover: #999;

.ps {
  > .ps__scrollbar-x-rail {
    display: block;
    position: absolute;
    opacity: 1;
    background-color: $ps-rail-color;

    > .ps__scrollbar-x {
      display: block;
      position: absolute;
      background: #ceced6;
      border-radius: 4px;
      opacity: 1;
    }
  }

  > .ps__scrollbar-y-rail {
    display: block;
    position: absolute;
    opacity: 1;
    right: 0;
    width: 8px;
    border-radius: 4px;
    background-color: $ps-rail-color;

    > .ps__scrollbar-y {
      width: 7px !important;
      right: 1px;
      display: block;
      position: absolute;
      background: #ceced6;
      border-radius: 4px;
      opacity: 1;
    }
  }

  &:hover {
    > .ps__scrollbar-x-rail,
    > .ps__scrollbar-y-rail {
      opacity: 1;
    }

    > .ps__scrollbar-x-rail:hover {
      > .ps__scrollbar-x {
        opacity: 1;
      }
    }

    > .ps__scrollbar-y-rail:hover {
      opacity: 1;

      > .ps__scrollbar-y {
        opacity: 1;
      }
    }
  }
}

.hidden-scrollbar {
  .ps__scrollbar-x-rail,
  .ps__scrollbar-y-rail,
  .ps__scrollbar-x,
  .ps__scrollbar-y {
    display: none !important;
  }
}

//hide scrollbar on pdf
@media print {
  .ps__scrollbar-x-rail,
  .ps__scrollbar-y-rail,
  .ps__scrollbar-x,
  .ps__scrollbar-y {
    display: none !important;
  }
}
