@import '../globals';

.aside-panel {
  position: relative;
  background: #464852;
  min-height: 550px;

  &__body {
    position: relative;
    padding: 10px 15px;
    color: $white;
  }

  &__header {
    display: flex;
    align-items: center;
    height: 40px;
    background: #585a69;
  }

  &__title {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #cfced6;
    padding: 0 10px;
  }

  &__controls {
    text-align: right;
    padding: 0 5px;
  }

  &__title,
  &__controls {
    flex-grow: 1;
  }

  &__row {
    margin-bottom: 12px;
  }

  &__subtitle {
    color: #cfced6;
    margin: 0 0 12px 0;
    font-weight: normal;
  }

  &__fields-holder {
    padding: 0 10px;
  }

  &__field {
    display: flex;
    border: 1px dotted #8d92c5;
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    &:not(:last-child) {
      margin-right: 10px;
    }

    &_medium {
      height: 35px;
      line-height: 35px;
    }

    &_large {
      height: 43px;
      line-height: 43px;
    }

    &_multi-lines {
      padding: 15px 0;
      height: auto;
      line-height: inherit;
    }

    &_round {
      width: 28px;
      height: 28px;
      line-height: 28px;
      border-radius: 50%;
      flex-grow: 0;
      padding: 0;
      padding-right: 1px;
    }

    &_date {
      flex-grow: 0;
      padding: 0;
      width: 100px;
    }
  }

  &__field-col {
    flex-basis: 0;
    flex-grow: 1;
    align-self: stretch;
    padding: 0 10px;
    height: 50px;
    &:first-child {
      border-right: 1px dotted #8d92c5;
    }
  }

  &__impact-holder {
    min-height: 32px;
    background-color: #31333d;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
  }
}
