@import '../globals';

.lang-icon {
  display: flex;
  align-items: center;

  &__img {
    height: 20px;
    width: auto;
  }

  &__label {
    margin-left: 15px;
  }
}
