@import '../globals';

.error-page {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &_incorrect-browser {
    background: url('../../images/errors/browser.jpg');
  }
}
