@import '../globals';

.filters-panel {
  width: 100%;
  height: 55px;
  min-height: 55px;
  background-color: #e7e6eb;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  &_fixed {
    position: fixed;
    top: 105px;
    left: 200px;
    right: 0;
  }

  &_secondary {
    height: 50px;
    min-height: 50px;
  }

  &_modal {
    background: #f7f7f9;
  }

  &_reduce-height {
    height: 40px;
    min-height: 40px;
  }

  &_fix-margin {
    margin-bottom: 10px;
  }

  &__left-group-holder {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__right-group-holder {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    padding-left: 15px;
  }
}

//.fix-time-filter-lang {
//  @media (min-width: 1200px) and (max-width: 1600px) {
//
//      .filter__label {
//        font-size: 10px;
//        padding-left: 5px;
//        padding-right: 5px;
//      }
//      .dropdown__toggle {
//        font-size: 10px;
//      }
//      .slider-checkbox__label {
//        font-size: 11px;
//      }
//      .slider-checkbox {
//        line-height: 18px;
//      }
//      .fields-divider-10 {
//        margin-right: 0px;
//      }
//      .textinput_period {
//        padding-left: 0px;
//        padding-right: 0px;
//      }
//      .filter:not(:last-child) {
//        margin-right: 10px;
//      }
//
//    .filter_applyButton {
//      min-width: 80px;
//      padding-left: 5px;
//      padding-right: 5px;
//      font-size: 11px;
//    }
//  }
//}
