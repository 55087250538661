@import '../globals';

.line-divider {
  margin-top: 15px;
  margin-bottom: 15px;
  height: 1px;
  background: #eeedf2;

  &_indent-both-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
}
