.container {
  position: relative;
  flex-grow: 1;
  display: flex;
  height: 100vh;
  width: 100vw;
}

.languageSelector {
  position: absolute !important;
  top: 10px;
  right: 10px;
}
