.link.item, .item{
    position: relative;
    display:block;
    padding-left: 50px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    color: #fff;
}
.item:before{
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;

}
.item:hover{
  color:#fff;
}

.item.campaigns:before{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAARCAYAAAACCvahAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAAsTAAALEwEAmpwYAAABp0lEQVQ4EaXTT0hUQRgA8H2yJJGmQnWoCDtYSEgUhQhaBy8F1SnwFAgdpD+Q5/RSnapLBy+BUESX8FB4CkQvERYlEaFEhw0WulRISuGflZ6/b3m2a+jJgd9+M2/mm5n3ZjaX20JJNspN03S/5y00UmQmSZIFcfMi6RQTRCnxo1xL01/iA+o3zNZxhgUe08H2GCjuo4cCr1k/gQeR+JNzNHCfSb4yykV28ITKBBrNLHKVIxSZ4gax4j3+8IhtfGSkvH2Vu7whOj7wlDxt9FLHYWbpo50VDsQ7xUy3iC3HgPqsLZTLvN+DXKGQfYcYd7lGo5n3HOMdJa6zVuID9TFBTBLHNxX1SF6hlqUsRvL/Zzqf9f0Voz/GlyI5tnKSWP0Ee7jD2gRf1Ic5z+fs+XGxEO98kxnig40xTiNNdJLQTdyBC5wlTmdXJO8mzvg2cSHe8o0hBnnOMgPsJI5yyMq5vDsbV7Bb/SWzdNFLJ4f4RAffecEk/VSKCY4SO3jFJVpp4DSx6hzPyFeyqmo6WnjIb6rLtMY11iVu9peMU9hLE0WvNle1xr/qKoaw4Zxlqc7EAAAAAElFTkSuQmCC) no-repeat 50%;
}
.widgets.item:before{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAAsTAAALEwEAmpwYAAABO0lEQVQoFaWSTStFURSGbV3cKVMpUWJGCX+AZEAuBlIykyHKyNzMH/ALblFkpO4fYCgTJWUk5CsDl7A972mtOqdznYm3nt6119ce7B2aTDHGNsJ5KEE1hFC3UrExOAS34LonGC2eokpTFzzCJ6zCCtThBUYKF9CwA9KcNxJPwQdcQ7Pnc06xBm9Q9iJxO2yANOH5nFNcSlpiPMRbYBp+4Byk/tyQJyiW4EhdSD4G7zqgM/XhZRjwmYxT0I26Oa1vO1TxB4tP8eHMsA4kA4zDHlSgF55AuoNd0Is8Q35BeiMN3eC3E8ZBmAFf0JHuz8Q0bYG0DNuQvAa+CNKmvuJfqllhFl/gu37Z+cK807yxsf0guSPGfVyv0gM3oKfsazxlWRpa4RikK3gFDa4VDnqRRi1Yh0s4gUmv/ct/ARnvjCrepQUdAAAAAElFTkSuQmCC) no-repeat 50%;
}
.sites.item:before{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAQCAYAAAAvf+5AAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0ElEQVQoFWNgIBIwwtT9//9fDMh2B2INIL4PxHsYGRkfAGkEACoKBOI3QIwMvgE5OXBVQI4eEP9EUnERyP6DxPcFKwYKbIIKPgHSmiBBIC0DxGeg4tdhCp9DBerAAlACKBYJFf8HpHmZgOJwDyErRGMzgqzZDNVJ0Gp3qEIYhe4ZFJ+fg6lCo18B+ZxwZwA5YWgKYNxquCIQAyjKBMS3YLJQ+hOQFkBRCFWcgqawC0MRVCEbUOFTqOIfQFoSq0Ko4jSowh6cimASQIVCMDYyDQAqWj95RIyiQAAAAABJRU5ErkJggg==) no-repeat 50%;
}
.entities.item:before{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAlklEQVQoFWNgQAL///+3AOI3QHwSCYP4FkjKGFiAAnxAAUsgZgJiEyC+BMTdQAwDpUCGK1CdIJD+B8THGYGca0CGJhATC66DNP0nVjVMHchJJAMWoI5VpOoC2fQbDT8D8udCxUA0iI+iBpufXgMVOQCxMhDfBeIDQCwKxHCATRNcEhcD5DxSQ+8/SNN8EjSCLACppxMAAHD5SYtvSzwTAAAAAElFTkSuQmCC) no-repeat 50%;
}
.analytics.item:before{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAANCAYAAAB2HjRBAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtElEQVQoFWP4//+/EQO5AKj5LbF6gWrtgNgTrh6bZqCYOhBHg2i4QiADyL8NxP9hYkwwBhrtBuQvAWIQjROw4JSBSgAtCgIy9YB4NrpaXDYjqwNprgdiaWRBEBtuM9AGZSC/EYiPgiSIAXDNQMXCQBwN1XSSGM3EOBunORRpZgT69S0jI6MwkGYGWsEPxD+B+A8QcwPxVyAGeY0diD8CMQ8QMwPVvwPSDGDNIAYZ4B4ZehBaABkgP6jhiR+WAAAAAElFTkSuQmCC) no-repeat 50%;
}
.admin.item:before{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAAsTAAALEwEAmpwYAAABR0lEQVQoFY3SvUtCURjH8XukEgchSgkpSCKHoEWEJpGQHCwaoiEo+gfaWhqaI5oFN43mlv6DCBScHFwCMWhIDILehl5A7fr92T1wN33gc8+55zznnsdzNM4Y4bruCWlrqGHLGJOd8K8jIcx7Gi0mH3xzk/Q3kESbvP91dOJIYg+KK8xiHZdQ7CKMHp4Nj2m+co8ZfHltl/YTmjuCqlhGEykcByjpg04dQQRQxiMieMM1lDOHGEKsuXXYsQjV/YcEExpTJU10UcYq5hHEgnKUZKM9HPAeDJbsBG3ePzfsM5jDnZd0qEH6CbzjFfvIIo9FHNiF2r4BG7Zsvf9Ap32Gb3Sg83B0Hz2ozCm8IIMnNKB72/To0KoosPhcp9rnZQc65gsobhjfpl2BrigKfayFEJYwOtjhFH38oqIVKnWcUNn6I+j3qXRnAHC3DRAxff6EAAAAAElFTkSuQmCC) no-repeat 50%;
}
.reports.item:before{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAMAAAAMs7fIAAAAVFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////8wXzyWAAAAG3RSTlMAAQIDBAoLDxATJihJUF50laOrtbe509ze9fva/k8sAAAAWklEQVQYlY3QRxaAIAxF0ViwYMEu+ve/TzEwoEx454TBHSUQZYTSPL2IpL61CGUFLLVOZsDS8HQsEpxuJmBk2a3gNbOxHPCqEpGJqF+Wy+t0O4dXxFERl/MZH+RJDEcnRepwAAAAAElFTkSuQmCC) no-repeat 50%;
}
.monitoring.item:before{
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjElEQVQoFWP8////XgYGBl4g/g/ExAAWoKL7DECNb4lRjawGpIcJWYAU9kjQCApaAWAo7QDS/4gMHJAeBkagJmLjD9ncd6BQXY8sQiR7PSNIIdBSASAFixoOIPseELMDMQi8A2INIP4L4gDBX0ZGxo8Q9zIyfoCIgQ2xArK/QjFIGOR3UaDiayAOxQAAGG0opCBjgcYAAAAASUVORK5CYII=) no-repeat 50%;
}
.subItem{
    display: flex;
    align-items: center;
    height: 32px;
    padding-right: 5px;
    padding-left: 12px;
    font-size: 11px;
    font-weight: 400;
    cursor: pointer;
    color: #cacbe0;
    transition: border-color .3s ease,background-color .3s ease,color .3s ease;
}
.subItem:hover, .subItem.active{
    background-color: #464a59;
    border-right: 5px solid #3596c9;
    color: #fff;
}
.subItem.active{
  font-weight: 600;
}
.item:hover{
    background:#545662;
}
.itemName{
    font-size: 11px;
}
.subMenu{
    display:none;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #2e313c;
    overflow: hidden;
}
.subMenu.opened{
  display: block;
}

@media screen and (max-width: 1365px){
  .link.item, .item{
    padding-left: 40px;
  }
  .subMenu{
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #2e313c;
    overflow: hidden;
  }
  .subMenu.opened{
    position: absolute;
    visibility: hidden;
  }
  .itemWrapper{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .itemWrapper:hover>.subMenu,.itemWrapper:hover>.subMenu.opened, .subMenu:hover{
    margin: 0;
    overflow: auto;
    display: block;
    visibility: visible;
    left:40px;
    width: 200px;
    max-height: 200px;
    z-index: 1;
  }

  .item span{
    visibility: hidden;
  }

  .item span{
    position: absolute;
    left: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0;
    background: #646675;
    width: 200px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;

  }
  .itemWrapper:hover .item span{
   visibility: visible;
    z-index: 1;
 }
}
