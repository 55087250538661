//Typography
$font-size-base: 1.2rem;

$font-family-base: 'Open Sans', 'Arial Narrow', Arial, Helvetica, sans-serif !default;

$font-color-base: #2e2e2e;
$font-color-prime: #000000;
$font-color-light: #4b4d56;

$line-height-base: 1.2;

//Palette
$black: #333333;
$white: #ffffff;
$green: #00ba1c;
$red: #ff0000;
$blue: #42affc;
$lightblue: #dbefff;
$dark-blue: #2d80c2;
$violet: #9384c2;
$yellow: #bfbc04;
$orange: #dea302;
$brown: #826837;

//palette for special needs

$blue-thin-lines: #4fb6ff;
$blue-field-focus: #49b2fc;

$grey-thin-lines: #b3b9c7;
$grey-placeholder: #828282;

//vars for layout styling

$menu-bars-background: #464852;
$logo-block-background: #3a3c45;

$top-panel-height: 50px;
