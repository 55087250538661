/*
* Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
* 600 North Bridge Road, Parkview Square #15-10, Singapore
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* License: see file “LICENSE.txt”
*/
@import '../globals';

.geo-map {
  &.__monitoring {
    height: calc(100vh - 310px);
  }
  &.__sites {
    height: 400px;
    margin: 0 20px 20px;
  }
  &.__campaign-sites {
    height: 350px;
  }
  &.__analytics {
    height: 450px;
  }
  &.__campaign-search-coords {
    height: 450px;
    border: 1px solid $blue-thin-lines;
  }

  a.leaflet-popup-close-button {
    font-size: 11px;
    width: 13px;
    padding-top: 2px;
  }
}

.geo-map_overlay {
  position: relative;
  z-index: 1;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.geo-map_nocoords {
  position: absolute;
  z-index: 1;
  height: 25px;
  top: 20px;
  left: 2.5%;
  width: 95%;
  background: #fff;
  opacity: 0;
  transition: all 0.5s ease 0s;

  &.__visible {
    opacity: 1;
    top: 10px;
  }
}

.geo-map_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.marker_label {
  margin-top: -17px;
  color: $blue;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.marker-popup-title {
  font-size: 12px;
  color: #afa8c7;
  width: 87%;
  display: inline-block;
  vertical-align: middle;

  &.__with-btn {
    width: 74%;
  }
}

.marker-popup-status {
  width: 10%;
  display: inline-block;
  vertical-align: middle;

  .brick-block_status {
    position: relative;
    right: 0;
  }
}

.marker-popup-add-remove {
  cursor: pointer;
  margin-right: 5px;
  padding: 0;
  outline: none;
  border: none;
  display: inline-block;
  background-color: #57b6fa;
  color: #fff;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  transition: 0.2s ease;
  font-size: 15px;

  &.__disabled {
    background-color: #aaa;
  }

  .marker-minus {
    font-size: 16px;
  }

  .marker-plus {
    font-size: 15px;
  }
}

.marker-info-cnt {
  font-size: 11px;
}

.marker-info-title {
  color: #a0a1b6;
  margin-right: 8px;
}

.marker-info-data {
  color: #e0e1e3;
}

.marker-info_copy-btn {
  padding: 2px 4px;
  background-color: $blue;
  color: #fff;
  border: 0;
  border-radius: 3px;
  margin-left: 7px;
  transform: scale(0.9);

  &:hover {
    background-color: #fff;
    color: $blue;
  }

  &:active {
    background-color: $blue;
    color: #fff;
  }
}

.marker-default-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: $blue;

  &.__small {
    opacity: 0.6;
  }

  &.__error {
    background-color: $red;
  }

  &.__confirmed {
    background-color: $green;
  }

  &.__grey {
    background-color: #999;
  }

  &.__load10 {
    background-color: #c6001b;
  }

  &.__load9,
  &.__load8 {
    background-color: $red;
  }

  &.__load7,
  &.__load6 {
    background-color: $orange;
  }

  &.__load5,
  &.__load4 {
    background-color: $yellow;
  }

  &.__load3,
  &.__load2 {
    background-color: #8fcf41;
  }

  &.__load1,
  &.__load0 {
    background-color: $green;
  }

  &.__green {
    background-color: #57d261;
  }

  &.__yellow {
    background-color: #ded84e;
  }

  &.__red {
    background-color: #f35648;
  }

  &.__green {
    background-color: #57d261;
  }

  &.__yellow {
    background-color: #ded84e;
  }

  &.__red {
    background-color: #f35648;
  }

  &.__radius {
    border: none;
  }
}

.map-address-confirm {
  padding: 0 20px;
  font-size: 11px;
  margin-bottom: 17px;
}
.map-address-confirm_form {
  padding: 7px;
  border-radius: 5px;
  border: 1px dashed #21a7ee;
  flex-grow: 1;

  &.__confirm {
    border: 1px dashed #14c926;
    background-color: #dfeee2;
    color: #14c926;
  }
  &.__is-confirm,
  &.__search {
    background-color: #eaf2ff;
    color: #21a7ee;
  }
  &.__error {
    background-color: #f7e4e5;
    color: #f74436;
    border: 1px dashed #f74436;
  }
  &.__is-confirm {
    flex-grow: 1;
    margin-right: 15px;
  }
}

.map-address-checker {
  width: 26px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  display: block;
  position: relative;
  color: #949394;

  &.__search {
    border: 1px solid #949394;
  }

  &.__confirm {
    border: 1px solid #14c926;
    color: #14c926;
  }

  &.__preloader {
    padding: 2px 3px 0px;
  }
}

ng-form.submitted .map-address-checker.__error,
form.submitted .map-address-checker.__error {
  border: 1px solid #f74436;
  color: #f74436;
}

.leaflet-current-area {
  background-color: #fff;
  width: 26px;
  height: 26px;
  margin-bottom: 5px !important;
  padding: 5px 7px;

  &:before {
    position: relative;
    font-size: 16px;
  }
}

.modal-search-by-coords {
  label {
    font-size: 11px;
    font-weight: normal;
  }
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #646675;
  color: #e0e1e3;
  border-radius: 0;

  .marker-popup-title {
    color: #e0e1e3;
  }
}
