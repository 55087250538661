@import '../globals';

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  -webkit-overflow-scrolling: touch;
  table-layout: fixed;
  height: 100%;
  width: 100%;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: none;

  // When fading in the modal, animate it to slide down
  &.fade .modal__window {
    transform: translate3d(0, -75%, 0);
    transition: 0.3s ease-out;
  }
  &.in .modal__window {
    transform: none;
  }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Modal background
.modal-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #000;
  height: 100%;

  // Fade for backdrop
  &.fade {
    opacity: 0;
  }
  &.in {
    opacity: 0.75;
  }
}

.modal {
  &__window {
    width: 500px;
    margin: 0 auto;
    background: white;
  }

  &__header {
    display: flex;
    align-items: center;
    background: #2e313c;
    height: 55px;
  }

  &__body {
    max-height: calc(100vh - 150px);
    @extend %h-scrollbar-skin;
  }

  &__title {
    @extend %h-header-reset;
    color: $white;
    text-transform: uppercase;
    padding-left: 20px;
    font-weight: 600;
    font-size: 1.3rem;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    background: #e1e1eb;
  }

  &__buttons-divider {
    display: block;
    margin: 0;
    background: #cacad3;
  }

  &__msg {
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 600;
  }

  &__container {
    padding: 30px;
  }

  &__text-highlighted {
    //border-bottom: 1px solid;
    font-weight: 800;
  }

  &__icon-cnt {
    margin-bottom: 20px;
    text-align: center;
  }

  &__icon {
    position: relative;
    display: inline-block;
    font-size: 30px;
    font-weight: 800;
    width: 50px;
    height: 50px;
    line-height: 44px;
    text-align: center;
    color: $blue;
    border: 3px solid;
    border-radius: 50%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      border-radius: 50%;
      border: 1px solid;
      opacity: 0.6;
    }
    &:before {
      top: 0;
      left: 0;
      width: 44px;
      height: 44px;
    }
    &:after {
      top: -4px;
      left: -4px;
      width: 52px;
      height: 52px;
    }

    &_info {
      color: $violet;
    }

    &_alert {
      color: $red;
    }
  }
}

.modal_add-widget {
  .modal__window {
    width: 800px;
  }
}

.modal_link-widget {
  .modal__window {
    width: 1100px;
    background: #ced1db;
  }

  .modal__body {
    padding: 0;
    max-height: 680px;
    overflow: auto;
  }
}

.modal_mac-exceptions {
  .modal__window {
    width: 1000px;
    background: #ced1db;
  }

  .modal__body {
    max-height: calc(100vh - 100px);
    overflow: auto;
  }
}

.modal_multilist {
  .modal__window {
    width: 1000px;
  }
  .modal__container {
    padding: 0;
    max-height: calc(100vh - 150px);
    overflow: auto;
  }
}

.modal_geo-location {
  .modal__window {
    width: 1000px;
  }
}

.modal_add-contact,
.modal_add-time-frame,
.modal_export {
  .modal__window {
    width: 600px;
  }
}

.modal_poll-answer {
  .modal__window {
    width: 660px;
  }
  .modal__container {
    padding-top: 10px;
  }
}

.modal_poll-question {
  .modal__container {
    padding-top: 10px;
  }
}

.modal_manage-block {
  .modal__window {
    width: 1000px;
  }
}

.modal_presets {
  .modal__window {
    width: 1130px;
  }
}

.modal_delete-mac {
  .modal__window {
    width: 600px;
  }
}
