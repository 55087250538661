@import '../globals';

.filter {
  height: 30px;
  display: flex;
  align-items: stretch;
  background: #f7f7f9;
  color: #8b8496;
  max-width: 400px;
  min-width: 0;
  flex-shrink: 1;
  &:not(:last-child) {
    margin-right: 15px;
  }

  &_period {
    position: relative;
    max-width: 600px;
  }

  &__label {
    @extend %h-truncate;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 250px;
    flex-shrink: 0;
  }

  &__controls {
    border: none;
    display: flex;
    align-items: center;
    background: #fff;
  }

  &__input {
    @extend %h-placeholder-skin;
    border: none;
    text-align: center;
    text-decoration: underline;
    outline: none;
    height: 100%;
  }

  &__data-value {
    padding: 0 10px;
    text-align: center;
    min-width: 50px;
  }

  &__item-title {
    padding-right: 10px;
    line-height: 30px;
    color: $font-color-base;
    font-size: 16px;
    font-weight: 600;
  }
}

.filters-cnt {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 15px;
  }
  &_hide-timeframe {
    .filter_timeframe {
      display: none;
    }
  }
  &_hide-days {
    .filter_days {
      display: none;
    }
  }
  &_hide-interactionTypes {
    .filter_interactionTypes {
      display: none;
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  }
}

.filter_applyButton {
  flex-shrink: 1;
  height: 30px;
}

.view-reporting-monitoring {
  .filter_timeframe,
  .filter_days {
    display: none;
  }
}

//filters on analytics

.filter_analytics {
  background: #d1cbe5;
  height: 26px;
  color: $font-color-base;
  border-radius: 4px;

  .filter__label {
    line-height: 26px;
  }

  .filter__controls {
    border-radius: 0 4px 4px 0;
  }

  .dropdown__toggle {
    line-height: 26px;
    border-radius: 0 4px 4px 0;
  }
}
