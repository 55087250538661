@import '../globals';

.pagination {
  display: flex;

  &__total-items {
    color: #818182;
  }

  &__btn {
    @extend %h-button-reset;
    min-width: 26px;
    width: auto;
    padding: 0 3px;
    height: 26px;
    line-height: 26px;
    background-color: #d6d6e6;
    color: $font-color-prime;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;

    &_icon-default {
      @extend %h-icon-skin;
      color: #818182;
      background-color: #c7c7d3;
    }

    &_icon-caret {
      @extend %h-icon-skin;
      color: #818182;
      background-color: #c7c7d3;
      font-size: 16px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:hover {
      background-color: $white;
    }

    &_active {
      background-color: $white;
      box-shadow: inset 0 0 0 1px #d6d6e6;
      cursor: auto;
    }

    &_disabled,
    &:disabled {
      opacity: 0.5;
      pointer-events: none !important;
    }
  }
}
