//truncate text
%h-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//initialize fontawesome icon
%h-fa {
  display: inline-block;
  font: normal normal normal 14px FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
