/*
* Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
* 600 North Bridge Road, Parkview Square #15-10, Singapore
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* License: see file “LICENSE.txt”
*/
@import '../globals';

.live-prognosis {
  width: 100%;
  display: block;
  background-color: #474751;
  color: #d1c9e0;
  position: fixed;
}

.live-prognosis_cnt {
  padding: 10px 15px;
  height: 100%;
  .ps-scrollbar-y-rail {
    background-color: #5c5e6b !important;
  }
  .ps-scrollbar-y {
    background-color: #8a8d9e !important;
  }
  .ps-scrollbar-x-rail {
    display: none;
  }
}

.live-prognosis_specificity-cnt {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px dotted #6b6c9c;
  border-radius: 50%;
  padding: 4px;
  font-size: 11px;
  color: #fff;
  width: 33px;
  height: 33px;
  line-height: 2;
  margin-left: 5px;
  margin-top: 5px;
}

.live-prognosis_h-cnt {
  background-color: #535561;
  color: #c6c7dc;
  border: none;
  padding: 0 15px 0 15px;
}

.live-prognosis_refresh-btn {
  outline: none;
  border: none;
  border-radius: 3px;
  display: inline-block;
  background-color: #58b7fb;
  color: #fff;
  padding: 0 10px;
  text-transform: none;
}

.live-prognosis_panel {
  border: 1px dotted #6b6c9c;
  border-radius: 7px 5px;
  padding: 6px;
  margin: 0 0 15px;
  display: inline-block;
  vertical-align: middle;
  &.__full-width {
    width: 100%;
  }
  &.__info-block {
    width: 95%;
  }
  &.__big-padding {
    padding: 12px 10px;
  }
}

.live-prognosis_impact-panel {
  border: none;
  border-radius: 3px;
  background-color: #30333c;
}

.live-prognosis_panel-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.live-prognosis_date {
  font-size: 15px;
  display: inline-block;
}

.live-prognosis_calendar-icon {
  opacity: 0.9;
}

.live-prognosis_mod {
  margin-bottom: 15px;
  clear: both;
}

.live-prognosis_h {
  color: #908ea3;
  font-size: 12px;
  margin: 5px 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.__run-rate {
    text-transform: none;
  }
}

.live-prognosis_run-rate-lbl {
  color: #bfb4de;
  font-size: 130%;
  margin-right: 20px;
}

.live-prognosis_state {
  font-size: 130%;
  margin-right: 10px;
}

.live-prognosis_state-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc;
  margin-bottom: -1px;
  margin-right: 5px;

  &.__finished {
    background-color: #adadad;
  }
  &.__draft {
    background-color: #fbca15;
  }
  &.__running,
  &.__always-running {
    background-color: #5fbd45;
  }
  &.__stopped,
  &.__stoppedreserved {
    background-color: #ff0004;
  }
  &.__scheduled {
    background-color: #00b5fc;
  }
}

.live-prognosis_run-rate-date {
  font-size: 150%;
  &.__good {
    color: #11ac20;
  }
  &.__bad {
    color: #f5220f;
  }
}

.live-prognosis_panel-b {
  display: table-cell;
  width: 50%;
  & + & {
    border-left: 1px dotted #6b6c9c;
  }
}

.live-prognosis_number-cnt {
  margin: 5px 20px;
  &.__big {
    font-size: 22px;
  }
  &.__medium {
    font-size: 18px;
  }
}

.live-prognosis_trend-cnt {
  display: inline-block;
  vertical-align: middle;

  &:before {
    display: inline-block;
    vertical-align: middle;
    content: '';
  }

  &.__down {
    color: #f5220f;
    &:before {
      border-top: 4px dashed;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }
  &.__up {
    color: #11ac20;
    &:before {
      border-bottom: 4px dashed;
      border-bottom: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }
}

.live-prognosis_average-trend-val {
  font-size: 11px;
  margin-left: 2px;
}

.live-prognosis_legend {
  font-size: 11px;
  padding: 0 15px;
}

.live-prognosis_scroll-cnt {
  //height: 100%;
  overflow: hidden;
  position: relative;
}

.live-prognosis_footer-cnt {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 38px;
  background-color: #6c7187;
  display: table;
  width: 100%;
}

.live-prognosis_footer {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.live-prognosis_footer-lnk {
  outline: none;
  border: none;
  display: inline-block;
  background: none;
}

.live-prognosis_footer-lnk-text {
  color: $white;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.live-prognosis_busy-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.71);
  z-index: 10;
}

.live-prognosis_busy-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
}

.live-prognosis_w {
  height: calc(100% - 76px);
  position: relative;
}

.live-prognosis_infinity-i {
  display: inline-block;
  width: 37px;
  height: 13px;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAANCAYAAAAuYadYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAiBJREFUeNqk1V1IFFEYxvHJjOgiTKK8CFIrCCkKsZsKooJICPqiD4QWxLJQkzS6LC+qizIMRYyIIkGkCCSyCwkxSuvCKESIij4gMDIovEoLwer/wLMwLK7tjAd+7M7uzJnnnDnnnez2jo4g1JbhJA5hEX7gkw3iDqaC6G0DqrANC9zvF3xAT3ki0RM+eU4o1Blc8ff76MZ7d7IC67AYw7icYZidaMIaB3mAx/iMhVhrWThLuJFwKF14Gvc8orE0N1HnlcjHdTxKc14O2rHXM1KHrulOJEhAhnK+Hte9OR5WqO0c9OGmb5hJ2+pBvMDFlP82o9+jr0fzTB0plBo5NKuN2KgLb+BnhEBqT7APS6CpnuffK/AMX1H0v0Ap4TTr39GqUCujXBxqWvCn8BonUI1beIrleBejT63lRJYPBoP4TYt+C9pw14/2b8y+RjXrCvUbS2cRSmEOeiEPIW8WfRUHXowPvUuiNt38pR+bFvsBvEIndsUMVaPZUqha14riCBdrC39DCcpwzr/3efsf82Odn2mH7L5LfORqkMk6tcOjbZih9qjtwVUXU237IxiZ5jwN9rwHq516DZNp+pyLFs/SbXZhRbb/6MWE64q2+gDe4hdWoRSHXdFV5fd7p6Rrf1Shsd5FsdPhVdfeeB0XYLd3rnJcIFBD6msm2Y5ik9+Dha7e4w7eiucx1spqv0+LvBbzXeMmvKYbCfQxefI/AQYAl+iI7aeQswAAAAAASUVORK5CYII=);
}
