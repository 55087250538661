@import '../globals';

.manage-block {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 150px);

  &__section {
    flex-grow: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  &__subsection {
    width: 100%;
    flex-grow: 1;
    min-height: 0;
    overflow: auto;

    &_perfect-scrollbar {
      overflow: hidden;
      position: relative;
    }
  }

  &__no-steps {
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
  }

  &__step {
    position: relative;
    width: 20%;
    padding: 25px 10px 10px;
    border-right: 1px solid $blue-thin-lines;
  }

  &__step-controls {
    position: absolute;
    top: 3px;
    right: 5px;
  }

  &__step-del-btn,
  &__step-edit-btn {
    @extend %h-button-reset;
    @extend %h-icon-skin;
    color: #4fb6ff;
    font-size: 13px;
    cursor: pointer;
    padding: 3px;
  }

  &__step-extra {
    padding: 5px;
  }
}
