@import '../globals';

.top-logo {
  background-color: $logo-block-background;
  padding: 10px 10px 30px;
  text-align: center;
  @include tablet {
    padding: 10px;
    height: 105px;
    display: flex;
    align-items: center;
  }

  &__img {
    max-width: 86px;
    margin-bottom: 12px;
    @include tablet {
      max-width: 100%;
    }
  }

  &__label {
    margin: 0;
    color: $white;
    font-size: 2rem;
    line-height: 1.3;
    font-weight: bold;
    text-transform: uppercase;
    @include tablet {
      display: none;
    }
  }
}
