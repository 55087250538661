.container {
  height: 50px;
  min-height: 50px;
  background-color: #464852;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #ffffff;
}

.userMenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttonsContainer {
  display: flex;
}

.btn {
  display: block;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  transition: all 0.3s ease;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-color 0.3s ease;
  text-indent: -5000px;
}

.btn:hover {
  background-color: #2d80c2;
}

.logout {
  background-image: url('./logout.png');
}

.changePassword {
  background-image: url('./changePassword.png');
}

.profile {
  background-image: url('./profile.png');
}

.languageSelectorContainer {
  margin-right: 20px;
  margin-left: 10px;
}

.avatar {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 20px;
  background-position: center center;
  background-size: cover;
}

.userName {
  color: #fff;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  margin-right: 20px;
}

.userRole {
  font-weight: 300;
  padding: 6px 8px;
  border: 1px solid #c2c5d2;
  border-radius: 4px;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  margin-right: 20px;
}

.breadcrumbsContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 50px;
}
