@import '../globals';

@mixin button-background($bg-color) {
  background-color: $bg-color;
  &:hover,
  &:active,
  &:focus {
    background-color: darken($bg-color, 10%);
    color: #fff;
  }
}

.button {
  @extend %h-button-reset;
  @extend %h-truncate;
  display: inline-block;
  font-size: $font-size-base;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  color: $white;
  text-align: center;
  border-radius: 4px;
  padding: 0.75rem 1.4rem 0.7rem;
  min-width: 100px;
  //max-width: 200px;
  max-width: 300px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  flex-shrink: 0;
  @include button-background($blue);
  &:not(:last-child) {
    margin-right: 15px;
  }
  > input[type='file'] {
    display: none;
  }
  &_no-margin:not(:last-child) {
    margin-right: 0;
  }

  &_small {
    min-width: 80px;
    //max-width: 170px;
    font-size: 1.3rem;
    font-weight: 400;
    text-transform: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &_two-lines {
    max-width: calc(50% - 7.5px);
    white-space: initial;
    overflow: visible;
    text-overflow: initial;
  }

  &_micro {
    width: 28px;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    padding: 0;
    font-weight: normal;
    @extend %h-icon-skin;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &_widget {
    width: 26px;
    min-width: 0;
    height: 26px;
    line-height: 26px;
    padding: 0;
    border-radius: 50%;
    font-weight: normal;
    @extend %h-icon-skin;
    font-size: 14px;
    &:not(:last-child) {
      margin-right: 7px;
    }
  }

  &_list {
    position: relative;
    width: 22px;
    height: 22px;
    min-width: 0;
    padding: 0;
    border-radius: 50%;
    font-weight: normal;
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      @extend %h-icon-skin;
      overflow: hidden;
    }
  }

  &_list-action-disabled {
    background-color: #d3d4d7;
    pointer-events: none !important;
    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 4px;
      background: $white;
    }
  }

  &_list-action-stop {
    @include button-background($red);
    &:after {
      width: 11px;
      height: 11px;
      background: url('../../images/list-buttons/icon-stop.png');
    }
  }

  &_list-action-run {
    @include button-background($green);
    &:after {
      width: 11px;
      height: 12px;
      margin-left: 1px;
      background: url('../../images/list-buttons/icon-play.png');
    }
  }

  &_list-action-archive {
    @include button-background($brown);
    &:after {
      width: 12px;
      height: 12px;
      background: url('../../images/list-buttons/icon-archive.png');
    }
  }

  &_list-action-recover {
    @include button-background($green);
    &:after {
      width: 12px;
      height: 12px;
      background: url('../../images/list-buttons/icon-recover.png');
    }
  }

  &_list-action-delete {
    @include button-background($black);
    &:after {
      width: 12px;
      height: 12px;
      background: url('../../images/list-buttons/icon-delete.png');
    }
  }

  &_list-action-notify {
    @include button-background($blue);
    &:after {
      content: '\f0f3';
      font-size: 11px;
      width: 11px;
      height: 11px;
    }
  }

  &_busy {
    .button__spinner {
      display: inline-block;
    }
  }

  &_confirm {
    @include button-background($green);
  }

  &_reject {
    @include button-background($red);
  }

  &_danger {
    @include button-background($black);
  }

  &_block {
    @include button-background($white);
    color: $red;
  }

  &_action-1 {
    @include button-background($violet);
  }

  &_action-2 {
    @include button-background($orange);
  }

  &_action-3 {
    @include button-background($brown);
  }

  &__spinner {
    width: 1em;
    height: 1em;
    position: relative;
    color: white;
    display: none;
    animation: ld-spin 1s infinite;

    &:after {
      position: absolute;
      margin: auto;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: ' ';
      display: inline-block;
      background: center center no-repeat;
      background-size: cover;
      border-radius: 50%;
      -webkit-background-clip: padding-box;
      border: 0.15em solid currentColor;
      border-left-color: transparent;
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none !important;
  }
}

.button_pointer-events-on:disabled {
  pointer-events: auto !important;
  &:hover {
    cursor: default;
  }
}

//custom cases

.button_external-link {
  text-align: left;
  padding-left: 8px;
}

@keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: linear;
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: linear;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
