@import '../globals';

.validation-msg {
  display: none;
  color: $red;

  &_new-line {
    margin-top: 10px;
  }
}

//show validation only on submit
.submitted {
  .validation-msg {
    display: block;
  }
}

//hidden validation messages

.validation-container,
.validation-container-direct {
  position: relative;
}

.validation-msg-hidden {
  display: none;
  position: absolute;
  top: 100%;
  margin-top: 10px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid $red;
  color: $red;
  line-height: 1.5;
  text-transform: none;
  font-size: 1.3rem;
  font-weight: normal;
  background: $white;
  padding: 10px;
  width: auto;
  min-width: 200px;
  z-index: 1000;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    border: 1px solid $red;
    transform: rotate(45deg);
    z-index: 20;
    width: 10px;
    height: 10px;
    top: -6px;
    margin-left: -5px;
    background: #fff;
    border-bottom: 0;
    border-right: 0;
    border-radius: 0;
  }

  &_above {
    top: auto;
    margin-top: 0;
    bottom: 100%;
    margin-bottom: 10px;
    &:before {
      top: auto;
      bottom: -6px;
      border-bottom: 1px solid $red;
      border-right: 1px solid $red;
      border-top: 0;
      border-left: 0;
    }
  }

  &_right {
    top: 50%;
    left: 100%;
    transform: translateX(0) translateY(-50%);
    margin-top: 0;
    margin-left: 10px;
    &:before {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      left: -6px;
      margin-left: 0;
      border-bottom: 1px solid $red;
      border-left: 1px solid $red;
      border-top: 0;
      border-right: 0;
    }
  }

  &_inner {
    top: 20px;
    left: 50%;
    margin-top: 0;
    &:before {
      top: auto;
      bottom: -6px;
      border-bottom: 1px solid $red;
      border-right: 1px solid $red;
      border-top: 0;
      border-left: 0;
    }
  }
}

.submitted {
  .validation-container:hover
    .validation-msg-hidden:not(.ng-inactive):not(.is-inactive) {
    display: block;
  }
}

.submitted {
  .validation-container-direct:hover
    > .validation-msg-hidden:not(.ng-inactive):not(.is-inactive) {
    display: block;
  }
}

.submitted {
  .validation-container.is-invalid,
  .validation-container .is-invalid {
    border: 1px solid $red;
  }
}

//show validation before the form submit
.validation-container:hover {
  .validation-msg-hidden.validation-msg-hidden_always-show:not(.ng-inactive) {
    display: block !important;
  }
}

.validation-container:hover
  [date-picker-wrapper]:hover
  ~ .validation-msg-hidden.validation-msg-hidden_always-show:not(.ng-inactive) {
  display: none !important;
}

.submitted .dropdown_open ~ .validation-msg-hidden {
  display: none !important;
}
