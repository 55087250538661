/*
* Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
* 600 North Bridge Road, Parkview Square #15-10, Singapore
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* License: see file “LICENSE.txt”
*/

.timeline {
}

.timeline_full-width {
  width: 100%;
}

.timeline_wrapper-t,
.timeline_wrapper-t table {
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    padding: 0;
  }
}

.timeline_wrapper-t {
  width: 100%;
  position: relative;
  background-color: #fff;
  &.__detailedView {
    .timeline_viewport {
      margin-top: 60px;
    }
  }
}

.timeline_range-cell {
  background-color: #f5f5f8;
  border: 1px solid #dcdce3;
  text-align: center;
  height: 30px;
  font-weight: normal;
  font-size: 11px;
  color: #49494f;
}

.timeline_li {
  height: 15px;
  position: relative;
  margin-bottom: 5px;
  &.__running .timeline_li-bar {
    background: linear-gradient(
      to right,
      rgba(4, 188, 64, 0.67),
      rgba(4, 154, 52, 0.82)
    );
    border-color: rgba(4, 154, 52, 1);
    .timeline_campaign-type {
      background-color: rgb(4, 130, 46);
    }
  }
  &.__stopped .timeline_li-bar {
    background: linear-gradient(
      to right,
      rgba(255, 23, 28, 0.78),
      rgba(220, 23, 28, 0.85)
    );
    border-color: rgba(220, 23, 28, 1);
    .timeline_campaign-type {
      background-color: rgb(172, 18, 22);
    }
  }
  &.__scheduled .timeline_li-bar {
    background: linear-gradient(
      to right,
      rgba(1, 161, 249, 0.75),
      rgba(1, 143, 225, 0.83)
    );
    border-color: rgba(1, 143, 225, 1);
    .timeline_campaign-type {
      background-color: rgb(1, 122, 196);
    }
  }
  &.__finished .timeline_li-bar {
    background: linear-gradient(
      to right,
      rgba(137, 135, 135, 0.78),
      rgba(85, 83, 83, 0.91)
    );
    border-color: rgba(85, 83, 83, 1);
    .timeline_campaign-type {
      background-color: rgb(63, 61, 61);
    }
  }
  &.__default .timeline_li-bar {
    background: linear-gradient(
      to right,
      rgba(185, 147, 196, 0.8),
      rgba(185, 147, 196, 0.93)
    );
    border-color: rgba(185, 147, 196, 0.93);
    .timeline_campaign-type {
      background-color: rgb(157, 124, 168);
    }
  }
}

.timeline_li-bar {
  position: absolute;
  background-color: blue;
  height: 100%;
  border-radius: 10px;
  font-size: 8px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #080808;
}

.timeline_campaign-type {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: white;
  height: 15px;
  width: 15px;
  display: inline-block;
  text-align: center;
}

.timeline_height-helper {
  height: 350px;
}

.timeline_viewport {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 30px;
  padding: 10px 0;
}
