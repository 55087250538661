@import '../globals';

.tos-container {
  position: relative;
  width: 100%;
  max-width: 497px;
  margin: 10px auto;
  border: 1px solid #c5c7d0;
  min-height: 300px;
  overflow: auto;

  &_localization-terms {
    height: calc(100vh - 210px);
  }

  &_edit {
    border-color: $blue-thin-lines;
  }

  &_wider {
    max-width: 497px;
  }

  &__inner {
    position: absolute;
    width: 100%;
    resize: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    padding: 20px 10px;
    outline: none;
    border: none;
  }

  #cke_terms {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    box-shadow: none;
  }

  .cke_inner {
    height: calc(100% - 42px);
  }

  .cke_bottom {
    display: none;
  }
}
