@import '../globals';

.notification {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 18px);

  &__error-msg,
  &__success-msg {
    position: relative;
    display: block;
    background: #ba0404 url('../../images/icon-attention.png') no-repeat 8px
      center;
    color: $white;
    font-weight: 300;
    padding: 14px 14px 14px 38px;
    text-align: left;
    max-width: 300px;
    margin: 10px 0;
    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      height: 0;
      width: 0;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 9px solid #ba0404;
    }
  }

  &__success-msg {
    background-color: $green;
    &:before {
      border-right: 9px solid $green;
    }
  }
}

.submitted .notification {
  display: table;
}

.notification_inner {
  top: 20px;
  left: 20px;
  transform: none;

  .notification__error-msg:before {
    top: 100%;
    right: 50%;
    transform: translateX(50%);
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #ba0404;
  }
}

.notification_top {
  top: auto;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);

  .notification__error-msg:before {
    top: 100%;
    right: 50%;
    transform: translateX(50%);
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #ba0404;
  }
}
