//** Background color for `<body>`.
$body-bg: #f5f5f7 !default;

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee

$brand-primary: darken(#428bca, 6.5%) !default; // #337ab7
$brand-success: #49b043 !default;
$brand-info: #3aa0e8 !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d30322 !default;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Global text color on `<body>`.
$text-color: #515151 !default;

//** Global textual link color.
$link-color: $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Open Sans', 'Arial Narrow', Arial, Helvetica,
  sans-serif !default;
$font-family-serif: Georgia, 'Times New Roman', Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 14px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1: floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2: floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(
  ($font-size-base * $line-height-base)
) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;

//== Iconography
//
//** File name for all font files.
$icon-font-name: 'glyphicons-halflings-regular' !default;
//** Element ID within SVG icon file.
$icon-font-svg-id: 'glyphicons_halflingsregular' !default;

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: 6px !default;
$padding-base-horizontal: 12px !default;

$padding-large-vertical: 10px !default;
$padding-large-horizontal: 16px !default;

$padding-small-vertical: 5px !default;
$padding-small-horizontal: 10px !default;

$padding-xs-vertical: 1px !default;
$padding-xs-horizontal: 5px !default;

$line-height-large: 1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5 !default;

$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color: #fff !default;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary !default;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 4px !default;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px !default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000 !default;
$zindex-dropdown: 10 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-navbar-fixed: 1030 !default;
$zindex-modal: 1040 !default;

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet !default;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md: $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop !default;

//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default;
//** Text muted color
$text-muted: $gray-light !default;
//** Abbreviations and acronyms border color
$abbr-border-color: $gray-light !default;
//** Headings small color
$headings-small-color: $gray-light !default;
//** Blockquote small color
$blockquote-small-color: $gray-light !default;
//** Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25) !default;
//** Blockquote border color
$blockquote-border-color: $gray-lighter !default;
//** Page header border color
$page-header-border-color: $gray-lighter !default;
//** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal !default;
//** Horizontal line color.
$hr-border: $gray-lighter !default;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text: #3c763d !default;
$state-success-bg: #dff0d8 !default;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text: #31708f !default;
$state-info-bg: #d9edf7 !default;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text: #8a6d3b !default;
$state-warning-bg: #fcf8e3 !default;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text: #a94442 !default;
$state-danger-bg: #f2dede !default;
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%) !default;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px !default;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px !default;

//** Default background color used for all tables.
$table-bg: transparent !default;
//** Background color used for `.table-striped`.
$table-bg-accent: #f9f9f9 !default;
//** Background color used for `.table-hover`.
$table-bg-hover: #f5f5f5 !default;
$table-bg-active: $table-bg-hover !default;

//** Border color for table and cell borders.
$table-border-color: #ddd !default;

//== Forms
//
//##

//** `<input>` background color
$input-bg: #fff !default;
//** `<input disabled>` background color
$input-bg-disabled: $gray-lighter !default;

//** Text color for `<input>`s
$input-color: $gray !default;
//** `<input>` border color
$input-border: #ccc !default;

//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $border-radius-base !default;
//** Large `.form-control` border radius
$input-border-radius-large: $border-radius-large !default;
//** Small `.form-control` border radius
$input-border-radius-small: $border-radius-small !default;

//** Border color for inputs on focus
$input-border-focus: #66afe9 !default;

//** Placeholder text color
$input-color-placeholder: #999 !default;

//** Default `.form-control` height
$input-height-base: (
  $line-height-computed + ($padding-base-vertical * 2) + 2
) !default;
//** Large `.form-control` height
$input-height-large: (
  ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) +
    2
) !default;
//** Small `.form-control` height
$input-height-small: (
  floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) +
    2
) !default;

$legend-color: $gray-dark !default;
$legend-border-color: #e5e5e5 !default;

//** Background color for textual input addons
$input-group-addon-bg: $gray-lighter !default;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border !default;

//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed !default;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: 600 !default;

$btn-default-color: #333 !default;
$btn-default-bg: #fff !default;
$btn-default-border: #ccc !default;

$btn-primary-color: #fff !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: darken($btn-primary-bg, 5%) !default;

$btn-success-color: #fff !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: darken($btn-success-bg, 5%) !default;

$btn-info-color: #fff !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: darken($btn-info-bg, 5%) !default;

$btn-warning-color: #fff !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: darken($btn-warning-bg, 5%) !default;

$btn-danger-color: #fff !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: darken($btn-danger-bg, 5%) !default;

$btn-add-color: #fff !default;
$btn-add-bg: #39adea !default;
$btn-add-border: darken(#39adea, 5%) !default;

$btn-link-disabled-color: $gray-light !default;

$btn-lg-font-size: 13px !default;

//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg: #fff !default;
//** Dropdown menu `border-color`.
$dropdown-border: rgba(0, 0, 0, 0.15) !default;
//** Divider color for between dropdown items.
$dropdown-divider-bg: #e5e5e5 !default;

//** Dropdown link text color.
$dropdown-link-color: $gray-dark !default;
//** Hover color for dropdown links.
$dropdown-link-hover-color: darken($gray-dark, 5%) !default;
//** Hover background for dropdown links.
$dropdown-link-hover-bg: #f1f9fc !default;

//** Active dropdown menu item background color.
$dropdown-link-active-bg: #bfe4f7 !default;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light !default;

//** Text color for headers within dropdown menus.
$dropdown-header-color: $gray-light !default;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: #000 !default;

//== Pagination
//
//##

$pagination-color: #808080 !default;
$pagination-bg: #d7d6e6 !default;
$pagination-border: #d1d0d8 !default;

$pagination-hover-color: #808080 !default;
$pagination-hover-bg: $gray-lighter !default;
$pagination-hover-border: transparent !default;

$pagination-active-color: #808080 !default;
$pagination-active-bg: #ffffff !default;
$pagination-active-border: #c7c7d3 !default;

$pagination-disabled-color: #a8a8a8 !default;
$pagination-disabled-bg: #d4d2da !default;
$pagination-disabled-border: #d4d2da !default;

$pagination-font-size: 13px !default;
$pagination-item-paddings: 3px 9px !default;
$pagination-item-margins: 0 3px !default;
$pagination-buttons-bg: #c7c7d3 !default;

//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding: 15px !default;

//** Padding applied to the modal title
$modal-title-padding: 17px !default;
//** Modal title line-height
$modal-title-line-height: $line-height-base !default;

//** Background color of modal content area
$modal-content-bg: #fff !default;
//** Modal content border color
$modal-content-border-color: rgba(0, 0, 0, 0.1) !default;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: #999 !default;

//** Modal backdrop background color
$modal-backdrop-bg: #000 !default;
//** Modal backdrop opacity
$modal-backdrop-opacity: 0.75 !default;
//** Modal header border color
$modal-header-border-color: #e5e5e5 !default;
//** Modal footer border color
$modal-footer-border-color: $modal-header-border-color !default;

$modal-lg: 900px !default;
$modal-md: 700px !default;
$modal-sm: 400px !default;

//== Close
//
//##

$close-font-weight: bold !default;
$close-color: #000 !default;
$close-text-shadow: 0 1px 0 #fff !default;

//== Sidebar
//
//##
$sidebar-width: 210px !default;
$sidebar-bg-color: #262626 !default;
$sidebar-border-color: #2f2f2f !default;

$sidebar-title-color: #434447 !default;
$sidebar-title-font-size: 13px !default;

//sidebar nav
$sidebar-li-height: 36px !default;

$sidebar-link-font-size: 11px !default;
$sidebar-link-color: #ccc !default;
$sidebar-link-active-color: #fff !default;
$sidebar-link-active-bg: #1a1919 !default;
$sidebar-link-active-border: #5f5d5e !default;

// Admin main

$admin-main-color: #eeedf3;

// Admin section

$admin-color: #dbdbdd;
$admin-header-bg-color: #32353e;
$admin-filters-bg-color: #4e4e56;
$admin-header-tab-bg-color: #86858a;
$admin-header-tab-active-bg-color: #e5e5ed;

$admin-form-header-bg-color: #d7d7df;
$admin-form-border-color: #d5d4d9;
$admin-form-bg-color: #fafafc;
$admin-form-header-text-color: #9594a4;

$admin-restart-color: #437dad;
$admin-restart-border-color: #68abe0;
$admin-restart-bg-color: #d8e8f7;

$admin-edit-color: #21a7ee;
$admin-edit-form-header-bg-color: #bfe4f7;
$admin-edit-bg-add-field-color: #f2f6f7;

$admin-btn-color-blue: #39adea;

// Admin sidebar

$admin-sidebar-bg-color: #c5c5d1;
$admin-sidebar-bg-header-color: #9f9eac;
$admin-sidebar-border-color: #bebfc3;
$admin-sidebar-active-color: #e2e1e7;
$admin-sidebar-item-border-color: #bbbbc5;

// Admin tab section

$admin-tab-table-color: #fbfbfb;
$admin-tab-section-bg: #e6e6e8;
$admin-tab-header-bg-color: #d6d4d5;
$admin-tab-bg-input: #f7f7f7;
$admin-tab-widget-color: #4a4a4a;

// Settings section

$settings-color: #dbdbdd;
$settings-text-color: #777;
$settings-description-color: #9c9c9e;

$settings-bg-header-color: #f1f1f3;
$settings-bg-color: #f4f4f6;

$settings-height-one: 32vh;
$settings-height-two: 36vh;

// Localization section

$localization-height: 70vh;

//== Topbar
//
//##
$topbar-bg-color: #262626 !default;
$topbar-height: 50px !default;
$topbar-muted-color: #898a90 !default;

//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width: 200px !default;
//** Tooltip text color
$tooltip-color: #fff !default;
//** Tooltip background color
$tooltip-bg: #000 !default;
$tooltip-opacity: 0.9 !default;

//** Tooltip arrow width
$tooltip-arrow-width: 5px !default;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg !default;

//== Popovers
//
//##

//** Popover body background color
$popover-bg: #fff !default;
//** Popover maximum width
$popover-max-width: 276px !default;
//** Popover border color
$popover-border-color: rgba(0, 0, 0, 0.2) !default;
//** Popover fallback border color
$popover-fallback-border-color: #ccc !default;

//** Popover title background color
$popover-title-bg: darken($popover-bg, 3%) !default;

//** Popover arrow width
$popover-arrow-width: 10px !default;
//** Popover arrow color
$popover-arrow-color: $popover-bg !default;

//** Popover outer arrow width
$popover-arrow-outer-width: ($popover-arrow-width + 1) !default;
//** Popover outer arrow color
$popover-arrow-outer-color: fade_in($popover-border-color, 0.05) !default;
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color: darken(
  $popover-fallback-border-color,
  20%
) !default;

//== Slidedowm block

$slidedown-color: #90929f;
$slidedown-text-block-color: #f5f4f9;

$section-edit-form-control-bg-color: #eaf2ff;
