html,
body {
  height: 100%;
}

@media screen and (max-width: 1200px) {
  body {
    display: none;
  }

  html {
    background: url('../../images/errors/resolution.jpg') no-repeat center;
    background-size: cover;
  }
}
