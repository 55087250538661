/*
* Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
* 600 North Bridge Road, Parkview Square #15-10, Singapore
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* License: see file “LICENSE.txt”
*/

.page.__campaigns.__analytics {
  .date {
    position: relative;
    display: inline-block;
  }

  // .date .form-control.__data-control,
  // .settings-form_dropdown_btn {
  //   width: 60px;
  //   padding: 3px;
  //   border: 0;
  //   border-radius: 3px;
  //   background-color: #dddce1;
  //   font-size: 10px;
  // }

  .dropdown_menu {
    border: none;
    z-index: 100;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #9f9fa7;
    }
    &::-webkit-scrollbar-track {
      background-color: #dddce1;
    }
  }

  .filters-block {
    label.contain-checkbox span {
      font-weight: normal;
    }
  }

  .filters-block.__timeframe {
    .dropdown {
      .settings-form_dropdown_btn {
        width: 38px;
      }
      li span {
        background-color: #dddce1;
      }
      li span:hover {
        background-color: #cfced6;
      }
    }
  }

  .filters-block.__days {
    label.contain-checkbox {
      display: block;
      padding: 5px 10px;
      background-color: #dddce1;

      &:hover {
        background-color: #cfced6;
      }
    }

    .dropdown_menu {
      max-height: none;
    }
  }

  label.contain-checkbox span,
  label.contain-checkbox span.radio {
    padding-left: 20px;
  }

  .pichart-padding {
    padding: 25px 20px;
  }

  .horchart-padding {
    padding: 10px 20px 10px 40px;
  }

  .graph-content .spiner {
    position: absolute;
    width: 80px;
    height: 100%;
    transform: translateX(-50%);
    margin-left: 50%;
    top: 0;
    left: 0;
  }

  .graph-content.__onlyTextBlock .spiner {
    width: 60px;
  }

  .tooltip.analytics-checkbox {
    .tooltip_arrow {
      left: 22%;
      border-bottom-color: #aba8b1;
    }

    .tooltip_inner {
      background-color: #aba8b1;
      font-size: 9px;
      padding: 5px 15px;
    }
  }

  .tooltip.analytics-graph {
    background-color: #aba8b1;
    color: #fff;
    font-size: 9px;
    padding: 5px 15px;
    border-radius: 5px;
    opacity: 1;

    h5 {
      display: block;
      font-size: 11px;
      padding-bottom: 5px;
      margin: 0;
    }
  }

  .dropdown.__time .dropdown_menu .ps-scrollbar-y-rail {
    background-color: #dddce1;

    .ps-scrollbar-y {
      background-color: #9f9fa7;
    }
  }

  .ps-container > .ps-scrollbar-x-rail {
    display: none !important;
  }

  .chart-axis-label {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 20px;
    height: 20px;
    min-width: 50px;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    border-radius: 5px;

    &.__y {
      background-color: #babccb;
    }

    &.__x {
      background-color: #babccb;
    }

    &.__y2 {
      background-color: #f2a2a5;
    }
  }

  // Hide y2 if not needed
  [single-axis-lines] .chart-axis-label.__y2 {
    display: none;
  }

  // pagination in sites
  .pagination_b {
    padding: 8px 8px 3px;
    background-color: #f2f3f5;

    .pagination_i.__group-backward,
    .drop-down_w {
      display: none;
    }

    .pagination_info.__short {
      padding-left: 3px;
      margin: 0;
    }
  }

  //  .analytics-graph-part {
  //    width: calc(100% - 230px);
  //  }
  //  .analytics-side-block-part {
  //    width: 230px;
  //  }

  @media (max-width: 1650px) {
    .pagination_button > span,
    .pagination_button > a,
    .pagination_button > a:focus {
      padding: 3px 3px;
    }
  }

  @media (max-width: 1450px) {
    .pagination_button > span,
    .pagination_button > a,
    .pagination_button > a:focus {
      padding: 3px 0px;
    }
  }
}

.analytics-section {
  position: relative;
  background-color: #d1d4dd;
  overflow: auto;
}

.analytics-section_header {
  height: 65px;
  background-color: #f2f3f5;
}

.analytics-section_filters {
  position: absolute;
  right: 25px;
  top: 12px;

  .filters-block {
    display: inline-block;
    padding: 10px 15px;
    background-color: #eaebef;
    font-size: 10px;
    margin-left: 10px;
  }
}

.analytics-section_graphs {
  overflow: auto;
  margin-top: 20px;
  padding: 0 15px;
  height: calc(100vh - 200px);
}

.analytics-section_graphs_overflow {
}

// Analytics block
//
.analytics-block,
.analytics-poll-block {
  position: relative;
  margin-bottom: 10px;
  min-height: 100px;
  page-break-before: always;

  &:last-child {
    margin-bottom: 0;
  }

  &.__spinner {
    min-height: 200px;
  }

  &.__no-page-break {
    page-break-before: avoid;
  }

  &.__no-inside-break,
  .__no-inside-break {
    page-break-inside: avoid;
  }
}
.analytics-poll-block {
  min-height: auto;
  margin-bottom: 0;
}

.analytics-block_header,
.analytics-block_footer {
  position: relative;
  background-color: #f2f1f6;
  padding: 9px 15px;
  height: 45px;
  color: #a2a4b1;

  .links-show-more {
    font-size: 12px;
    color: #000;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }

    &[disabled] {
      text-decoration: none;
      opacity: 0.5 !important;
    }
  }
}

.analytics-block_header {
  padding-right: 60px;
}

.analytics-block_close {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #e2e2ee;
  width: 45px;
  height: 45px;
  text-align: center;
  color: #aaa;
  padding: 10px;
}

.analytics-block_graph {
  position: relative;
  background-color: #fff;
}

.filter-types {
  &.__with-border {
    border: 1px solid #ccc;
    padding: 1px 0;

    .analytics-block_checkbox {
      border: none;
    }
  }
}

label.contain-checkbox.analytics-block_checkbox {
  padding: 2px 10px;
  border: 1px solid #ccc;
  font-weight: bold;
  margin-left: 10px;

  @media (max-width: 1450px) {
    margin-left: 0px;
  }

  &:first-child {
    margin-left: 0;
  }

  &.__radio {
    margin: 0;
  }

  span {
    font-weight: 600;
    font-size: 10px !important;
    padding-left: 18px;
  }

  span.radio {
    &:before {
      border-radius: 0;
    }
  }
}

.analytics-block_title {
  display: inline-block;
}

.analytics-block_parameter {
  display: inline-block;
  margin-left: 10px;

  @media (max-width: 1450px) {
    margin-left: 0px;
  }

  &:last-child {
    margin: 0;
  }

  &.__right {
    float: right;
    top: 0;
    display: block;
  }
}

.analytics-sites-filter {
  position: relative;
  max-height: calc(100% - 3 * 36px);
  overflow: auto;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: block;
    border-bottom: 1px solid #e4e5e7;
  }

  li {
    position: relative;
    height: 38px;
    background-color: #f2f3f5;
    border-bottom: 1px solid #e4e5e7;

    &:last-child {
      border: none;
    }

    &.__header {
      height: 36px;
      background-color: #eaebf0;
    }

    span.__site-color {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 7px;
      height: 100%;
      background-color: #ccc;
    }
    label.contain-checkbox {
      margin-top: 10px;
      margin-left: 20px;
      font-weight: normal;
      font-size: 10px;
      width: 90%;
      padding-right: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        padding-left: 25px;
      }
    }
  }
}

.col-lg-info {
  display: block;
  float: left;
  text-align: center;
  width: 20%;
  padding: 0 20px;
  margin: 20px 0;
  border-right: 1px solid #e6e9f0;

  &:last-child {
    border-right: none;
  }

  span.units {
    font-size: 30px;
    color: #52576b;
  }
  span.text {
    font-size: 10px;
    color: #9698ad;
  }
}

.btn.__to-pdf {
  visibility: visible;
  float: right;
  margin: 2px 0 0 50px;
  position: relative;
  background-color: #2d80c2;
  font-weight: bold;
  border-radius: 3px;
  padding: 6px 12px 6px 14px;

  &:hover {
    opacity: 0.9;
  }
}

.analytics-sites-filter-header {
  position: relative;
  background-color: #eaebf0;
  border-bottom: 1px solid #e4e5e7;

  label.contain-checkbox {
    font-weight: normal;
    font-size: 10px;
    width: 100%;
    padding: 12px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 34px;
  }

  .settings-form_form-control.__search {
    padding: 5px 10px;
    width: 100%;
    height: 36px;
    background-color: #fff;
    border: 0;

    &:focus {
      border: 0;
    }
  }
}

.graph-content {
  position: relative;
  padding: 40px 30px;

  &.row {
    margin: 0;
  }

  &.__chart {
    min-height: 510px;
  }

  &.__bottom {
    padding: 0px 0px 30px;

    .btn {
      background-color: #cac7d8;
    }

    .btn:hover {
      opacity: 0.7;
    }
  }

  &.__no-padding {
    padding: 0;
  }

  &.__spinner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 200px;
    background-color: rgba(255, 255, 255, 0.7);
  }

  &.__wrong-text {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 1);
  }

  &.__message {
    position: relative;
    margin-top: -45px;
    min-height: 200px;
    background-color: rgba(255, 255, 255, 0.7);
  }

  &.__onlyTextBlock {
    //    padding: 80px 0 45px;
  }
}

.graph-block {
  position: relative;
  padding: 30px 30px 15px;
}

[class^='col-']:last-child {
  .graph-block:after {
    content: none;
  }
}

.graph-block.__big-padding {
  padding: 30px 80px 15px;
}

.graph-block-title {
  position: absolute;
  top: 20px;
  left: 35px;
  color: #a2a4b1;
  font-size: 10px;
  padding: 3px 6px;
  border-radius: 3px;
  background-color: #e5e4e9;
}

.graph-block:after {
  content: '';
  background-color: #ccc;
  height: 90%;
  position: absolute;
  right: -15px;
  top: 0;
  width: 1px;
  margin-top: 5%;
}

.horbar-counts {
  padding: 2px;
  background-color: #edecf2;
}

.scroll-block {
  position: fixed;
  //  top:0;
  //  left:250px;
  //  right:0;
  //  height:54px;
  //  z-index:1000;
}

// Restyled C3 ----------------------- >>

.c3-ygrid-line.lineStroke line,
.c3-xgrid-focus line {
  stroke: #ddd;
}

.c3-axis .tick tspan {
  fill: #bbb;
}

.c3-axis.c3-axis-y2 .tick tspan {
  fill: #ee9dad;
}

.c3-axis path {
  visibility: hidden;
}

.tick line {
  visibility: hidden;
}

//.c3-chart-texts .c3-text {
//  visibility: hidden;
//}

[class*='fake-chart'] {
  display: none;
}

.c3-chart-arc path {
  stroke: none;
}

//[multi-axis-bars-line] .c3-chart-lines {
//  .c3-chart-line {
//    transform: translateX(10%);
//  }
//}

[horizontal-bars] .c3-chart-texts .c3-text {
  fill: #999 !important;
  stroke: 0 !important;
}

.c3-tooltip tr {
  border: none;
}

.c3-tooltip {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 5px 4px 20px -7px #777;
}

//// Print classes ------------------->

.header-graph-print {
  border: 1px solid #ccc;
}

.header-graph-print-info {
  padding: 25px 0 19px;

  .settings-form_form-control.__in-tab-disabled {
    background-color: #fff;
    width: auto;
    min-width: 100px;
    max-width: 250px;
  }

  label {
    font-weight: normal;
    margin-right: 10px;
  }

  .form-control {
    font-size: 14px !important;
  }
}

.header-graph-print-name {
  font-size: 20px;
  padding: 30px 50px 15px;
  background-color: #f2f1f6;

  .sidebar_logo {
    display: inline-block;
    width: 45px;
    height: 30px;
    margin-right: 10px;
    vertical-align: middle;
    background-size: auto 100%;
  }

  .separator {
    display: inline-block;
    margin: -5px 10px 0;
  }

  span {
    margin-top: 20px;
    display: inline-block;
  }

  h1.sidebar_system-name {
    font-weight: bold;
  }

  h1 {
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 20px;
  }
}

.footer-sites-print {
  border: 1px solid #ccc;
  background-color: #f2f1f6;
  padding: 0 20px;

  .slidedown-block_items label {
    width: 33%;
    font-weight: normal;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .__site-color {
      position: absolute;
      display: inline-block;
      margin-right: 10px;
      margin-top: 8px;
      width: 10px;
      height: 10px;
    }
  }

  h1 {
    font-size: 20px;
    padding-left: 50px;
  }
}

.onPrint {
  display: none;
}

.analytics-wrong-text {
  position: absolute;
  height: 100%;
  width: 100%;
  top: -5%;
  left: -50%;
  display: table;
  vertical-align: middle;
  transform: translateX(50%) translateY(50%);
}

//// On Print

.only-for-print {
  display: none;
}

@media print {
  .col-lg-info span.units {
    font-size: 20px;
  }
  @page {
    size: A4 landscape;
    margin: 10px;
  }
  .only-for-print {
    display: block;
  }
  .only-not-for-print {
    display: none;
  }
}

.page-break {
  display: block;
  page-break-before: always;
}
