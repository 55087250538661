@import '../globals';

.chain-block {
  width: 100%;
  border: 1px solid #e5e5e6;

  &__header {
    background-color: #90929f;
    text-align: center;
    color: $white;
    text-transform: uppercase;
    height: 36px;
    line-height: 36px;
  }

  &__body {
    padding: 15px;
  }

  &__tile {
    display: flex;
    align-items: stretch;
    height: 60px;
    background-color: #f0f0f2;
    border-radius: 4px;
    overflow: hidden;
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &.__active {
      opacity: 1;
    }

    &.__archive {
      opacity: 0.4;
    }
  }

  &__tile-label {
    width: 200px;
    max-width: 30%;
    background-color: #e9e9eb;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
  }

  &__tile-status {
    background: #dadae0;
    text-align: right;
    height: 20px;
    line-height: 20px;
    padding-right: 10px;
    color: #606370;
    text-transform: capitalize;
  }

  &__tile-title {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 14px;
  }

  &__tile-previews {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  &__tile-previews-block,
  &__tile-previews-cnt {
    display: flex;
    align-items: center;
  }

  &__tile-img-holder {
    position: relative;
    width: 36px;
    height: 48px;
    line-height: 48px;
    color: $white;
    text-align: center;
    font-size: 20px;
    background-color: #e3e3ec;
    border: 1px solid #cfd1e3;
  }

  &__tile-preview-img-bg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
  }

  &__tile-preview-img-fg {
    max-width: 100%;
    max-height: 100%;
  }

  &__tile-preview-img-ph {
    max-width: 80%;
  }

  &__tile-arrow-icon {
    font-family: FontAwesome;
    margin: 0 5px;
    color: #9395a2;
    &_hide-last {
      &:last-child {
        display: none;
      }
    }
  }
}
.chain-block__tile-previews-cnt
  > .chain-block__tile-previews-block:last-child
  > .chain-block__tile-arrow-icon {
  display: none;
}
