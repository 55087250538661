@import '../globals';

.slider-checkbox {
  font-size: $font-size-base;
  font-weight: normal;
  color: $font-color-prime;
  white-space: nowrap;
  &:not(:last-child) {
    margin-right: 10px;
  }
  &_adaptive {
    font-size: inherit;
    color: inherit;
  }

  &__control {
    position: absolute;
    left: -9999px;
  }
  &__skin {
    position: relative;
    bottom: -3px;
    display: inline-block;
    width: 24px;
    height: 14px;
    border-radius: 7px;
    box-shadow: inset 0 0 0 7px $blue;
    transition: all 0.4s cubic-bezier(0.17, 0.67, 0.43, 0.98);
  }
  &__skin:after {
    content: '';
    position: absolute;
    left: 1px;
    bottom: 1px;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ffffff;
    transition: 0.2s ease-out;
  }
}

.slider-checkbox__skin + .slider-checkbox__label {
  padding-left: 5px;
}
.slider-checkbox__label + .slider-checkbox__skin {
  margin-left: 5px;
}

.slider-checkbox__control:checked ~ .slider-checkbox__skin {
  box-shadow: inset 0 0 0 7px $green;
}

.slider-checkbox__control:checked ~ .slider-checkbox__skin:after {
  left: 11px;
}

//set cursor:pointer on active controls only
.slider-checkbox__skin,
.slider-checkbox__skin + .slider-checkbox__label {
  cursor: pointer;
}

//slider OFF locked
.slider-checkbox__control:disabled:not(.slider-checkbox__control_style-as-active)
  ~ .slider-checkbox__skin,
.slider-checkbox_disabled .slider-checkbox__control ~ .slider-checkbox__skin {
  box-shadow: inset 0 0 0 7px #8b8b8c;
}
.slider-checkbox__control:disabled:not(.slider-checkbox__control_style-as-active)
  ~ .slider-checkbox__skin:after,
.slider-checkbox_disabled
  .slider-checkbox__control
  ~ .slider-checkbox__skin:after {
  background-color: #dbdbdb;
}

//slider ON locked
.slider-checkbox__control:checked:disabled:not(.slider-checkbox__control_style-as-active)
  ~ .slider-checkbox__skin,
.slider-checkbox_disabled
  .slider-checkbox__control:checked
  ~ .slider-checkbox__skin {
  box-shadow: inset 0 0 0 7px $green;
}

.slider-checkbox__control:checked:disabled:not(.slider-checkbox__control_style-as-active)
  ~ .slider-checkbox__skin:after,
.slider-checkbox_disabled
  .slider-checkbox__control:checked
  ~ .slider-checkbox__skin:after {
  box-shadow: inset 0 0 0 7px #dbdbdb;
}

.slider-checkbox__control:disabled:not(.slider-checkbox__control_style-as-active)
  ~ .slider-checkbox__skin,
.slider-checkbox__control:disabled:not(.slider-checkbox__control_style-as-active)
  ~ .slider-checkbox__label,
.slider-checkbox_disabled .slider-checkbox__control ~ .slider-checkbox__skin,
.slider-checkbox_disabled .slider-checkbox__control ~ .slider-checkbox__label {
  cursor: auto;
}
